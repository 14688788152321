.fitness-expert-item{
    border-radius: 15px;
background: linear-gradient(90deg, #252A2D 0%, rgba(105, 105, 106, 0.57) 100%);
box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
display: grid;
align-items: center;
grid-template-columns: repeat(2, minmax(0, 1fr));
gap: 20px;
padding: 30px;
}
.fitness-expert-item img{
    width: 380px;
    height: 350px;
    border-radius: 15px;
    object-fit: cover;
}
.fitness-expert-item h5{
    color: #FFF;
font-size: 26px;
font-weight: 600;
line-height: normal;
margin: 0;
}
.fitness-expert-item p{
    color:  #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 139.9%;
    max-width: 470px;
    margin: 20px 0 40px 0;
}
.fitness-expert .swiper-button-prev{
left: 15px;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.4643' fill='white' stroke='white' stroke-width='1.07143'/%3E%3Cpath d='M24.9983 29.5219L15.7656 19.7638L24.9983 10' stroke='url(%23paint0_linear_3703_119025)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3703_119025' x1='18.2398' y1='5.83546' x2='32.3682' y2='12.3557' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}
.fitness-expert .swiper-button-next{
right: 15px;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.4643' transform='matrix(-1 0 0 1 40 0)' fill='white' stroke='white' stroke-width='1.07143'/%3E%3Cpath d='M15.0017 29.5219L24.2344 19.7638L15.0017 10' stroke='url(%23paint0_linear_3703_119028)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3703_119028' x1='21.7602' y1='5.83546' x2='7.63179' y2='12.3557' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}