.gmnoprint{
    gap: 10px !important;
}

.gm-style-mtc-bbw .gm-style-mtc:last-of-type>button {
    border-start-end-radius: 16px !important;
    border-end-end-radius: 16px !important;
}

.gm-style-mtc-bbw .gm-style-mtc:first-of-type>button {
    border-start-start-radius: 16px !important;
    border-end-start-radius: 16px !important;
}

.gm-style-cc, .gmnoprint, .gm-style-mtc {
    display: none !important;
  }