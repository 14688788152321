.service-center-main-container {
    border-radius: 15px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    text-align: center;
    padding: 42px 0;
}

.service-center-main-container img {
    width: 50px;
    height: 50px;
}

.service-center-main-container input {
    border-radius: 16px;
    background: #FFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M21.3553 20.0029L15.4912 14.1195C16.8094 12.49 17.4521 10.4169 17.2869 8.32751C17.1217 6.23812 16.1611 4.29177 14.6032 2.88972C13.0453 1.48767 11.0088 0.736773 8.91361 0.791896C6.81843 0.847019 4.82426 1.70395 3.34223 3.18598C1.8602 4.66801 1.00327 6.66217 0.948146 8.75736C0.893023 10.8525 1.64392 12.889 3.04597 14.4469C4.44802 16.0048 6.39437 16.9654 8.48376 17.1306C10.5731 17.2958 12.6463 16.6531 14.2757 15.3349L20.1398 21.1989C20.2195 21.279 20.3142 21.3426 20.4185 21.386C20.5228 21.4293 20.6345 21.4516 20.7475 21.4516C20.8604 21.4516 20.9723 21.4293 21.0766 21.386C21.1809 21.3426 21.2756 21.279 21.3553 21.1989C21.51 21.0383 21.5964 20.824 21.5964 20.6009C21.5964 20.3779 21.51 20.1635 21.3553 20.0029ZM9.15253 15.456C7.8774 15.4569 6.63073 15.0796 5.5701 14.3718C4.50946 13.6639 3.68252 12.6574 3.1941 11.4795C2.70568 10.3016 2.57767 9.00535 2.8262 7.75467C3.07473 6.50399 3.68874 5.35514 4.5904 4.45348C5.49206 3.55182 6.64091 2.93787 7.89159 2.68933C9.14227 2.4408 10.4385 2.56882 11.6164 3.05724C12.7943 3.54565 13.8008 4.37254 14.5086 5.43317C15.2165 6.49381 15.5938 7.74053 15.5928 9.01567C15.5903 10.7229 14.9109 12.3596 13.7037 13.5668C12.4964 14.774 10.8598 15.4534 9.15253 15.456Z' fill='%23C7C7C7'/%3E%3C/svg%3E");
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    border: none;
    max-width: 571px;
    width: calc(100% - 30px);
    color: #C7C7C7;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 10px 10px 10px 51px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 15px;
}

.service-center-main-container input::placeholder {
    color: #C7C7C7;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.service-center-main-container h1 {
    color: #FFF;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding: 19px 0 30px 0;
}

.service-center-main-container p {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    max-width: 720px;
    padding: 30px 0 12px 0;
    margin: 0 auto;
}

.service-center-main-container a {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline !important;
}

.support-cetegory-slider {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    padding: 34px 40px;
}

.support-cetegory-slider .swiper-button-prev:after,
.support-cetegory-slider .swiper-button-next:after {
    font-size: 18px;
    font-weight: bolder;
}

.support-cetegory-slider .swiper-button-next {
    right: -40px;
}

.support-cetegory-slider .swiper-button-prev {
    left: -40px;
}

.support-cetegory-slider .swiper-slide {
    width: 126px;
}

.support-cetegory-slider .swiper-slide-active.swiper-slide {
    width: 126px;
}

.swiper-item-img {
    width: 126px;
    height: 126px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
}

.support-cetegory-slider h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 25px;
}

.support-cetegory-slider .swiper-slide-active.swiper-slide .swiper-item-img {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
}

.support-cetegory-slider .swiper-slide-active.swiper-slide h2 {
    font-weight: bold;
}

.support-cetegory-slider .swiper-slide-active.swiper-slide .swiper-item-img img {
    filter: brightness(0) invert(1);
}

.support-cetegory-slider .swiper-pagination-progressbar {
    bottom: 0;
    top: unset;
    height: 6px;
    border-radius: 10px;
    overflow: hidden;
}

.support-cetegory-slider .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    border-radius: 10px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
}

.support-cetegory-slider .swiper-button-next,
.support-cetegory-slider .swiper-button-prev {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.frequenty-asked-questions {
    border-radius: 10px;
    background: url(../../images/unsplash_NpyF7rjqmq4.png);
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    background-repeat: no-repeat;
    background-size: cover;
    height: 406px;
}

.frequenty-asked-questions h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}

.frequenty-asked-questions p {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 28px;
    margin-top: 11px;
}