.hover-content {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgb(48 48 48 / 90%);
    color: #fff;
    padding: 40px 20px;
    transition: opacity 0.3s ease-in-out;
}

.workplace-item:hover .hover-content {
    opacity: 1;
}

.hover-content h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.hover-content p {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.workplace-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 38px;
}

.workplace-item {
    height: 250px;
    border-radius: 15px;
    position: relative;
}

.workplace-item img {
    border-radius: 15px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.percentage-raised {
    color: #303030;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    display: block;
}

.total-raised {
    color: #303030;
    text-align: center;
    font-size: 33px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.donation-head {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}

.donation-head h3 {
    margin-bottom: 12px;
}

.donation-head p {
    margin-bottom: 25px;
}

.donation-head .recent-video-component {
    padding: 25px 30px 30px 30px;
}

.donate-section {
    display: flex;
    align-items: center;
    border-radius: 15px;
    background: #E6ECEC;
}

.donate-section .recent-video-component {
    padding-left: 30px;
}

.donate-amount {
    background: #fff;
    padding: 30px;
    border-radius: 0px 15px 15px 0px;
    border: 1px solid #CBCBCB;
}

.donate-section h3 {
    margin-bottom: 20px;
}

.donate-section p {
    margin-bottom: 20px;
}

.custom-input-select label span {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.custom-input-select input[type="radio"]+label span {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border: none;
    border-radius: 0%;
    cursor: pointer;
    transition: none;
}

.custom-input-select div {
    width: 100%;
}

.custom-input-select label {
    border-radius: 16px;
    border: 1px solid #001597;
    background: #FFF;
    width: 100%;
    text-align: center;
    padding: 14px 0;
}

.custom-input-select {
    display: flex;
    column-gap: 15px;
}

.custom-input-select input {
    width: 0;
    height: 0;
    visibility: hidden;
}

.custom-input-select input:checked~label {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
}

.custom-input-select input:checked~label span {
    -webkit-text-fill-color: #fff;
}

.donate-custom input {
    width: 100%;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    border: none;
    outline: none;
    height: 50px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 40px;
}

.donate-custom {
    position: relative;
    margin-bottom: 30px;
    margin-top: 25px;
}

.donate-custom::before {
    content: "€";
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
}

.donate-custom::after {
    content: "Euro";
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
}

.donation-join-mail {
    border-radius: 15px;
    position: relative;
    color: #fff;
    padding: 40px 30px 80px 30px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.donation-join-mail::after {
    position: absolute;
    content: "";
    border-radius: 15px;
    background: #303030;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.donation-join-mail h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.donation-join-mail p {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.donation-join-mail input {
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.donation-join-mail input::placeholder {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}