.ads-home {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15.053px 0px rgba(82, 106, 103, 0.19);
}

.ads-campaign-statistic {
    padding: 77px 30px;
}

.ads-campaign-video {
    border-radius: 0 15px 15px 0px;
}

.running-campaign-statistic {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 15px;
}

.statistic-footer {
    display: flex;
    gap: 9px;
    flex-wrap: wrap;
    align-items: center;
}

.statistic-item {
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 0px 14.361px 0px rgba(0, 28, 147, 0.13);
    padding: 14px 20px;
}

.ads-campaign-statistic h2 {
    color: #303030;
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 13px;
}

.ads-campaign-statistic h3 {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.ads-campaign-statistic h4 {
    color: #ABABAB;
    font-size: 13.404px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    column-gap: 9px;
}

.ads-campaign-statistic h5 {
    font-size: 21.063px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 19px;
}

.ads-campaign-statistic .statistic-item p {
    font-size: 9.574px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

.statistic-item span {
    font-size: 9.574px;
    font-weight: 400;
    line-height: normal;
}

.badge-txt {
    border-radius: 3.419px;
    background: linear-gradient(134deg,
            rgba(0, 21, 151, 0.2) 3.03%,
            rgba(1, 16, 77, 0.2) 47.1%,
            rgba(2, 12, 28, 0.2) 80.14%,
            rgba(2, 11, 9, 0.2) 96.45%);
    padding: 4px 5px;
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.gredient-txt2 {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ads-campaign-statistic p {
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    max-width: 507px;
    margin-bottom: 35px;
}

.ads-chart {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}

.ads-chart-header {
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    padding: 24px 30px;
}

.ads-chart-header h2 {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: normal;
}

.ads-chart-header .monthpicker.chart {
    background: transparent;
    border-radius: 0;
}

.chart-indicator h4 {
    color: #303030;
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
}

.chart-indicator h4 span {
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.chart-lavel {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    padding-left: 22.68px;
}

.chart-lavel-red {
    color: #F00;
}

.chart-lavel-green {
    color: #04B004;
}

.chart-lavel:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 15.43px;
    height: 9.292px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.chart-lavel-green::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10' fill='none'%3E%3Cpath d='M15.5885 9.80676H1.19334C0.761572 9.80676 0.520484 9.35089 0.787872 9.03967L7.98543 0.693657C8.19145 0.454762 8.58815 0.454762 8.79636 0.693657L15.9939 9.03967C16.2613 9.35089 16.0202 9.80676 15.5885 9.80676Z' fill='%2304B004'/%3E%3C/svg%3E");
}

.chart-lavel-red::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10' fill='none'%3E%3Cpath d='M14.9986 0.514526H0.603494C0.171728 0.514526 -0.0693594 0.970401 0.198029 1.28162L7.39559 9.62763C7.60161 9.86653 7.9983 9.86653 8.20652 9.62763L15.4041 1.28162C15.6715 0.970401 15.4304 0.514526 14.9986 0.514526Z' fill='%23FF0000'/%3E%3C/svg%3E");
}

.summary-table th {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.summary-table td {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.summary-table table tr:not(:first-child) {
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}

.summary-table table {
    border-collapse: separate;
    border-spacing: 0 20px;
}

.summary-table table td {
    padding: 10px 0;
    background: #FFF;
}

.summary-table table tr td:first-child {
    border-radius: 15px 0 0 15px;
}

.summary-table table tr td:last-child {
    border-radius: 0px 15px 15px 0px;
}

.summary-adds img {
    width: 69px;
    height: 69px;
    object-fit: cover;
    border-radius: 10px;
}

.summary-adds-img::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Ccircle cx='8.92063' cy='9.22396' r='8.28' fill='white'/%3E%3Cpath d='M12.7807 9.88998L7.23481 13.1078C6.76416 13.3807 6.16016 13.0503 6.16016 12.5004V6.06462C6.16016 5.51552 6.76329 5.18432 7.23481 5.458L12.7807 8.67587C12.8877 8.73699 12.9767 8.82533 13.0386 8.93195C13.1005 9.03856 13.1331 9.15964 13.1331 9.28292C13.1331 9.4062 13.1005 9.52729 13.0386 9.6339C12.9767 9.74051 12.8877 9.82886 12.7807 9.88998Z' fill='url(%23paint0_linear_9473_43119)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9473_43119' x1='8.02876' y1='3.68647' x2='15.4155' y2='10.093' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 16.56px;
    height: 16.56px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.summary-adds-img {
    position: relative;
    width: 69px;
    height: 69px;
}

.summary-adds-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0.5;
    background: #303030;
    height: 100%;
    width: 100%;
}

.summary-adds {
    gap: 11px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#Published {
    color: #001C93;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    border-radius: 15px;
    background: #D8DBFF;
    padding: 8px 10px;
}
.advertisement-option .dropdown{
    box-shadow: none;
    border: 1px solid  #001597;
    width: 118px;
}