.grid-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.height-184 {
  height: 184px;
}

.common-shadow {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  padding: 30px;
}

.local-right-sec {
  width: 374px;
}

.local-left-sec {
  width: calc(100% - 404px);
}

.booking-list--item,
.travel-detail--item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15.542px 0px rgba(0, 28, 147, 0.13);
  padding: 20px;
}

.booking-list--profile img,
.travel--profile img {
  width: 46px;
  height: 46px;
  border-radius: 15px;
  border: 1.278px solid #001597;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  object-fit: cover;
}

.list-profile--name {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
}

.number-of-guest {
  color: #303030;
  font-size: 12px;
  opacity: 0.5;
  font-weight: 400;
}

.booking-list--profile ul,
.travel--profile ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.booking-list--profile {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.booking-list--item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-list--date {
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}

.booking--status {
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  padding: 5px 12px;
  border-radius: 11px;
}

.booking--status#Paid {
  color: #001c93;
  background: #d8dbff;
}

.booking--status#Pending {
  color: #ffa621;
  background: #fff5ee;
}

.booking--list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.travel--detail {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.travel--profile {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.travel-place {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
}

.travel-period {
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}

.about-travel span {
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
}

.about-travel p {
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
  margin: 0;
  padding-top: 10px;
}

.hotel-btn {
  text-align: center;
}

.hotel-btn button {
  border: none;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid #001597;
}

.rating-star-container img {
  width: 10px;
  height: 10px;
}

.rating-star-container {
  color: #30303099;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  column-gap: 6px;
  align-items: center;
}

.slider-for-local .swiper-slide img {
  height: 199px;
  width: 100%;
  border-radius: 10px;
}

.slider-for-local {
  margin-top: 24px;
  margin-bottom: 37px;
}

.slider-for-local .swiper-button-prev,
.slider-for-local .swiper-button-next {
  width: 30px;
  height: 30px;
}

.slider-for-local .swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='rotate(180 20 20)' fill='url(%23paint0_linear_3696_120058)' stroke='url(%23paint1_linear_3696_120058)' stroke-width='0.625'/%3E%3Cpath d='M23.5979 10.7347L14.3652 20.4928L23.5979 30.2566' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  left: -15px;
}

.slider-for-local .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' fill='url(%23paint0_linear_3696_120058)' stroke='url(%23paint1_linear_3696_120058)' stroke-width='0.625'/%3E%3Cpath d='M16.4021 29.2653L25.6348 19.5072L16.4021 9.74341' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  right: -15px;
}

.local-tabs .react-tabs__tab-list {
  border-bottom: none;
}
.local-tabs .react-tabs__tab.react-tabs__tab--selected {
  background: #fff;
  border: 0.5px solid #e1e2e5;
  border-radius: 47px;
}
.local-tabs .react-tabs__tab.react-tabs__tab--selected span {
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.local-tabs.event-tab .react-tabs__tab {
  width: 50%;
}
.local-tabs .react-tabs__tab {
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  width: 33.3%;
  text-align: center;
  /* margin: 0 10px; */
}
.local-tabs .react-tabs__tab-list {
  text-align: center;
}
.local-component img {
  height: 143px;
  width: 100%;
  border-radius: 15px;
  margin-bottom: 15px;
}
.local-component {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
  padding: 20px;
}
.local-component span {
  font-size: 14px;
  font-weight: 400;
  color: rgb(48, 48, 48, 80%);
  padding-top: 7px;
}
.hotel-btn span {
  font-size: 14px;
}
.hotel-btn {
  margin-top: 18px;
}
.local-component p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
}
.local_manager {
  font-size: 36px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 43.88px;
  margin-bottom: 30px;
}
.local_manager_section {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 211px));
  gap: 17px;
}
.local-manager-item {
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.local-manager-item-info p {
  color: rgba(11, 10, 10, 1);
  font-size: 14px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 17px;
  margin-bottom: 5px;
}
.local-manager-item-info span {
  color: rgba(48, 48, 48, 1);
  /* font-style: italic; */
  font-weight: 800;
  font-size: 18px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.local-manager-item-info span .positive-trend {
  color: rgba(0, 182, 155, 1);
  font-weight: 600;
  font-size: 14px;
}
.local-manager-item-info span .negetive-trend {
  color: rgba(230, 41, 77, 1);
  font-weight: 600;
  font-size: 14px;
}

.local_location {
  font-size: 24px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 29.26px;
}
.local_location h1 {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin: 12px 0;
}

.local_product {
  padding: 12px 13px 12px 13px;
  gap: 12px;
  border-radius: 15px;
  border: 1px solid var(--Border-Light, #e4e4e4);
  height: 379px;
}
.local_product:hover {
  cursor: pointer;
}

.local_button_grp {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.local_view_btn {
  border-radius: 16px;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  height: 35px;
  padding: 0px 10px;
  font-weight: 600;
  color: rgba(158, 158, 158, 1);
  transition: all 0.2s ease-in;
  border-radius: 22px;
}
.local_view_btn span {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: rgba(158, 158, 158, 1);
  transition: all 0.3s ease;
}
.local_view_btn:hover {
  border: 1px solid #001597;
}
.local_view_btn:hover span {
  color: #001597;
}

.add-item-model button.edit_local_btn {
  border-radius: 16px;
  border: 2px solid #001597;
  background: transparent;
  padding: 0px 10px;
  height: 27px;
}
.add-item-model button.edit_local_btn span {
  font-size: 14px;
  font-weight: 900;
  /* font-style: italic; */
}
.local_product_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 350px));
  border-radius: 15px;
  gap: 29px;
}

.add_new_border {
  /* padding: 324px 19px 19px;  */
  height: 100%;
  gap: 10px;
  border-radius: 15px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  border: 1px dashed #9e9e9e;
  align-content: center;
  background: rgba(249, 249, 249, 1);
}

.local_product .add_new_border .image_icon_text {
  text-align: center;
  justify-items: center;
}
.image_icon_text h3 {
  margin-top: 20px;
  font-family: Montserrat;
  font-size: 18.3px;
  font-weight: 400;
  line-height: 22.31px;
  text-align: center;
  color: #9e9e9e;
}

.add-item-content .all_type_name {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  border-radius: 15px;
  gap: 10px;
}
.add-item-model .local_type_modal.add-item-content {
  max-width: 800px;
  width: 100%;
  max-height: 80vh;
  height: auto;
  padding-right: 20px;
}
.add-item-model .add-item-content.contact_padding {
  max-width: 800px;
  width: 100%;
  max-height: 700px;
  height: auto;
  padding-right: 10px !important;
}

.local_type_modal {
  padding: 20px;
}

.type_name {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.type_name.selected {
  border: 1.8px solid #001597;
}
.type_name:hover {
  background: rgba(249, 249, 249, 1);
  cursor: pointer;
}
.add-item-model .local_button_row .local_type_cancel {
  height: 48px;
  padding: 0 22px;
  border-radius: 100px;
  border: 1px solid #001597;
  background: #fff;
  transition: all 0.3s ease;
}
.add-item-model .local_button_row .local_type_cancel:hover {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}
.add-item-model .local_button_row .local_type_cancel:hover span {
  -webkit-text-fill-color: #fff;
}
.add-item-model .local_button_row .local_type_cancel span {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  /* font-style: italic; */
  transition: all 0.3s ease;
}
.add-item-model .local_button_row .local_type_next {
  height: 48px;
  padding: 0 22px;
  border-radius: 100px;
  border: 1px solid #001597;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  transition: all 0.3s ease;
}
.add-item-model .local_button_row .local_type_next:hover {
  background: #fff;
}
.add-item-model .local_button_row .local_type_next:hover span {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.add-item-model .local_button_row .local_type_next span {
  -webkit-text-fill-color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  /* font-style: italic; */
  transition: all 0.3s ease;
}
.type_close {
  display: flex;
  justify-content: space-between;
  color: rgba(11, 10, 10, 1);
  font-size: 18px;
  /* font-style: italic; */
  font-weight: 900;
  text-transform: uppercase;
}

.type_close button {
  border: none !important;
  padding: 0 !important;
  height: 25px;
  width: 25px;
}

.all_type_name .type_name h1 {
  font-family: Montserrat;
  font-size: 18px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 21.94px;
  text-align: center;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  margin-bottom: 5px;
  margin-top: 10px;
  -webkit-text-fill-color: transparent;
}
.all_type_name .type_name p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.local_button_row {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.dark-outline-btn {
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 100px;
  padding: 8px 24px 8px 24px;
  background: rgba(249, 249, 249, 1);
  color: rgba(11, 10, 10, 1);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 40px;
}
.tool-option {
  gap: 20px;
}
.drop-search-3-0 .search-input {
  border: 1px solid rgba(225, 226, 229, 1);
  border-radius: 40px;
  height: 48px;
  color: rgba(11, 10, 10, 1);
  font-size: 16px;
  font-weight: 400;
  padding: 0 63px 0 53px;
  background-color: rgba(249, 249, 249, 1);
  background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4421 19.2138L14.573 13.3304C15.8923 11.7009 16.5356 9.62783 16.3703 7.53844C16.2049 5.44906 15.2435 3.50271 13.6842 2.10066C12.125 0.698606 10.0867 -0.0522897 7.98977 0.00283362C5.89279 0.0579569 3.89692 0.914887 2.41362 2.39692C0.93032 3.87895 0.0726551 5.87311 0.0174845 7.96829C-0.0376861 10.0635 0.713853 12.0999 2.11711 13.6578C3.52036 15.2157 5.46838 16.1763 7.55955 16.3416C9.65073 16.5068 11.7256 15.864 13.3565 14.5458L19.2256 20.4099C19.3053 20.49 19.4001 20.5535 19.5045 20.5969C19.6089 20.6403 19.7207 20.6626 19.8338 20.6626C19.9468 20.6626 20.0588 20.6403 20.1632 20.5969C20.2676 20.5535 20.3624 20.49 20.4421 20.4099C20.597 20.2493 20.6835 20.0349 20.6835 19.8119C20.6835 19.5888 20.597 19.3745 20.4421 19.2138ZM8.2289 14.6669C6.95267 14.6679 5.70494 14.2905 4.64339 13.5827C3.58185 12.8749 2.7542 11.8683 2.26536 10.6904C1.77653 9.51255 1.6484 8.21629 1.89714 6.96561C2.14589 5.71493 2.76043 4.56608 3.66286 3.66442C4.56529 2.76276 5.71513 2.1488 6.96688 1.90027C8.21863 1.65174 9.51595 1.77976 10.6948 2.26817C11.8737 2.75659 12.8812 3.58348 13.5896 4.64411C14.298 5.70475 14.6757 6.95147 14.6747 8.22661C14.6722 9.93389 13.9922 11.5705 12.7839 12.7777C11.5757 13.985 9.93764 14.6643 8.2289 14.6669Z' fill='%238D8D8D'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: 17px;
  background-position-y: center;
}
.drop-search-3-0 .search-input::placeholder {
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}
.drop-search-3-0 .dropdown {
  border: 1px solid rgba(225, 226, 229, 1);
  border-radius: 50px;
  height: 44px;
  width: auto;
  padding: 10px 22px;
  margin: 0;
}
.drop-search-3-0 .dropdown__text {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  background: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(80, 80, 80, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
.drop-search-3-0 .dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid rgba(80, 80, 80, 1);
  border-width: 0 2px 2px 0;
  float: right;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -6px;
}
.drop-search-3-0 .dropdown.active .dropdown__items {
  outline: 1px solid rgba(225, 226, 229, 1);
  border-radius: 0 0 16px 16px;
  border-top: 0px solid transparent;
}

.logo_btn .image_validation {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #8d8d8d;
}

.add_logo {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  border: 1px dashed #9e9e9e;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(249, 249, 249, 1);
  /* overflow: hidden; */
  position: relative;
  box-shadow: 0px 0px 1.94px 0px rgba(82, 106, 103, 0.19);
}

.add_logo h3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 22.31px;
  text-align: center;
  color: #9e9e9e;
}

.add_logo .logo_show_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.logo_btn {
  display: flex;
  gap: 20px;
  align-items: center;
}

.add-item-content .amenities_name {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 20px;
}

.platform-size {
  width: 182px;
  height: 124px;
}

.type_amenities {
  display: flex;
  text-align: center;
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  transition: all 0.3s ease;
}
.type_amenities:hover {
  background: rgba(249, 249, 249, 1);
}
.type_amenities.selected {
  border: 1px solid #01104d;
}

.type_amenities .hotel_amenities {
  width: 80px;
  height: 80px;
}

/* .image_upload_section {
  width: 40%;
} */
.person_detail_section {
  /* width: 85%; */
}
.add_photos {
  height: 100px;
  width: 100px;
  gap: 10px;
  border-radius: 50%;
  border: 1px dashed #9e9e9e;
  align-content: center;
  background: rgba(249, 249, 249, 1);
  position: relative;
}
.add_photos .add_logo_text {
  height: 100%;
  width: 100%;
  align-content: center;
}
.add_photos .logo_show_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.add_photos .logo_show_imgs {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.hotel_upload_div {
  margin-top: 50px;
  text-align: center;
  border: 1px dashed #3030304d;
  padding: 17px 27px;
  border-radius: 13.61px;
}

.hotel_image_upload_section {
  /* background: #8d8d8d0d; */
  background: #fff;
}

.location_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hotels_dropdown {
  border: none;
}

.hotels_dropdown .dropdown__text {
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 24.38px;
  text-align: left;
  color: var(--MAIN, #0b0a0a);
  /* justify-content: space-around; */
  justify-content: flex-start;
}

.location_section h1 {
  font-family: Montserrat;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 900;
  line-height: 19.5px;
  text-align: center;
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}

.details_section {
  display: flex;
  justify-content: space-between;
}

.name_logo img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.name_logo {
  display: flex;
}

.name_logo h1 {
  /* font-family: Montserrat;
  font-size: 18px; */
  /* font-style: italic; */
  /* font-weight: 900;
  line-height: 21.94px;
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.profile-tag {
  border-radius: 16px;
  background: #d6ffd6;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
}

.profile-tag span {
  background: #00b800;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.description_link {
  max-width: 550px;
}

.local_type_modal .item-details-input label {
  color: #0b0a0a;
  font-size: 16px;
  font-weight: 400;
  /* margin-bottom: 5px; */
}
.local_type_modal .item-details-input input,
.local_type_modal .item-details-input textarea {
  background: #fafafa;
  box-shadow: none;
  border: 0px solid rgba(228, 228, 228, 1);
  color: #0b0a0a;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  outline: none;
  padding: 14px 15px;
  width: 100%;
  margin-bottom: 20px;
  min-height: 45px;
}
.local_type_modal .item-details-input input {
  border-radius: 999px;
}
.local_type_modal .item-details-input textarea {
  border-radius: 10px;
}
.local_type_modal .item-details-input input::placeholder {
  color: #a5a1a1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  line-height: 17.07px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.local_type_modal .item-details-input .dropdown {
  border-radius: 999px;
  width: auto;
  padding: 14px 15px;
  margin: 0;
  background: #fafafa !important;
  box-shadow: none !important;
  border: 0px solid rgba(228, 228, 228, 1) !important;
}

.local_type_modal .item-details-input .dropdown__text {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  background: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(80, 80, 80, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}
.local_type_modal .item-details-input .dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid rgba(80, 80, 80, 1);
  border-width: 0 2px 2px 0;
  float: right;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -6px;
}
.local_type_modal .item-details-input .dropdown.active .dropdown__items {
  outline: 1px solid rgba(225, 226, 229, 1);
  border-radius: 0 0 16px 16px;
  border-top: 0px solid transparent;
}

.edit_local_btn {
  color: #fff;
  font-weight: 600;
  line-height: normal;
  border-radius: 22px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  height: 35px;
  padding: 0px 10px;
  transition: all 0.3s ease;
}
.edit_local_btn span {
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: #fff;
  -webkit-text-fill-color: #ffffff;
}
.contact-details h4 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  color: #0b0a0a;
  margin-top: 20px;
  margin-bottom: 5px;
}

.contact-details .hotel-details {
  display: flex;
  padding-bottom: 20px;
}

.podcast-profile-img {
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 1px solid #e4e4e4;
  border-radius: 999px;
}

.podcast-profile-img-content {
  display: flex;
  gap: 15px;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
}

.podcast-profile-img .add_image {
  width: 25px;
  height: 25px;
}

.podcast-profile-img span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  color: #212121;
}

.hotel-details h5 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  /* color: #0b0a0a; */
  color: #0b0a0a;
  margin-bottom: 5px;
}
.hotel-details .podcast-profile-detail p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #5a5a5a;
  padding-top: 0;
}

.details_section_profile_header {
  display: flex;
  justify-content: space-between;
}
.details_section_button_row {
  gap: 15px;
}
.details_section_button_row .local_type_cancel {
  height: 40px;
  padding: 0 22px;
  border-radius: 100px;
  border: 1.8px solid #041849bf;
  background: #fff;
  transition: all 0.3s ease;
}
.details_section_button_row .local_type_cancel:hover {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}
.details_section_button_row .local_type_cancel:hover span {
  -webkit-text-fill-color: #fff;
}
.details_section_button_row .local_type_cancel span {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  font-style: italic;
  transition: all 0.3s ease;
  padding-right: 5px;
}

.details_section_button_row .local_type_next {
  height: 40px;
  padding: 0 22px;
  border-radius: 100px;
  border: 1px solid #001597;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  transition: all 0.3s ease;
}
.details_section_button_row .local_type_next:hover {
  background: #fff;
}
.details_section_button_row .local_type_next:hover span {
  -webkit-text-fill-color: #01104d;
}
.details_section_button_row .local_type_next span {
  -webkit-text-fill-color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  /* font-style: italic; */
  transition: all 0.3s ease;
  font-style: italic;
}

.impress-section {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 284px));
  gap: 10px;
}

.local_box {
  margin-top: 20px;
  margin-bottom: 20px;
}

.list_overview .react-tabs__tab-list {
  position: sticky;
  top: 0;
  background-color: #fafafa;
  z-index: 1 !important;
  padding: 0px 0 0 0;
  box-shadow: none;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  border-radius: 47px;
}

.image_hotel {
  grid-template-columns: repeat(2, minmax(0, 274px));
  display: grid;
  gap: 10px;
}
.image_hotel img {
  width: 274px;
  height: 150px;
  border-radius: 10px;
}

.room-list {
  /* grid-template-columns: repeat(auto-fit, minmax(0, 280px));
  display: grid;
  gap: 15px; */
}
.room-lists {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
  gap: 15px;
}
.offers--hotel {
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 10px;
  background: #fff;
}
.hotels_dropdown.dropdown.active .dropdown__items {
  outline: 1px solid rgba(228, 228, 228, 1);
  border-radius: 16px;
  border-top: 0px solid transparent;
}
.room-card {
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 10px;
  cursor: pointer;
}
.room-card h4 {
  font-size: 14px;
  font-weight: 700;
  color: rgba(21, 21, 21, 1);
  margin-bottom: 4px;
}
.room-facilities p {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  color: rgba(80, 80, 80, 1);
  margin-bottom: 0;
}

.room-facilities {
  grid-template-columns: repeat(2, minmax(0, 100px));
  display: grid;
  gap: 5px;
  padding-bottom: 14px;
}
.room-price {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}
.local-room-detail {
  padding: 18px 10px;
}
.local-room-main h3 {
  font-size: 18px;
  font-weight: 900;
  font-style: italic;
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}
.local-tabs.section-local .react-tabs__tab-list {
  text-align: left;
}
.discount-badge {
  font-size: 12px;
  color: rgba(6, 36, 27, 1);
  font-weight: 500;
  background: #fff;
  border-radius: 999px;
  padding: 2px 10px;
  position: absolute;
  top: 10px;
  right: 0;
}
.image-buttons-discounts button {
  border: none;
  background: transparent;
}
.image-buttons-discounts {
  column-gap: 30px;
  display: flex;
  align-items: center;
}
.discount_list th {
  font-size: 16px;
  color: rgba(141, 141, 141, 1);
  font-weight: 600;
}
.discount_list td {
  color: rgba(80, 80, 80, 1);
  font-size: 16px;
  font-weight: 400;
  padding: 20px 10px;
  border: 1px solid rgba(228, 228, 228, 1);
  border-left: none;
  border-right: none;
  cursor: pointer;
}
/* .discount_list td span {
  color: #151515;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  border-left: none;
  border-right: none;
  align-content: center;
} */
.discount_list table {
  border-collapse: initial;
  border-spacing: 0 10px;
}
.discount_list td:first-child {
  border-left: 1px solid rgba(228, 228, 228, 1);
  border-radius: 15px 0 0 15px;
}
.discount_list td:last-child {
  border-right: 1px solid rgba(228, 228, 228, 1);
  border-radius: 0 15px 15px 0;
}
.add_room_text {
  text-align: center;
  background: rgba(249, 249, 249, 1);
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 10px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tag-local {
  border: 1px solid rgba(228, 228, 228, 1);
  cursor: pointer;
}
.tag-local:hover .published-tooltip-main {
  display: block;
  z-index: 1;
}
.published-tooltip {
  width: 220px;
  border: 1px solid rgba(228, 228, 228, 1);
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.published-tooltip-main {
  position: absolute;
  top: 95%;
  display: none;
  padding-top: 12px;
}
.published-item {
  border: 1px solid rgba(228, 228, 228, 1);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip-ico {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 8.5L9.70711 1.70711C9.31658 1.31658 8.68342 1.31658 8.29289 1.70711L1.5 8.5' stroke='%23E4E4E4'/%3E%3Cpath d='M8.85858 2.14142L2 9H15.5172C15.6953 9 15.7846 8.78457 15.6586 8.65858L9.14142 2.14142C9.06332 2.06332 8.93668 2.06332 8.85858 2.14142Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A");
  width: 15px;
  height: 8px;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}
.unpublished-btn {
  font-size: 10px;
  font-weight: 900;
  /* font-style: italic; */
  color: #fff;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 100px;
}
.unpublished-drop {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7375 0.369141L8 6.09414L2.2625 0.369141L0.5 2.13164L8 9.63164L15.5 2.13164L13.7375 0.369141Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  border-left: 1px solid rgba(249, 249, 249, 0.4);
  position: relative;
  z-index: 9;
}
.local_type_next:hover .unpublished-drop {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7375 0.369141L8 6.09414L2.2625 0.369141L0.5 2.13164L8 9.63164L15.5 2.13164L13.7375 0.369141Z' fill='%2301104d'/%3E%3C/svg%3E%0A");
  border-left: 1px solid #01104d;
}
.unpublished-drop-main {
  position: absolute;
  top: 95%;
  padding-top: 16px;
  right: -22px;
  display: none;
}
.uppublished-drop-btn {
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  background: #fff;
  color: #01104d;
  font-size: 16px;
  font-weight: 900;
  /* font-style: italic; */
  padding: 14px;
  border-radius: 10px;
  width: 146px;
}
.unpublished-drop:hover .unpublished-drop-main {
  display: block;
}
/* 
.edit_logo_local{
  position: absolute;
  top: 0px;
  right: 20px;
  border:none !important;
} */

.edit_logo_local {
  column-gap: 15px;
  position: absolute;
  top: 0%;
  right: 0%;
}
.edit_logo_local label {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='18' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-bottom: 0;
}

.back_btn {
  cursor: pointer;
}

.platform_checkbox {
  margin-top: 10px;
}

.contact_person_img_upload h3 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: center;
  color: #9e9e9e;
  margin-top: 10px;
}

.upload_btn_logo .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
}

.add_photos .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
  background: none !important;
  border: none !important;
}
.add_photos .ant-upload .contact_person_img {
  width: 100% !important;
  height: 100% !important;
}

.upload_btn_logo .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
  background: none !important;
  border: none !important;
}

/* .hotel_image_upload_section .upload-input .ant-upload {
  width: 100% !important;
  height: 364px !important;
} */

.event_time .ant-picker-input input {
  /* margin-top: 10px !important; */
  min-height: auto;
}

.collapse-button .upIcon {
  rotate: 180deg;
  transition: rotate 0.5s;
}
.collapse-button .downIcon {
  transition: 0.5s;
}

.local-dropdown .dropdown__text {
  -webkit-text-fill-color: #a5a5a5 !important;
}

/* .local-dropdown .dropdown__text.selected {
  -webkit-text-fill-color: #303030 !important;
} */
.selected_class_local .dropdown__text {
  -webkit-text-fill-color: rgba(11, 10, 10, 1) !important;
}

.add_contact_person {
  border: none;
  /* background: #fff; */
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 10px;
}

.particular_skeleton {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
}

.published-item .publish_icon {
  border-radius: 0% !important;
}

.hotel_text:hover {
  cursor: pointer;
}

.local_sticky_header {
  position: sticky;
  top: 0px;
  z-index: 1000;
  padding-right: 12px;
}

.local_scroll_bars {
  overflow: auto;
  height: 550px;
  padding-right: 12px;
}
.local_contact_scroll_bars {
  overflow: auto;
  max-height: calc(80vh - 220px);
  padding-right: 10px;
  padding-left: 9px;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.selected_class_local.active {
  border-radius: 24px 24px 0 0 !important;
}
.local-dropdown.active {
  border-radius: 24px 24px 0 0 !important;
}
.selected_class_local.active .dropdown__items {
  border-radius: 0 0 24px 24px !important;
}
.local-dropdown.active .dropdown__items {
  border-radius: 0 0 24px 24px !important;
}

.local-dropdowns.active {
  border-radius: 24px 24px 0 0 !important;
}
.local-dropdowns.active .dropdown__items {
  border-radius: 0 0 24px 24px !important;
}
.local_type_modal .dropdown.active:before {
  background: none !important;
}

.type_close .cancel_icon:hover {
  filter: opacity(75%);
}

.image .image_remove_icon {
  background: #fff !important;
  margin-top: 1px !important;
  width: 27px;
  height: 27px;
}

.ant-upload-select:hover {
  cursor: pointer !important;
}
.cropper-user:hover {
  cursor: pointer !important;
}

.contact-details .position-relative:hover .local_contact_buttons {
  display: flex;
}

.local_contact_buttons {
  display: none;
  column-gap: 15px;
  position: absolute;
  top: 6px;
  right: 30px;
}
.local_contact_buttons label {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 3.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.local_contact_buttons button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 3.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116856)'%3E%3Cpath d='M6 19.8871C6 20.9871 6.9 21.8871 8 21.8871H16C17.1 21.8871 18 20.9871 18 19.8871V7.88708H6V19.8871ZM19 4.88708H15.5L14.5 3.88708H9.5L8.5 4.88708H5V6.88708H19V4.88708Z' fill='url(%23paint0_linear_9174_116856)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116856' x1='8.7517' y1='0.04721' x2='25.2404' y2='12.5615' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116856'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.podcast-profile-img .add_image_local {
  width: 25px;
  height: 25px;
  box-shadow: none;
}

.new_contact_add {
  display: flex;
  align-items: center;
}

.image_hotel_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image_hotel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.no_images_text {
  text-align: center;
  width: 100%;
  font-size: 18px;
  color: #666;
}

.location_pin {
  width: 18px;
  height: 18px;
}

.local_sticky_btn {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0 1px;
  z-index: 10;
}

.price_night {
  text-align: end;
  min-width: 93px;
}

.local_item_name {
  width: 331px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.local-dropdown .dropdown__text {
  -webkit-text-fill-color: #8d8d8d !important;
  font-weight: 400 !important;
}

/* .local-dropdown .dropdown__text.selected {
  -webkit-text-fill-color: #303030 !important;
} */
.selected-class .dropdown__text {
  -webkit-text-fill-color: #303030 !important;
}

.max-photos-text {
  font-size: 0.9rem !important;
  color: #888;
  margin-bottom: 0px !important;
}

.disabledDropdown {
  pointer-events: none;
  opacity: 0.5;
  background-color: #e0e0e0;
  color: #e6e6e6;
  cursor: not-allowed;
}

.description_link.expanded {
  display: block;
  height: auto;
  overflow: visible;
}

.see_more_local {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #01104d;
  cursor: pointer;
  margin-left: 4px;
  font-style: italic;
}

.contact_designation {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.local_images_show {
  gap: 10px !important;
}

.edit_local_btn {
  border: 1.8px solid #041849bf !important;
}

.disabled-checkbox {
  pointer-events: none;
  opacity: 0.5;
}

.image_local_last_popup {
  padding-left: 17px !important;
}

.hotel_check_in {
  width: 243px;
}
.ant-picker.hotel_check_in {
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
}

.see-more-button {
  margin-left: auto !important;
  margin-right: auto !important;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.see-more-button span {
  font-size: 18px;
  margin-right: 5px;
}

.upIcon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}


.ant-picker-dropdown.custom-timepicker-dropdown .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-time-panel-column::after{
  height: 0% !important;
}

.add_room_modal .dropdown.active .dropdown__items{
  outline: 1px solid rgba(225, 226, 229, 1) !important;
}

.add_room_modal .dropdown__text {
  -webkit-text-fill-color: #a0a0a0 !important;
  background: none !important;
  font-size: 15px !important;
}

.local_type_modal .item-details-input .local_event_description textarea::placeholder {
  color: #a5a1a1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  line-height: 17.07px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.add_room_modal .item-details-input .local_event_description textarea::placeholder {
  color: #a5a1a1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  line-height: 17.07px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}