.recent-job-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 30px;
}

.job--grid-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px;
}

.job--details img {
    width: 70px;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
}

.job--details p {
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
    margin-bottom: 0;
}

.job--grid-item h5 {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.job--grid-item p {
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 400;
    line-height: 140.4%;
    margin-bottom: 20px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.job--details {
    margin-bottom: 20px;
}

.find-job-details {
    color: #fff;
    max-width: 445px;
    position: relative;
    z-index: 1;
}

.find-job-details h2 {
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.find-job-details p {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
    max-width: 417px;
}

.find-job-cover {
    position: relative;
    border-radius: 15px;
    padding: 60px 20px 60px 60px;
}

.find-job-cover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: #303030;
    opacity: 0.6;
}

.find-job-cover input {
    border-radius: 16px;
    border: 1px solid #8D8D8D;
    background: #FFF;
    width: 583px;
    height: 64px;
    color: #8D8D8D;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    padding-left: 20px;
}

.find-job-cover input::placeholder {
    color: #8D8D8D;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.input-serchbg {
    width: fit-content;
}

.input-serchbg::after {
    content: "";
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51' height='46' viewBox='0 0 51 46' fill='none'%3E%3Crect width='51' height='45.2697' rx='16' fill='url(%23paint0_linear_2834_102415)'/%3E%3Cpath d='M29.9648 27.0898L37.3531 34.4781M23.3974 29.5525C28.3846 29.5525 32.4275 25.5096 32.4275 20.5224C32.4275 15.5351 28.3846 11.4922 23.3974 11.4922C18.4101 11.4922 14.3672 15.5351 14.3672 20.5224C14.3672 25.5096 18.4101 29.5525 23.3974 29.5525Z' stroke='white' stroke-width='1.96146' stroke-linecap='square'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2834_102415' x1='13.6669' y1='-9.65721' x2='56.5307' y2='37.464' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    width: 51px;
    height: 45px;
    background-repeat: no-repeat;
    background-size: cover;
    right: 9px;
    top: 50%;
    transform: translate(0, -50%);
}

.job-categories-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
    column-gap: 22px;
    row-gap: 30px;
}
@media only screen and (max-width: 1300px) {
    .job-categories-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

.job-categories-item {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    text-align: center;
    padding: 20px;
}

.job-categories-item p {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 15px 0;
}

.job-categories-item span {
    color: #8D8D8D;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.jobs-items-detsils {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.job-items-sub {
    column-gap: 25px;
}

.jobs-item-row {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.jobs-items-logo {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    border: 0.5px solid #8D8D8D;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.jobs-items-logo img {
    max-width: 100%;
}

.jobs-items-discription h4 {
    color: #303030;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 22px;
}

.job-items-company {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 22px;
    justify-content: space-between;
}

.job-items-company li {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.job-items-skill h6 {
    margin: 0;
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-radius: 25px;
    border: 1px solid #F6F6F6;
    background: #F6F6F6;
    padding: 4px 15px;
    width: fit-content;
}

.job-items-skill ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0;
    padding: 0;
}

.job-items-skill {
    display: flex;
    align-items: center;
}

.job-items-skill ul li {
    border-radius: 25px;
    border: 1px solid #8D8D8D;
    background: #F9F9F9;
    padding: 4px 15px;
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.job-items-apply p {
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
    margin: 8px 0 0 0;
    text-align: center;
}

.job-newslatter {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.job-newslatter h5 {
    color: #303030;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.job-newslatter input,
.job-newslatter input::placeholder {
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.job-newslatter input {
    border-radius: 16px;
    border: 1px solid #8D8D8D;
    background: #FFF;
    padding: 11px 20px 13px 20px;
    margin-right: 10px;
}

.job-responsibilities,
.job-requirements {
    list-style-type: none;
    margin: 0;
}

.job-responsibilities li,
.job-requirements li {
    position: relative;
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.9;
    margin-bottom: 18px;
}

.job-responsibilities li::before,
.job-requirements li::before {
    position: absolute;
    content: "";
    left: -2rem;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M6.225 11.45L9.075 14.1355L14.775 8.125M10.5 20C9.25244 20 8.0171 19.7543 6.86451 19.2769C5.71191 18.7994 4.66464 18.0997 3.78249 17.2175C2.90033 16.3354 2.20056 15.2881 1.72314 14.1355C1.24572 12.9829 1 11.7476 1 10.5C1 9.25244 1.24572 8.0171 1.72314 6.86451C2.20056 5.71191 2.90033 4.66464 3.78249 3.78249C4.66464 2.90033 5.71191 2.20056 6.86451 1.72314C8.0171 1.24572 9.25244 1 10.5 1C13.0196 1 15.4359 2.00089 17.2175 3.78249C18.9991 5.56408 20 7.98044 20 10.5C20 13.0196 18.9991 15.4359 17.2175 17.2175C15.4359 18.9991 13.0196 20 10.5 20Z' stroke='url(%23paint0_linear_3062_111404)' stroke-width='2' stroke-linecap='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3062_111404' x1='6.09159' y1='-3.0532' x2='24.1571' y2='14.5752' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    width: 19px;
    height: 19px;
    background-size: cover;
    background-position: center;
}

.job-details-data {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.job-details-data span {
    width: 124px;
    display: inline-block;
    color: #35425B;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.job-details-data li {
    display: flex;
    color: #35425B;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    column-gap: 71px;
}

.job-detail-right {
    width: 362px;
}

.job-detail-left {
    width: calc(100% - 382px);
}
@media only screen and (max-width: 1200px) {
    .job-detail-right {
        width: 250px;
    }
    
    .job-detail-left {
        width: calc(100% - 270px);
    }
  }

.job-detail-left h4 {
    color: #303030;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.job-detail-left h3 {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}
.job-details-data li{
    margin-bottom: 20px;
}
.job-details-data{
    margin-top: 30px;
}
.job-detail-right .map{
    height: 276px;
    border-radius: 16px;
    overflow: hidden;
}
.find-job-details ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.find-job-details ul li{
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 23px;
}
