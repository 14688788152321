.logo {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 10px;
}
.header {
    background-color: --header-bg;
}
.id-button {
    /* font-size: 12px; */
}
.logos {
    height: 80px;
    width: 80px;
}
.title {
    font-family: Montserrat;
    font-size: 40px;
    font-style: italic;
    font-weight: 900;
    line-height: 48.76px;
    text-align: center;
    padding-right: 5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: transparent;
    background-clip: text;
}
.sub-title {
    font-family: Montserrat;
    font-size: 25px;
    font-style: italic;
    font-weight: 900;
    line-height: 30.48px;
    text-align: center;
    padding-right: 5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: var(--electric-purple);
    color: transparent;
    background-clip: text;
}
.description {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 17.07px;
    text-align: center;
    width: 60%;
    margin-top: 3px;
    padding-right: 5px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: transparent;
    background-clip: text;
}
.gradient-border {
    padding: 1px 1px;
}
.card-type {
    min-height: 207px;
    max-height: 207px;
    /* width: 350px; */
    background: var(--primary-sky);
}
.main-card {
    width: 380px;
    background: white;
    border-radius: 20px;
    padding: 15px;
    justify-items: center;
}