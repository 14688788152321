.available-service{
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.available-service-icon{
    width: 40px;
    height: 40px;
    border-radius: 15px;
    border: 1px solid #05104e;
    display: flex;
    align-items: center;
    justify-content: center;
}
.available-service li span{
    color: #303030;
    font-size: 12px;
    font-weight: 500;
}
.available-service li{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.available-service-main{
    width: 260px;
}
.status-and-overview{
    width: calc(100% - 287px);
}
.status-and-overview .dropdown{
    margin: 0;
    width: fit-content;
    width: 120px;
}
.strong-head{
    font-size: 18px;
    font-weight: 600;
}
.recent-project-tab #Completed{
    background: #00CBA4;
    color: #fff;
    border-radius: 15px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 500;
}
.recent-project-tab #Active{
    background: #D8DBFF;
    color: #05104e;
    border-radius: 15px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 500;
}
.progress-tab{
    font-size: 14px;
    font-weight: 400;
}
.progress-tab img{
    margin-right: 10px;
}
.completed-project{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.team-member-workspace{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.completed-project-item{
    border-radius: 5px;
background: #FFF;
box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
padding: 7px 10px;
display: flex;
align-items: center;
justify-content: space-between;
}
.team-member-item{
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 10px;
    display: flex;
align-items: center;
justify-content: space-between;
}
.completed-project-item h6{
    color: #303030;
font-size: 12px;
font-weight: 500;
line-height: normal;
margin-bottom: 7px;
}
.completed-project-item img{
    width: 20px;
    height: 20px;
    border-radius: 20px;
border: 1px solid #001597;
}
.completed-project-item span{
    color: #000;
font-size: 10px;
font-weight: 500;
line-height: normal;
}
.member-pic img{
    border-radius: 14px;
border: 1.308px solid  #001597;
box-shadow: 0px 0px 3.487px 0px rgba(0, 0, 0, 0.15);
width: 36px;
height: 36px;
}
.team-member-item ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.team-member-item .member-detail{
    display: flex;
    align-items: center;
    column-gap: 11px;
}
.team-member-item ul li:first-child{
    color:  #303030;
font-size: 12px;
font-weight: 600;
line-height: normal;
}
.team-member-item ul li:last-child{
    color: #303030;
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
}
.team-member-item button{
    font-size: 10px;
    column-gap: 5px;
    padding: 5px;
}
.team-member-all button{
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 14px;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
border: none;
border-bottom: 1px solid #01104D;
}
.assignee-user img{
    width: 30px;
    height: 30px;
    border-radius: 30.002px;
border: 2.705px solid #FFF;
margin-left: -12px;
display: none;
}
.assignee-user img:nth-child(1),.assignee-user img:nth-child(2),.assignee-user img:nth-child(3),.assignee-user img:nth-child(4){
    display: block;
}
.assignee-user img:first-child{
    margin-left: 0;
}
.total-assignee{
    width: 30px;
    height: 30px;
    border-radius: 30.002px;
border: 2.705px solid #FFF;
margin-left: -12px;
background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
color: #FFF;
font-size: 14px;
font-weight: 500;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}