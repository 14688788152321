.settings{
    margin-left: 238px;
    margin-top: 100px;
    padding: 25px 30px;
}
.settings h2{
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 30px;
}
.settings-overview{
    border-radius: 15px 15px 0px 0px;
    background:  #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.settings .react-tabs__tab-list{
    margin-bottom: 0 !important;
    border-radius: 15px 15px 0px 0px;
    background:  #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.settings .react-tabs__tab-list{
    padding: 12px 18px 12px 18px;
}
.settings .item-wrapper{
    padding: 30px;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.profile-overview-item{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin-bottom: 30px;
}
.profile-overview-item:last-child{
    margin-bottom: 0;
}
.profile-overview-item h3{
    color:  #303030;
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 14px;
}
.profile-overview-item p{
    color:  #303030;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.profile-overview-item a{
    color:  #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 16px;
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    padding: 14px 22px;
    width: 156px;
}
.profile-item-detail{
    width: calc(100% - 156px);
}

.content-manager-item h3{
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 43px;
}
.content-manager-item p{
    color:  #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
}
.checkbox-item{
    display: flex;
    align-items: center;
    column-gap: 122px;
    padding-bottom: 30px;
}
.checkbox-item:last-child{
    padding-bottom: 0;
}
.checkbox-data{
    max-width: 390px;
}
.checkbox-item p{
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.content-manager-item button{
    padding: 12px 22px;
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.content-manager-item .add-more{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
}
.content-manager-item .add-more h3{
    margin-bottom: 0;
}
.content-manager-item{
    margin-bottom: 40px;
}
.content-manager-item:last-child{
    margin-bottom: 0;
}
.checkbox-item span{
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
.content-manager-item{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 30px;
}
.add-more-settings-model{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    background: #000000a3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-more-settings-model .model-content{
    border-radius: 30px;
    background:  #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 30px;
}
.add-more-settings-model h4{
    margin: 0;
    color: #303030;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 20px;
}
.add-more-settings-model .model-header button{
    border: none;
    background: transparent;
    padding: 0;
}
.add-more-settings-model .model-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(141 141 141 / 40%);
}
.add-more-settings-model label{
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 9px;
}
.add-more-settings-model input{
    margin-bottom: 20px;
}
.add-more-settings-model textarea:focus,.add-more-settings-model textarea:active{
    border: none;
    outline: none;
}
.add-more-settings-model input,.add-more-settings-model textarea{
    border-radius: 16px;
    background:  #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    color:  rgb(141 141 141 / 60%);
    font-size: 14px;
    font-weight: 500;
    border: none;
    line-height: normal;
    padding: 16px 20px;
    width: 100%;
}
.model-filled{
    padding-top: 38px;
}

.model-footer{
    margin-top: 40px;
}
.model-footer .save-button{
    border-radius: 16px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border: 1px solid transparent;
    padding: 14px 22px;
    margin-right: 20px;
}
.model-footer .cancel-button{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 16px;
    border: 1px solid  #001597;
    background:  #FFF;
    padding: 14px 22px;
}
.model-footer .cancel-button span{
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.check-box-option{
    column-gap: 15px;
}
.common-select-option{
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    row-gap: 40px;
    column-gap: 20px;
}
.tab-dashboard .react-tabs__tab--selected img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(52%) saturate(6444%) hue-rotate(229deg) brightness(88%) contrast(115%);
}
.tab-dashboard .react-tabs__tab img {
    margin-right: 5px;
}