.add-awards-detail h4 {
  height: 17px;
  width: 113px;
}

.add-awards-detail p {
  height: 34px;
  width: 100%;
}

.add-awards-detail span {
  width: 83px;
  height: 21px;
  display: inline-block;
}

.add-awards-cover {
  position: relative;
  height: 195px;
  background: rgb(48 48 48 / 15%);
  opacity: 1;
}

.add-awards-detail {
  padding: 16px 20px 20px 20px;
  height: 125px;
}

.awards-item{
  height: 285px;
}
.add-awards-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  height: 320px;
}
.content-manager-awards-wrapper .awards-row .awards-item:hover .image-buttons {
  display: flex;
}
.awards-item:hover .image-buttons {
  display: flex;
}

.awards-item-awards:hover .image-buttons-cancel{
  display: flex;
}

.awards-row-awards{
  height: 320px;
  width: 334px;
}
.add-value{
  font-size: 0;
}