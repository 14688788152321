.polls-item-head h6 {
  width: 86px;
  height: 25px;
  margin-bottom: 0;
}
.polls-item-head {
  margin-bottom: 10px;
}
.polls-item-head button {
  right: 0;
  left: unset;
  transform: translate(0, -50%);
}
.polls-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.polls-header p {
  height: 40px;
  width: calc(100% - 316px);
  margin: 0;
}

.polls-header div {
  height: 26px;
  width: 85px;
}

.polls-header h6 {
  height: 26px;
  width: 43px;
}
.polls-item h5 {
  height: 40px;
  width: 85px;
}
.polls-item-txt {
  width: calc(100% - 115px);
  height: 40px;
  opacity: 1;
  background: rgb(48 48 48 / 15%);
  display: flex;
  align-items: center;
}
.polls-item-txt p {
  border-radius: 15px;
  opacity: 0.2;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  height: 14px;
  width: 35%;
  margin-left: 20px;
  margin-bottom: 0;
}
.add-polls-item .polls-item {
  margin-bottom: 0;
  margin-top: 20px;
}

.content-manager-Polls-wrapper
  .polls-item.common-card-body:hover
  .image-buttons-cancel {
  display: flex;
}
.polls-item.common-card-body:hover .image-buttons-cancel {
  display: flex;
}
.font-hidden {
  color: transparent;
}
.like-tag{
  display: flex;
  gap: 10px;  
  align-items: center;
}

.poll_like_bg{
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #fff;
  top: 20px;
  left: 20px;
  z-index: 1;
}