.local-main-cover {
    border-radius: 15px;
    background: #E0E0E0;
    height: 492px;
}

.block-bottom-row {
    column-gap: 80px;
    margin-top: 20px;
    margin-bottom: 24px;
}

.block-bottom {
    width: 15%;
    height: 44px;
}

.local-home-main p {
    max-width: 550px;
    height: 22px;
}

.input-block-local {
    display: flex;
    align-items: center;
    column-gap: 30px;
    margin-top: 23px;
    margin-bottom: 30px;
}

.input-block-local .input-block {
    height: 79px;
    width: 33.33%;
}

.local-home-btn {
    width: 315px;
    height: 48px;
    margin: auto;
}

.services-local-block-row {
    display: flex;
    align-items: center;
    column-gap: 30px;
    margin-bottom: 25px;
}

.services-local-block {
    width: 99px;
    height: 20px;
}

.service-txt {
    height: 34px;
    margin-bottom: 0;
}

.menu-deals-cover {
    border-radius: 15px;
    background: #E0E0E0;
    height: 200px;
}

.menu-deals-details span {
    display: inline-block;
    width: 93px;
    height: 24px;
}

.menu-deals-details p {
    width: 100%;
    height: 82px;
    margin-bottom: 0;
    margin-top: 10px;
}

.deals-item {
    padding: 20px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
    position: relative;
}

.deals-title {
    width: 91px;
    height: 20px;
    margin-bottom: 0;
}

.deals-price {
    width: 47px;
    height: 20px;
    margin-bottom: 0;
}

.deals-details {
    height: 51px;
    margin: 0;
}

.deals-title-price {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.add-item-btn-deals {
    border-radius: 50%;
    background: #E6E6E6;
    box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
    border: none;
    width: 35px;
    height: 35px;
    position: absolute;
    top: -18px;
    right: -18px;
}

.add-deal-item h4,
.add-deal-item p,
.add-deal-item span {
    height: 22px;
}

.add-deal-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 10.295px 0px rgba(0, 28, 147, 0.13);
}

.add-rooms-detail .add-rooms-title p {
    width: 30%;
    height: 17px;
    margin: 0;
}

.add-rooms-detail .add-rooms-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.add-rooms-detail ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: auto auto;
    padding: 0;
    margin: 0;
    row-gap: 13px;
    column-gap: 38px;
}

.add-rooms-detail ul li {
    height: 16px;
}

.add-photos-item {
    aspect-ratio: 1 / 1;
}

.add-photos-cover {
    height: 100%;
}

.add-photos-cover.add-value {
    opacity: 1;
    background: rgb(48 48 48 / 15%);
}

.dropdown-full .dropdown {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
}

.location-input {
    position: relative;
}

.location-input::after {
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='24' viewBox='0 0 20 24' fill='none'%3E%3Cpath d='M10.0026 24C9.69232 23.993 9.39137 23.8906 9.13904 23.7063C6.62602 22.0583 4.41932 19.9687 2.62142 17.5347C0.956999 15.3807 0.0355943 12.7267 0 9.98411C0.000286579 8.66579 0.260742 7.36108 0.765865 6.14763C1.27099 4.93419 2.01044 3.83686 2.94012 2.92115C4.82297 1.03958 7.35657 -0.00966999 9.9923 0.000555229C12.6131 -0.0274551 15.138 1.00534 17.0138 2.87269C18.8896 4.74005 19.9634 7.28973 20 9.96318C19.9644 12.7057 19.043 15.3597 17.3786 17.5138C15.5729 19.9498 13.3658 22.047 10.8558 23.7116C10.6071 23.8947 10.3092 23.9954 10.0026 24ZM10.0026 6.93243C9.12874 6.93243 8.2907 7.28654 7.6728 7.91686C7.05491 8.54718 6.70779 9.40211 6.70779 10.2935C6.70562 10.7305 6.79011 11.1634 6.95618 11.5662C7.12224 11.969 7.36646 12.3333 7.67412 12.6373C8.05441 13.0331 8.52485 13.3266 9.04344 13.4917C9.56203 13.6568 10.1126 13.6883 10.6461 13.5834C11.1795 13.4785 11.6791 13.2404 12.1004 12.8905C12.5217 12.5406 12.8514 12.0897 13.0603 11.5781C13.2691 11.0665 13.3504 10.5101 13.2971 9.95857C13.2437 9.40707 13.0573 8.87762 12.7545 8.41753C12.4516 7.95743 12.0418 7.581 11.5616 7.32185C11.0814 7.0627 10.5458 6.92893 10.0026 6.93243Z' fill='url(%23paint0_linear_9335_123304)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9335_123304' x1='5.35957' y1='-5.11983' x2='27.7064' y2='13.052' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    bottom: 32px;
    right: 11px;
    width: 20px;
    height: 24px;
    background-size: cover;
}

.add-local-home-banner {
    height: 492px;
    border-radius: 15px;
    background: #E0E0E0;
    position: relative;
}

.add-local-home-detail ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    column-gap: 80px;
    padding: 0;
    margin: 20px 0 0 0;
}

.add-local-home-detail ul li {
    width: 149px;
    height: 44px;
}

.add-local-events-item .add-local-event-top {
    height: 238px;
    background: #E0E0E0;
    border-radius: 15px;
}

.add-local-events-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}

.add-event-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
}

.add-event-detail p {
    height: 35px;
    width: 50%;
    margin: 0;
}

.add-event-detail span {
    height: 35px;
    width: 25%;
}

.add-local-event-top .people-block {
    width: 56px;
    height: 24px;
    border-radius: 5px;
    opacity: 0.15;
    background: #303030;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 14px;
    left: 10px;
    margin: 0;
}

.add-local-event-top .date-block {
    width: 29px;
    height: 32px;
    border-radius: 5px;
    opacity: 0.15;
    background: #303030;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    margin: 0;
    right: 10px;
    bottom: 83px;
    position: absolute;
}

.add-local-event-top .detail-block {
    padding: 6px 20px;
    border-radius: 0px 0px 15px 15px;
    opacity: 0.15;
    background: #303030;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.detail-block h4 {
    height: 15px;
    width: 20%;
    background: #E0E0E0;
    border-radius: 15px;
}

.detail-block h5 {
    height: 15px;
    width: 75%;
    background: #E0E0E0;
    border-radius: 15px;
}

.detail-block h6 {
    height: 15px;
    width: 20%;
    background: #E0E0E0;
    border-radius: 15px;
    margin: 0;
}

.add-tickets {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 11px;
}

.add-tickets-item img {
    width: 100%;
}
.celender-input{
    position: relative;
}
.celender-input::after {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 32px;
    width: 22.857px;
    height: 24px;
    opacity: 0.8;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='25' viewBox='0 0 23 25' fill='none'%3E%3Cg opacity='0.8'%3E%3Cpath d='M18.0936 4.47852H4.76023C3.70826 4.47852 2.85547 5.37395 2.85547 6.47852V20.4785C2.85547 21.5831 3.70826 22.4785 4.76023 22.4785H18.0936C19.1455 22.4785 19.9983 21.5831 19.9983 20.4785V6.47852C19.9983 5.37395 19.1455 4.47852 18.0936 4.47852Z' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.2383 2.47852V6.47852' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.61719 2.47852V6.47852' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.85547 10.4785H19.9983' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
}
.add-value{
    font-size: 0;
}