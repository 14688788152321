.upload_image_text{
    font-family: Montserrat !important;
    font-size: 14.7px !important;
    font-weight: 400 !important;
    line-height: 15.48px !important;
    text-align: center !important;
    text-underline-position: from-font !important;
    text-decoration-skip-ink: none !important;
}
.container-space {
    margin-bottom: 30px;
    margin-top: 30px;
}
