.recent-shots-component video {
  object-fit: cover;
  border-radius: 15px;
  opacity: 0.6;
  height: 452px;
  width: 100%;
  border-radius: 15px;
}

.shots-grid .shots-stories-react {
  /* height: 452px; */
  overflow: hidden;
  border-radius: 15px;
  width: 322px;
}

.shots-grid {
  display: grid;
  grid-template-columns:repeat(3, minmax(0, 322px)) !important;
  gap: 26px;
  align-items: center;
}

.shots-default-content {
  height: 390px;
  border-radius: 15px;
  position: relative;
  /* width: 322px; */
}

.shots-default-content::after {
  border-radius: 15px;
  opacity: 0.6;
  background: #303030;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.shots-default-content img {
  border-radius: 31.111px;
  border: 1.6px solid #fff;
  box-shadow: 0px 0px 7.749px 0px rgba(0, 0, 0, 0.15);
  width: 80px;
  height: 80px;
}

.shots-default-content p {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
}

.shots-default-content span {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.shots-default-profile {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.add-shots-component .shots-default-content::after {
  border-radius: 15px;
  background: #e0e0e0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
}

.add-shots-component .shots-default-content button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #e6e6e6;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  border: none;
  width: 35px;
  height: 35px;
  padding: 6px;
}

.add-shots-component .shots-default-content {
  background: #e0e0e0;
}

.add-shots-component .shots-default-content button img {
  border-radius: 0;
  border: none;
  box-shadow: none;
  width: auto;
  height: auto;
}

.shots-view-count {
  width: 56px;
  height: 16px;
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.awards-row1 {
  display: grid;
  gap: 22px;
}

.add-profile-shots-cover1 {
  height: 400px;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.add-profile-shots-cover1 button {
  border-radius: 50%;
  background: #e6e6e6;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  border: none;
  width: 35px;
  height: 35px;
  padding: 6px;
}

.add-profile-shots-cover1 .view-block {
  border-radius: 30px;
  background: #bdbcbc;
  box-shadow: 0px 0px 15px 0px #00000014;
  width: 52px;
  height: 15px;
  position: absolute;
  left: 15px;
  bottom: 14px;
}

.shots-grid .video-detail-items:hover .image-buttons {
  display: flex;
}

.video-detail-items:hover .image-buttons-cancel {
  display: flex;
}

.shots-detail-items:hover .image-buttons-cancel {
  display: flex;
  z-index: 9999 !important;
}

.shots-default-content {
  height: 452px;
}

.add-profile-shots-cover1 {
  height: 452px;
}

.shots-default-content .view {
  margin-top: 410px;
  margin-left: 10px;
  color: #fff;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;

}

.view img {
  width: 15px;
  height: 15px;
  border: none;
}

.shots-control2 {
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: absolute;
  top: 11px;
  right: 50px;
  z-index: 1;
}

.Like_btn {
  align-items: center;
  column-gap: 15px;
  position: absolute;
  bottom: 15px;
  left: 25px;
  z-index: 1;
  background: none;
  border: none;
}

.Like_btn img {
  width: 25px;
  height: 25px;
}

.shot_like {
  background: none;
  border: none;
  align-items: center;
}

.shot_like_bg {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #fff;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.shot_save_bg {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 7px;
  background: #fff;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.shot_save_bg button {
  background: none;
  border: none;
  margin-bottom: 2px;
}

.shot_save_bg img {
  width: 18px;
  height: 18px;
}

.total_like_text {
  align-items: center;
  font-size: 20px;
  column-gap: 15px;
  position: absolute;
  bottom: 12px;
  left: 55px;
  z-index: 1;
  background: none;
  border: none;
}

.shot_upload_file_text{
 margin-top: 7px !important;
 margin-bottom: 15px !important;
}

.image-shot-profile {
  max-width: 35px;
  max-height: 35px;
  border-radius: 8px;
  border: 0.74px solid #FFFFFF;
}
.shots-header {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  column-gap: 10px;
  padding: 10px;
  position: absolute;
  top: 0,;
  z-index: 1;
}
.shots-heading {
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.shots-p-heading {
  color: white;
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  text-align: start;
}

.shots-creadted-at {
  color: #ffffff99;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  text-align: start;
}

@media screen and (max-width: 1400px) {
  .shots-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 352px)) !important;
    gap: 26px;
    align-items: center;
  }

  .shots-grid .shots-stories-react {
    /* height: 390px; */
    overflow: hidden;
    border-radius: 15px;
    width: 100% !important;
  }

  .shots-default-content .view {
    margin-top: 410px;
    margin-left: 10px;
    color: #fff;
  }
}

@media screen and (max-width: 1067px) {
  .shots-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    gap: 26px;
    align-items: center;
  }

  .shots-grid .shots-stories-react {
    height: 390px;
    overflow: hidden;
    border-radius: 15px;
    width: 100% !important;
  }

  .shots-default-content {
    height: 390px;
    border-radius: 15px;
    position: relative;
    width: 100%;
  }

  .add-profile-shots-cover1 {
    height: 390px;
  }

  .shots-default-content .view {
    margin-top: 350px;
    margin-left: 10px;
    color: #fff;
  }
}

@media screen and (max-width: 1000px) {
  .shots-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    gap: 26px;
    align-items: center;
  }

  .shots-grid .shots-stories-react {
    height: 390px;
    overflow: hidden;
    border-radius: 15px;
    width: 100%;
  }

  .shots-default-content {
    height: 390px;
    border-radius: 15px;
    position: relative;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .shots-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    gap: 26px;
    align-items: center;
  }

  .shots-grid .shots-stories-react {
    height: 390px;
    overflow: hidden;
    border-radius: 15px;
    width: 100% !important;
  }

  .shots-default-content {
    height: 390px;
    border-radius: 15px;
    position: relative;
    width: 100%;
  }
}