/* General button styling for both primary and secondary buttons */
.primary-btn-model,
.secondary-btn-model {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  padding: 14px 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* transition: all 0.6s ease; */
  cursor: pointer;
}

.primary-btn-model:not(.add_new_content),
.secondary-btn-model:not(.add_new_content) {
  width: 110px;
}

.primary-btn-model {
  color: #fff;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  border: 1px solid #001597;
}

.primary-btn-model:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
}

.primary-btn-model:disabled {
  opacity: 40%;
  cursor: default;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.secondary-btn-model {
  color: #7d7d7d;
  background: #fff;
  border: 1.8px solid #00298f7d;
}

.secondary-btn-model:hover {
  background-color: rgb(235, 235, 236);
}

.secondary-btn-model:hover span {
  color: #001597;
}

.secondary-btn-model:disabled,
button[disabled] {
  opacity: 60%;
  pointer-events: none;
}

.add_new_content {
  color: #fff;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  border-radius: 16px;
  padding: 14px 22px;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  transition: all 0.6s ease;
}

.add_new_content:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
}

.add-video-rows .add_new_content:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  ) !important;
}

.add_new_content .button-icon {
  width: 20px;
  height: 20px;
}

.button-row {
  text-align: right;
}

.primary-btn-model-store {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 24px;
}

.primary-btn-model-store span {
  color: #fff !important;
}

.primary-btn-model-store:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
}

.primary-btn-model-store:disabled {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  opacity: 40% !important;
  cursor: not-allowed;
}

.secondary-btn-model-store {
  border-radius: 16px;
  border: 1px solid #001597;
  background: #fff;
  padding: 14px 22px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  width: auto;
}
.secondary-btn-model-store span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  font-style: italic;
}

.secondary-btn-model-store:hover {
  background-color: rgb(235, 235, 236);
}

.secondary-btn-model-store:hover span {
  background-color: #001597;
}

.secondary-btn-model-store:disabled {
  opacity: 60%;
  pointer-events: none;
}

.secondary-btns {
  border: 1.8px solid #001597;
  background: #fff;
  padding: 14px 22px;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 19.5px;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  width: auto;
  border-radius: 50px;
}
.secondary-btns span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-style: italic;
  font-weight: 900;
  line-height: 19.5px;
}

.secondary-btns:hover {
  background-color: rgb(235, 235, 236);
}

.secondary-btns:hover span {
  background-color: #001597;
}

.secondary-btns:disabled {
  opacity: 60%;
  pointer-events: none;
}
/* Local button  */

.local-primary-button {
  border-radius: 16px;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  height: 35px;
  padding: 0px 10px;
  font-weight: 600;
  color: rgba(158, 158, 158, 1);
  transition: all 0.2s ease-in;
}

.local-primary-button span {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: rgba(158, 158, 158, 1);
  transition: all 0.3s ease;
}

.local-primary-button:hover {
  border: 1px solid #001597;
}

.local-primary-button:hover span {
  color: #001597;
}

.local-secondary-button {
  border-radius: 16px;
  border: 2px solid #001597;
  background: transparent;
  padding: 0px 10px !important;
  height: 27px;
}

.local-secondary-button span {
  font-size: 14px;
  font-weight: 900;
  /* font-style: italic; */
}

/* Local Button Row Styles */
.local_type_cancel {
  height: 48px;
  padding: 0 22px;
  border-radius: 100px;
  border: 1px solid #001597;
  background: #fff;
  transition: all 0.3s ease;
}

.local_type_cancel:hover {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.local_type_cancel:hover span {
  -webkit-text-fill-color: #fff;
}

.local_type_cancel span {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  /* font-style: italic; */
  transition: all 0.3s ease;
}

.local_type_next {
  height: 48px;
  padding: 0 22px;
  border-radius: 100px;
  border: 1px solid #001597;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  transition: all 0.3s ease;
}

.local_type_next:hover {
  background: #fff;
}

.local_type_next:hover span {
  -webkit-text-fill-color: transparent;
}

.local_type_next span {
  -webkit-text-fill-color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  /* font-style: italic; */
  transition: all 0.3s ease;
}

.local_new_design_btn {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px !important;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  ) !important;
  padding: 16px 48px !important;
}

.local_new_design_btn span {
  color: #fff !important;
  font-family: Montserrat;
  font-size: 16px !important;
  font-style: italic !important;
  font-weight: 900 !important;
  line-height: 19.2px;
}

.local_new_design_btn:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  ) !important;
}

.local_new_design_btn:disabled {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  ) !important;
  opacity: 40% !important;
  cursor: not-allowed;
}

.local_new_design_secondary_btn {
  border-radius: 30px !important;
  border: 1px solid #001597;
  background: #fff;
  padding: 16px 48px !important;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  width: auto;
}
.local_new_design_secondary_btn span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  font-style: italic;
}

.local_new_design_secondary_btn:hover {
  background-color: rgb(235, 235, 236);
}

.local_new_design_secondary_btn:hover span {
  background-color: #001597;
}

.local_new_design_secondary_btn:disabled {
  opacity: 60%;
  pointer-events: none;
}