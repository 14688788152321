.event_speaker {
  width: 100%;
}

.event_speaker_drop .local_event_speaker_dropdown {
  width: 100%;
}

.item-details-input .speaker_info {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
}

.event_speaker {
  padding: 10px;
  gap: 12px;
  border-radius: 15px;
  border: 1px solid var(--Border-Light, #e4e4e4);
}

.speaker_img img {
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 10px;
}

.event_speaker_name h1 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: #212121;
  margin-bottom: 5px;
  margin-top: 10px;
}
.event_speaker_name p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #8d8d8d;
  margin-bottom: 10px;
}

.event_speaker_btn {
  border-radius: 16px;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  height: 35px;
  padding: 0px 10px !important;
  font-weight: 600;
  color: rgba(158, 158, 158, 1);
  transition: all 0.2s ease-in;
  border-radius: 22px;
  width: 100% !important;
}
.event_speaker_btn span {
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.discount_list .event_td {
  padding: 5px 5px;
}

.plus_speaker {
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #c7d5e0;
  margin-left: -8px;
  object-fit: cover;
}

.plus_speaker span {
  font-family: Montserrat;
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 700 !important;
  line-height: 12.19px;
  margin-left: 3px;
}
.event_speaker_btn {
  border-radius: 16px;
  border: 1px solid rgba(228, 228, 228, 1);
  background: #fff;
  height: 35px;
  padding: 0px 10px !important;
  font-weight: 600;
  color: rgba(158, 158, 158, 1);
  transition: all 0.2s ease-in;
  border-radius: 22px;
  width: 100%;
  /* font-style: italic; */
}
.event_speaker_btn span {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  text-align: center;
  color: rgba(158, 158, 158, 1);
}

.event_speaker .event_performer_box{
  height: 158px !important;
}

.event_speaker:hover .local_contact_buttons_event{
  display: flex;
}

.local_contact_buttons_event  {
  display: none;
  column-gap: 15px;
  position: absolute;
  top: 2px;
  right: 5px;
}
.local_contact_buttons_event label {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='20' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}


.statistic-chart-container .ant-picker-input{
  width: 370px !important;
}

.statistic-chart-container .ant-picker-input input::placeholder{
  color: #b5b5b5 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.statistic-chart-container .local_event_description .common-input::placeholder{
  color: #b5b5b5 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.item-details-input .local_event_description .common-input::placeholder{
  color: #ababab !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.particular_event_cancel{
  padding: 12px 18px !important;
}

.performer_data .position-relative:hover .local_contact_buttons {
  display: flex;
}
.width_add_performer{
  width: 220px !important;
}

.event_speaker_drop .dropdown__items {
  top: 51px !important;
}
.performer_dropdown .dropdown__items {
  top: 50px !important;
}

.time_timepicker_local .event_time .ant-picker-input input::placeholder{
  color: #ababab !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.performer_data .btn-delete-edit .local_contact_buttons{
 right: 40px !important;
}

.performer_text{
  font-weight: 600 !important;
}









.time-picker-wrapper {
  display: flex;
  flex-direction: column;
}

.custom-timepicker {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f8f8f8;
  border-radius: 20px;
  padding: 10px 15px;
}

.custom-timepicker-input {
  flex: 1;
  border: none !important;
  background: transparent !important;
  text-align: center;
}

.custom-timepicker-input input {
  text-align: center !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


.ant-dropdown-trigger.custom-timepicker {
  color: #a5a1a1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  line-height: 17.07px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.dropdown.local-dropdown .dropdown__text{
  color: #a5a1a1 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: Montserrat !important;
  line-height: 17.07px !important;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.custom-timepicker.text-color-time{
  color: #000000 !important;
  font-weight: 500 !important;
}

.object_position{
  object-fit: unset !important;
}