.top-40 {
    top: 40px;
}

.date-range {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.date-range span {
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.date-range input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='22' viewBox='0 0 23 22' fill='none'%3E%3Cpath d='M17.8887 3.16797H5.88867C3.67953 3.16797 1.88867 4.84905 1.88867 6.92276V16.3097C1.88867 18.3834 3.67953 20.0645 5.88867 20.0645H17.8887C20.0978 20.0645 21.8887 18.3834 21.8887 16.3097V6.92276C21.8887 4.84905 20.0978 3.16797 17.8887 3.16797Z' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.88867 1.29053V5.04531' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.8887 1.29053V5.04531' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.88867 8.80005H21.8887' stroke='%238D8D8D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    border-radius: 16px;
    background-position-y: center;
    background-position-x: 16px;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    border: none;
    outline: none;
    padding: 11px 21px 11px 56px;
    width: 150px;
    color: #8D8D8D;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
}

.order-table th {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.order-table td {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding: 20px 0;
}

.order-table table {
    border-spacing: 0 20px;
    border-collapse: separate;
}

.order-table .client-img img {
    height: 47px;
    width: 47px;
    object-fit: cover;
    border-radius: 20px;
    border: 2px solid #001597;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.order-table .client-detail span {
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
}

.data-row {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}

.data-row td:first-child {
    border-radius: 15px 0 0 15px;
    padding-left: 20px;
}

.order-table th:first-child {
    padding-left: 20px;
}

.data-row td:last-child {
    border-radius: 0px 15px 15px 0px;
}

.client-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
    gap: 36px;
}

.client-detail-card {
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.client-detail-card .profile-img {
    width: 165px;
    height: 165px;
    border-radius: 50px;
    border: 2.705px solid #001597;
    box-shadow: 0px 0px 5.41px 0px rgba(0, 0, 0, 0.15);
    object-fit: cover;
    object-position: center;
}

.client-detail-card h6 {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    column-gap: 7px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;
}

.client-detail-card .designation {
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
}

.btn-gredient {
    border-radius: 12px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.15);
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    width: 102px;
    padding: 6px 0;
    border: none;
}

.btn-light-blue {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.15);
    width: 102px;
    padding: 6px 0;
    border: none;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
}

.btn-light-blue span {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.product-rating-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 36px;
}

.product-detail-card {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    align-items: center;
    gap: 40px;
    border-radius: 15px;
    background: #FFF;
    padding: 20px 30px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);

}

.product-detail-card h2 {
    color: #303030;
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
}

.product-detail-card span {
    color: #1A1A1A;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.product-detail-card .product-img img {
    height: 184px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.order-table #order {
    color: #001C93;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    border-radius: 15px;
    background: #D8DBFF;
    padding: 8px 10px;
}

.page-item {
    display: none;
    width: 35px;
    height: 27px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
box-shadow: 0px 0px 15px rgba(82, 106, 103, 0.19);
align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.pagination{
    column-gap: 10px;
    justify-content: end;
}
.page-link-visible.disabled{
background: #fff;
}
.page-link-visible.disabled svg path{
    stroke: #001597;
}
.page-link{
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    border: none !important;
    background: transparent !important;
}
.page-link-visible {
    display: flex;
}
.filter-products{
    width: 294px;
}
.price-range .multi-range-slider {
    display: flex;
    position: relative;
    border: none;
    border-radius: 0px;
    padding: 0;
    box-shadow: none;
}
.price-range .multi-range-slider .bar-right,.price-range .multi-range-slider .bar-left{
    background-color: #8D8D8D;
    border-radius: 0px 0px 0px 0px;
    box-shadow: none;
    opacity: 0.2;
}
.price-range .multi-range-slider .bar-inner {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    position: relative;
    border: none;
    justify-content: space-between;
    box-shadow: none;
}
.price-range .multi-range-slider .thumb::before {
    content: '';
    background-color: inherit;
    position: absolute;
    width: 17px;
    height: 17px;
    border: none;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    z-index: 1;
    margin: -5px -12px;
    cursor: pointer;
}
.price-range .caption{
    display: none !important;
}
.product-filter-main .feed-container{
    width: calc(100% - 324px);
}
.platform-grid{
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
    gap: 35px;
}
.platform-item{
    width: 80px;
    height: 80px;
    border-radius: 15px;
    overflow: hidden;
}
.platform-select-model .add-item-content{
    width: 504px;
    height: 507px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.platform-item input[type="radio"]{
    display: none;
}
.platform-item input[type="radio"]:checked ~ label {
  opacity: 0.3;
}
.add-product-content .add-item-content .input-img-label label{
    width: 320px;
    height: 298px;
    /* box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19); */
    /* border: 1px dashed #001597; */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23071DA5FF' stroke-width='4' stroke-dasharray='3%2c 24' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 15px;
}

.add-product-content .add-item-content .input-image-show .first-image{
    width: 175px !important;
    height: 158.28px !important;
}
.add-product-content .add-item-content .input-image-show .image{
    width: 143px;
    height: 130px;
}
.gredient-txt{
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.add-product-content .add-item-model p{
    font-size: 24px;
}
.product-img-item img{
    border-radius: 15px;
}
.add-product-content .product-img-item{
width: 118px;
height: 107px;
}
.add-product-content .product-img-selected{
    width: calc(100% - 138px);
    height: 458px;
}
.rating-star img{
    width: 18px;
    height: 18px;
}
.discription-box p,.product--detail p{
    text-align: left;
}
.product-preview .react-tabs__tab--selected{
    border-bottom: 0;
}
.product-preview .react-tabs__tab-list{
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 30px;
}
