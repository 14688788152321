.local_menu_dropdown {
  width: 100%;
}

.edit_description p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #303030;
  margin-top: 20px;
  margin-bottom: 20px;
}
.menu-img img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}
.menu-img {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.menu-img h6 {
  color: rgba(21, 21, 21, 1);
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* antd  */

.image_name {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* .image_name span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  background: linear-gradient(
      134.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.ant-image {
  width: 50px !important;
}

.ant-image-img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px !important;
}

.ant-image-mask {
  width: 50px !important;
  height: 50px !important;
  border-radius: 5px !important;
}

.ant-table-cell {
  padding: 10px !important;
}

.ant-table-column-title {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #8d8d8d;
}

.ant-table-cell {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19.5px !important;
  text-align: left !important;
  color: #8d8d8d !important;
}

.ant-table-thead .ant-table-column-has-sorters {
  background-color: #fff !important;
  border-bottom: none !important;
}
.ant-table-thead .ant-table-cell {
  background-color: #fff !important;
  border-bottom: none !important;
}

.room_image .menu_image{
    width: 150px !important;
    height: 150px !important;
    border-radius: 10% !important;
}

.menu_image_show{
  display: flex;
  justify-content: space-evenly;
}

.room_image.image-count-1 .menu_image.large {
  width: 750px !important;
  border-radius: 10px !important;
}

.room_image.image-count-2 .menu_image.medium {
  width: 300px !important;
  border-radius: 10px !important;
}

.room_image.image-count-3 img, 
.room_image.image-count-4 img {
  width: auto;
  height: auto;
}
