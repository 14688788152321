.dropdown {
  position: relative;
  margin: 28px 0 20px auto;
  width: 256px;
  border-radius: 10px;
  background: #fff;
  /* box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19); */
  /* border: 1.8px solid #00298f5e; */
  border: 1.8px solid #00298fc4;
  padding: 12px 15px;
}
.dropdown__text {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  -webkit-text-fill-color: #01104d;
}
.dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid #01104D;
  border-width: 0 2px 2px 0;
  float: right;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -6px;
}
.dropdown.active .dropdown__text:after {
  margin-top: 8px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.dropdown__items {
  position: absolute;
  top: 43px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  left: 0;
  transition: max-height 0.6s ease;
}
.dropdown.active .dropdown__items {
  visibility: visible;
  background: #fff;
  width: 100%;
  border-radius: 15px;
  height: auto;
  max-height: 308px;
  opacity: 1;
  transition:
    max-height 0.2s,
    opacity 0.1s,
    visibility 0.1s ease;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07); */
  -webkit-text-fill-color: #01104d;
  overflow: auto;
  font-weight: 500;
  left: 0px;
}
.dropdown.active .dropdown__items::-webkit-scrollbar {
  display: none;
}
.dropdown__item {
  cursor: pointer;
  padding: 10px 15px;
}
.dropdown__item_active {
  background: rgba(0, 0, 0, 0.05);
  background-clip: unset;
  -webkit-background-clip: unset;
}
.dropdown__item:hover {
  /* background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%); */
  background: rgba(0, 0, 0, 0.05);
  background-clip: unset;
  -webkit-background-clip: unset;
  /* -webkit-text-fill-color: #01104D; */
}
.dropdown.active{
  border-bottom: 1px solid transparent !important;
  border-radius: 16px 16px 0 0 !important;
}
.dropdown.active .dropdown__items{
  /* outline: 1.8px solid #00298f5e; */
  outline: 1.8px solid #00298fc4;
  border-radius: 0 0 16px 16px;
  border-top: 0px solid transparent;
}
.dropdown.active:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  background: #fff;
  width: 100%;
  height: 2px;
  z-index: 99;
}

.dropdown__items .selected {
  width: 100%;
  background: rgba(0, 0, 0, 0.05) !important;
  -webkit-text-fill-color: #01104D !important;
  border-radius: 10px;
}

.platformTag_dropdown .dropdown{
  width: 206px;
  margin: 0 !important;
}

.select_platform{
  border: 2px solid #01104D;
}