.product-footer p {
  width: 60%;
  height: 35px;
  margin-bottom: 0;
}
.product-footer span {
  display: inline-block;
  width: 20%;
  height: 35px;
}
.product-footer {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.add-product-video-cover {
  height: 230px;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-item-content-store {
  max-width: 1096px;
  max-height: 802px;
  padding: 0px 25px 0px 25px !important;
  width: calc(100% - 20px) !important;
  height: calc(100% - 20px) !important;
}
.add-item-content-store.store_right_padding{
  max-width: 1096px;
  max-height: 802px;
  padding-right: 10px !important;
  width: calc(100% - 20px) !important;
  height: calc(100% - 20px) !important;
}
.awards-row.store-container {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
  gap: 22px;
}
/* .awards-row.store-container .product-item-cover {
  aspect-ratio: 16 / 9;
} */
.awards-row.store-container .product-item-cover {
  height: 230px;
}
.awards-row.store-container .add-product-video-cover {
  height: 230px;
  /* aspect-ratio: 16 / 9; */
}
.awards-row.store-container .product-grid-item:hover .image-buttons {
  display: flex;
}
.awards-row.store-container .product-grid-item:hover {
  cursor: pointer;
}
.product-grid-item:hover .image-buttons {
  display: flex;
}
.product-grid-item .actual-price {
  -webkit-text-fill-color: #8d8d8d;
  text-decoration: line-through;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 999;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Modal Content */
.modal h2 {
  margin-top: 0;
}

/* .add-item-model .secondary-btn-modelConfirm {
  width: 30%;
  height: 30%;
  border-radius: 16px;
  border: 1px solid #001597;
  background: #FFF;
  padding: 14px 22px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
} */

.dropdown:hover {
  cursor: pointer;
}

.add-item-model .secondary-btn-modelConfirm:hover {
  background-color: rgb(235, 235, 236);
}

.add-item-model .secondary-btn-model:hover span {
  background-color: #001597;
}

.input-position input::placeholder,
.input-position input {
  color: #a0a0a0;
}

.custom-img-crop-wrapper {
  width: 320px;
  height: 298px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23071DA5FF' stroke-width='4' stroke-dasharray='3%2c 24' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  position: relative;
}
.upload_crop {
  width: 320px !important;
  height: 298px !important;
}

/* .ant-upload-list-picture-card{
  width: 320px !important;
  height: 298px !important;
} */

.ant-upload-list-picture-card .upload_image_crop {
  width: 320px !important;
  height: 298px !important;
  margin-top: 150px;
}

.ant-modal-footer .ant-btn-default {
  border-radius: 16px;
  border: 1px solid #001597;
  background: #fff;
  padding: 14px 22px;
  font-size: 16px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  width: 110px;
}

.ant-modal-footer .ant-btn-default:hover {
  background-color: rgb(235, 235, 236);
}

.ant-modal-footer .ant-btn-default:hover span {
  background-color: #01104d !important;
  border: none !important;
}
/* .ant-modal-footer .ant-btn-default:hover span{
  color: #fff !important;
  border:none !important;
} */
.ant-modal-content .ant-btn {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: normal !important;
  border-radius: 30px !important;
  border: 1px solid #001597 !important;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 23px 24px;
}

.ant-modal-content .ant-btn.ant-btn-default:hover span {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  ) !important;
  cursor: pointer !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.ant-modal-footer .ant-btn-default:hover span {
  background-color: rgb(235, 235, 236) !important;
}

.ant-modal-footer .ant-btn-default:hover span {
  background-color: rgb(235, 235, 236) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.ant-modal-footer .ant-btn-primary {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  border: 1px solid #070915;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 23px 24px;
}

.ant-modal-footer .ant-btn-primary:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  ) !important;
  cursor: pointer !important;
}

.image {
  width: 160px;
  height: 160px;
  position: relative;
}

.plus-img .ant-upload {
  width: 50px !important;
  margin-bottom: 0px !important;
}

.product-label {
  display: flex;
  align-items: center;
}
.edit-new-label {
  margin: 0px;
  width: 100%;
  text-align: center;
}

.no-scroll {
  overflow: hidden;
}

.common-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 14px 24px !important;
  border: none;
  cursor: pointer;
}

.add_new_store {
  width: 100% !important;
}

.upload_crop .upload_label p {
  color: #08135b !important;
  font-weight: 600 !important;
  font-size: 16x;
}

.selected-dropdown-text {
  font-weight: 500 !important;
  -webkit-text-fill-color: #303030 !important;
  background: transparent !important;
}

.store_dropdown {
  min-width: 148px;
}

.store_add_dropdown .dropdown__text {
  font-size: 15px !important;
  -webkit-text-fill-color: #000000 !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  background: none;
}
.store_add_dropdown .dropdown__item {
 padding: 10px 28px !important;
}

.store_header_sticky {
  position: sticky;
  top: 0px;
  background: #fff;
}

.store_header_sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff; 
  /* padding: 10px 0;  */
  padding: 30px 15px 30px 0px;
}

.store_scroll_bar {
  max-height: 460px; 
  overflow-y: auto; 
  padding: 5px 15px 10px 10px;
}

.store_sticky_btn {
  position: sticky;
  bottom: 0;
  background-color: #fff; 
  padding: 30px 15px 30px 0px; 
  z-index: 0;
}
.store_scroll_bar_add {
  max-height: 550px; 
  overflow-y: auto; 
  padding: 5px 15px 5px 8px;
}