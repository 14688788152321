.link_dropdown .dropdown__item {
  padding: 10px 18px !important;
}

.add-item-content .drp  .dropdown__items {
  margin-top: 10px;
}

.link_dropdown .dropdown__item_active{
  background: none !important;
}
.link_dropdown .dropdown__item:hover{
  background: rgba(0, 0, 0, 0.05) !important;
}
.link_dropdown .dropdown.active.link_dropdown:before{
  background: none !important;
}
.drp .dropdown {
  background: #FAFAFA;
  border: 1.8px solid #00298fc4 !important;
  border-radius: 10px;
  width: auto;
  padding-right: 30px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
}

.description-input {
  padding: 15px 15px !important;
}