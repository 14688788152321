.profile-head-builder {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 20px;
}

.profile-builder-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-builder-header button {
  border: none;
  background: transparent;
}

.profile-heading span {
  color: #303030;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
}

.profile-heading {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-left: 10px;
}

.profile-personal-detail .profile-build-image {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  border: 2px solid #001597;
}

.profile-personal-detail .profile-build-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.about-personal-profile h2 {
  color: #212121;
  font-size: 22px;
  font-weight: 700;
  line-height: 12px;
}

.about-personal-profile h3 {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 0;
}

.about-personal-profile p {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  max-width: 258px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.about-personal-profile span {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.about-account-insignt {
  list-style-type: none;
  margin: 0;
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
}
.about-account-insignt .Follower_text{
 cursor: pointer;
}

.about-account-insignt li {
  width: 33.33%;
}

.about-account-insignt li h4 {
  color: #303030;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.about-account-insignt li p {
  color: #8d8d8d;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.follow-connenct button {
  padding: 12px;
  border-radius: 47.5px;
  font-size: 14px;
  width: 100%;
}

.success-btn {
  border-radius: 47.5px;
  border: 1px solid transparent;
  background: linear-gradient(150.01deg, #03CDFD 6.08%, #03B9FD 23.98%, #0188FD 70.39%, #0175FD 93.43%);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  transition: all 0.6s ease;
}
.success-btn-no-hover {
  border-radius: 47.5px;
  border: 1px solid transparent;
  background: linear-gradient(150.01deg, #03CDFD 6.08%, #03B9FD 23.98%, #0188FD 70.39%, #0175FD 93.43%);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  transition: all 0.6s ease;
}
.success-btn:hover {
  border: 1px solid #03CDFD;
  background: #fff;
  color: #03CDFD;
}

.follower-img-group img {
  max-width: 25px;
  max-height: 25px;
  border-radius: 50%;
  border: 1px solid #C7D5E0;
  margin-left: -8px;
  object-fit: cover;
}
.follower-img-group .skeleton-img-group {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #C7D5E0;
  margin-left: -8px;
  object-fit: cover;
}

.follower-img-group img:first-child {
  margin-left: 0;
}

.performer-img-group img {
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  border: 1px solid #C7D5E0;
  margin-left: -8px;
  object-fit: cover;
}
.performer-img-group .skeleton-img-group {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #C7D5E0;
  margin-left: -8px;
  object-fit: cover;
}

.performer-img-group img:first-child {
  margin-left: 0;
}

.profile-personal-detail {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.profile-builder-main {
  display: flex;
  padding-left: 14.5px;
  margin-top: 10px;
}

.profile-personal-detail {
  width: 43%;
}

.profile-account-details {
  width: 60%;
}

.follow-connenct {
  display: flex;
  align-items: center;
  column-gap: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.buttons-group {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: calc(100% - 155px);
  justify-content: space-between;
}

.follwer-shows {
  display: flex;
  align-items: center;
  column-gap: 14px;
  margin-bottom: 10px;
}

.follwer-shows p {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.profile-redirect a {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.view-button {
  display: flex;
  column-gap: 20px;
}

.view-button button {
  border: 1px solid #8d8d8d;
  width: 50px;
  height: 50px;
  background: transparent;
  border-radius: 12px;
}

.view-button button.active {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  border: none;
}

.view-button button.active img {
  filter: brightness(0) invert(1);
}
.general-settings {
  position: absolute;
  right: 0;
}
.general-settings a {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.profile-builder-main-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-profile .profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  border: 1.5px solid #001597;
  margin: auto;
  margin-bottom: 20px;
}
.edit-profile .profile-image input {
  display: none;
}
.edit-profile .profile-image label {
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1.5px solid #001597;
}
.item-details-input .delete_icon {
  width: 35px;
  height: 35px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116856)'%3E%3Cpath d='M6 19.8871C6 20.9871 6.9 21.8871 8 21.8871H16C17.1 21.8871 18 20.9871 18 19.8871V7.88708H6V19.8871ZM19 4.88708H15.5L14.5 3.88708H9.5L8.5 4.88708H5V6.88708H19V4.88708Z' fill='url(%23paint0_linear_9174_116856)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116856' x1='8.7517' y1='0.04721' x2='25.2404' y2='12.5615' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116856'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  height: 48.8px;
}
.delete_icon:hover {
  /* cursor: pointer; */
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 16px 0 rgba(0, 0, 0, 0.19);
}

.camera_icon :hover {
  cursor: pointer;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.primary-btn-model:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
  cursor: pointer;
}

.primary-btn-model:disabled {
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  opacity: 40% !important;
  cursor: default;
}
.add-item-model .edit-creator{
  background: #fff;
  border-radius: 15px;
  padding: 40px;
  width: 800px;
  height: 600px;
  overflow-y: scroll;
}

@media only screen and (max-width: 1900px) {
  .buttons-group .profile-skeleton-btn{
    width: 239px;
  }
  .gredient-txt .follow-text{
    width: 300px;
  }
  .profile-personal-detail .profile_ske_icon{
    border-radius: 50%;
    width: 156px;
    height: 156px;
  }
}
@media only screen and (max-width: 1240px) {
  .buttons-group .profile-skeleton-btn{
    width: 175px;
  }
  .gredient-txt .follow-text{
    width: 215px;
  }
  .profile-personal-detail .profile_ske_icon{
    border-radius: 50%;
    width: 156px;
    height: 156px;
  }
}
@media only screen and (max-width: 1067px) {
  .buttons-group .profile-skeleton-btn{
    width: 125px;
  }
  .gredient-txt .follow-text{
    width: 150px;
  }
  .profile-personal-detail .profile_ske_icon{
    border-radius: 50%;
    width: 125px;
    height: 125px;
  }
}
@media only screen and (max-width: 767px) {
  .buttons-group .profile-skeleton-btn{
    width: 75px;
  }
  .gredient-txt .follow-text{
    width: 100px  ;
  }
  .profile-personal-detail .profile_ske_icon{
    border-radius: 50%;
    width: 75px;
    height: 75px;
  }
}
@media only screen and (max-width: 576px) {
  .buttons-group .profile-skeleton-btn{
    width: 50px;
  }
  .profile-personal-detail .profile_ske_icon{
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

}
@media only screen and (max-width: 1400px) {
  .profile-personal-detail .profile-build-image {
    height: 100px;
    width: 100px;
}
.about-personal-profile{
  width: calc(100% - 120px);
}
.about-personal-profile h2 {
  font-size: 14px;
  line-height: normal;
}
.about-personal-profile h3{
  font-size: 12px;
}
.about-personal-profile span {
  font-size: 12px;
  line-height: 14px;
}
}

/* .cropper-user .ant-upload {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  position: relative !important;
  border: 1.5px solid #001597 !important;
}

.cropper-user .ant-upload-list-item-container{
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  position: relative !important;
}

.cropper-user .ant-upload-select{
    border: none !important;
}

.cropper-user .ant-upload-list-item-thumbnail{
  object-fit: cover;
  border-radius: 50% !important;
}

.cropper-user .ant-upload-list-item:hover::before{
  border-radius: 50% !important;
}
.cropper-user .ant-upload-list-item::before{
  width: calc(100% - 4px) !important;
  height: calc(100% - 4px) !important;
  border-radius: 50% !important;
}

.cropper-user .ant-upload .camera_icon{
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1.5px solid #001597;
}

.cropper-user .ant-upload-list-item{
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  position: relative !important;
  border: 1.5px solid #001597 !important;
  padding: 2px !important;
}
.cropper-user .ant-upload-list{
  justify-content: center;
}
.cropper-user .ant-upload-list-item .camera_icon{
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1.5px solid #001597;
}

.cropper-user .ant-upload-list-item-done .camera_icon{
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1.5px solid #001597;
}

.cropper-user .image_label_imagecrop{
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1.5px solid #001597;
}

.cropper-user .ant-upload .image_label_imagecrop{
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1.5px solid #001597;
} */

.profile-image-store {
  width: 100px;
  height: 100px;
}
.profile-image-store .Profile_img{
  width: 98px;
  height: 97px;
  margin-right: 2px;
}

.profile_create_scroll{
  max-height: 410px !important;
}

.profile_post_count{
  max-width: 50px;
  text-align: center;
}
.profile_follower_count{
  max-width: 90px;
  text-align: center;
}
.profile_like_count{
  max-width: 50px;
  min-width: 50px;
  text-align: center;
}

.profile_open_btn{
  justify-content: space-between;
}

.success-btn.disabled {
  pointer-events: none; 
  opacity: 0.6; 
}

.success-btn.disabled:hover {
  border: 1px solid transparent;
  background: linear-gradient(
    150.01deg,
    #03CDFD 6.08%,
    #03B9FD 23.98%,
    #0188FD 70.39%,
    #0175FD 93.43%
  );
  color: #fff;
}