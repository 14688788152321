.drop-search input {
    width: 350px;
    padding: 14px 14px 14px 53px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    border: none;
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}
.drop-search{
    position: relative;
}
.drop-search input::placeholder {
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}
.drop-search::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translate(0 , -50%);
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M20.4099 19.2138L14.5458 13.3304C15.864 11.7009 16.5068 9.62783 16.3416 7.53844C16.1764 5.44906 15.2158 3.50271 13.6579 2.10066C12.1 0.698606 10.0635 -0.0522897 7.9683 0.00283362C5.87311 0.0579569 3.87895 0.914887 2.39692 2.39692C0.914887 3.87895 0.057957 5.87311 0.00283362 7.96829C-0.0522897 10.0635 0.698606 12.0999 2.10066 13.6578C3.50271 15.2157 5.44906 16.1763 7.53845 16.3416C9.62783 16.5068 11.7009 15.864 13.3304 14.5458L19.1945 20.4099C19.2742 20.49 19.3689 20.5535 19.4732 20.5969C19.5775 20.6403 19.6892 20.6626 19.8022 20.6626C19.9151 20.6626 20.027 20.6403 20.1313 20.5969C20.2356 20.5535 20.3303 20.49 20.4099 20.4099C20.5647 20.2493 20.6511 20.0349 20.6511 19.8119C20.6511 19.5888 20.5647 19.3745 20.4099 19.2138ZM8.20722 14.6669C6.93208 14.6679 5.68542 14.2905 4.62478 13.5827C3.56415 12.8749 2.7372 11.8683 2.24879 10.6904C1.76037 9.51255 1.63235 8.21629 1.88088 6.96561C2.12942 5.71493 2.74343 4.56608 3.64509 3.66442C4.54675 2.76276 5.6956 2.1488 6.94628 1.90027C8.19696 1.65174 9.49316 1.77976 10.6711 2.26817C11.8489 2.75659 12.8555 3.58348 13.5633 4.64411C14.2711 5.70475 14.6485 6.95147 14.6475 8.22661C14.645 9.93389 13.9656 11.5705 12.7584 12.7777C11.5511 13.985 9.9145 14.6643 8.20722 14.6669Z' fill='%238D8D8D'/%3E%3C/svg%3E");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.drop-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.drop-sub-row{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.drop-sub-row .dropdown{
    width: auto;
    margin: 0;
}
.community-profile-item{
    height: 378px;
    position: relative;
    border-radius: 15px;
}
.community-profile-item::after{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(0deg, rgba(4, 4, 4, 0.54) 23.58%, rgba(189, 189, 189, 0.00) 117.66%);
}
.community-profile-detail h4{
    color:  #FFF;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.community-profile-detail p{
    color:  #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.community-profile-detail{
    position: absolute;
    z-index: 1;
    width: calc(100% - 60px);
    left: 30px;
    bottom: 30px;
}