.recent-video-component .swiper-slide {
  width: 259px;
}

.recent-video-component .swiper-slide {
  width: 259px;
}

.recent-video-component .video-cover {
  height: 174px;
  border-radius: 11px;
}

.recent-video-detail p {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.recent-video-detail h4 {
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.recent-video-detail {
  padding: 15px;
}

.recent-video-detail h4 span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.recent-video-component .video-cover::after {
  border-radius: 11px;
  background: linear-gradient(
    0deg,
    rgba(67, 67, 67, 0.45) 0%,
    rgba(67, 67, 67, 0.45) 100%
  );
}

.recent-video-slider {
  border-radius: 11.881px;
  background: #fff;
  box-shadow: 0px 0px 25.02px 0px rgba(82, 106, 103, 0.19);
  opacity: 0.4;
}

.recent-video-slider .play {
  top: 50%;
  left: 50%;
  bottom: unset;
  transform: translate(-50%, -50%);
}

.recent-video-slider .time {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border-radius: 15px 0px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 4px 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.mySwiper-story .swiper-button-prev {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='rotate(180 20 20)' fill='url(%23paint0_linear_3696_120058)' stroke='url(%23paint1_linear_3696_120058)' stroke-width='0.625'/%3E%3Cpath d='M23.5979 10.7347L14.3652 20.4928L23.5979 30.2566' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.mySwiper-story .swiper-button-next {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' fill='url(%23paint0_linear_3696_120058)' stroke='url(%23paint1_linear_3696_120058)' stroke-width='0.625'/%3E%3Cpath d='M16.4021 29.2653L25.6348 19.5072L16.4021 9.74341' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_3696_120058' x1='10.7191' y1='-8.53306' x2='48.7518' y2='28.5794' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.mySwiper-story .swiper-button-next.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='matrix(1 0 0 -1 0 40)' fill='%238D8D8D' stroke='%238D8D8D' stroke-width='0.625'/%3E%3Cpath d='M16.4021 10.7347L25.6348 20.4928L16.4021 30.2566' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.mySwiper-story .swiper-button-prev.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.6875' transform='matrix(-1 0 0 1 40 0)' fill='%238D8D8D' stroke='%238D8D8D' stroke-width='0.625'/%3E%3Cpath d='M23.5979 29.2653L14.3652 19.5072L23.5979 9.74341' stroke='white' stroke-width='2.30769' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.recent-video-component h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 23px;
}

.swiper-pagination-bullet {
  width: 29.032px;
  height: 5px;
  border-radius: 3px 0px;
  opacity: 0.2;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  display: inline-block;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  opacity: 1;
}

.swiper-pagination {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.recent-video-component .swiper-slide-active .recent-video-slider {
  opacity: 1;
}

.recent-video-component .swiper-slide-active .video-cover {
  height: 247px;
}

.recent-video-component .swiper-slide-active.swiper-slide {
  width: 369px;
}

.recent-video-component .swiper-wrapper {
  align-items: center !important;
}

/* .add-profile-video .awards-item {
  padding: 20px;
} */

.add-profile-video-cover {
  /* height: 158px; */
  height: 200px;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}
.add-profile-video-detail p {
  height: 34px;
  width: 100%;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  margin-top: 16px;
}
.add-profile-video-detail span {
  height: 16px;
  width: 96px;
  border-radius: 15px;
  background: #e0e0e0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  display: inline-block;
}
.add-profile-video-cover button {
  border-radius: 50%;
  background: #e6e6e6;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  border: none;
  width: 35px;
  height: 35px;
  padding: 6px;
}

.video-detail-manager {
  grid-template-columns:repeat(auto-fit, minmax(0, 254px)) !important;
}
.video-detail-items {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.video-detail-manager .video-detail-cove {
  height: 145px;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.video-contents p {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 13px;
  max-width: 216px;
}

.views-and-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.views-and-time span {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.video-detail-managers {
  grid-template-columns:repeat(3, minmax(0, 322px));
  display: grid;
  gap: 30px;
}

.video-detail-manager .position-relative:hover .image-buttons {
  display: flex;
}
.position-relative:hover .image-buttons {
  display: flex;
}
.awards-item-video {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
  /* height: 285px; */
  height: 325px;
  padding: 20px;
}
.video-detail-managers {
  grid-template-columns:repeat(3, minmax(0, 322px)) !important;
  display: grid;
  gap: 30px;
}

.video-detail-managers .video-detail-cover {
  aspect-ratio: 16 / 9;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

/* .video-detail-managers .video-detail-cove {
  height: 158px;
  width: 282px;
  border-radius: 15px;
  position: relative;
  margin-bottom: 16px;
}
.video-detail-managers .video-detail-items {
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 20px;
  height: 285px;
  width: 322px;
} */

.video-content {
  padding: 10px 10px 13px 10px;
}

.video-contents .title_text {
  max-width: 282px;
  min-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.close_icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("../../images/CloseIco.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(100% - 20px);
  border: none;
  cursor: pointer;
}

.image-buttons-cancel {
  display: none;
  column-gap: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
}

.video-detail-items:hover .image-buttons-cancel {
  display: flex;
}
.view_all {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #01104d;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  line-height: normal;
  cursor: pointer;
  text-decoration: underline !important;
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  bottom: 10px;
  gap: 5px;
}
.pagination-containers-list {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  bottom: 10px;
  gap: 5px;
  margin-right: 20px;
}

.pagination-button {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
}
.pagination-buttons {
  background-color: #8d8d8d;
  width: 45px;
  color: #fff;
  font-size: 16px;
  border: none;
  font-weight: 600;
  padding: 10px 16px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.disable {
  opacity: 0.6;
}
.pagination-buttons:hover {
  opacity: 1;
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
}
.pagination-button:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
  cursor: pointer;
}

.pagination-buttons.active {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.pagination-button.disabled {
  pointer-events: none;
  background-color: #8d8d8d;
  /* opacity: 0.6; */
}

.pagination-containers-list button {
  border-radius: 16px;
  border: 2px solid #001597;
  /* padding: 14px 22px; */
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.tag_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.video_button {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 15px;
}

.video_like {
  background: none;
  border: none;
  align-items: center;
  margin-bottom: 2px;
}

.video_like_bg {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #fff;
  position: absolute;
  top: 11px;
  left: 11px;
  z-index: 1;
}

@media screen and (max-width: 1400px) {
  .video-detail-managers {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    display: grid;
    gap: 30px;
  }
  .video-detail-managers .video-detail-items {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    height: 285px;
    width: 100%;
  }
  .video-detail-managers .video-detail-cover {
    /* height: 158px; */
    width: 100%;
    border-radius: 15px;
    position: relative;
    /* margin-bottom: 16px; */
  }
  .awards-item-video {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    height: 100%;
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 1067px) {
  .video-detail-managers {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    display: grid;
    gap: 30px;
  }
  .video-detail-managers .video-detail-items {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    height: 285px;
    width: 100%;
  }
  .video-detail-managers .awards-item-video {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    height: 100%;
    padding: 20px;
  }
  .video-detail-managers .video-detail-cover {
    /* height: 158px; */
    width: 100%;
    border-radius: 15px;
    position: relative;
    /* margin-bottom: 16px; */
  }
  .awards-item-video {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    height: 100%;
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .video-detail-managers {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    display: grid;
    gap: 30px;
  }
  .video-detail-managers .video-detail-items {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 20px;
    height: 285px;
    width: 100%;
  }
  .video-detail-managers .awards-item-video {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    height: 100%;
    padding: 20px;
  }
  .video-detail-manager .video-detail-cover {
    /* height: 158px; */
    width: 100%;
    border-radius: 15px;
    position: relative;
    /* margin-bottom: 16px; */
  }
  .awards-item-video {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
    height: 100%;
    width: 100%;
    padding: 20px;
  }
}

/* Container for buttons to center them */
.button-container {
  text-align: center;
  margin-top: 20px;
}

.button-container button {
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  display: inline-block;
}

.load-more-btn {
  color: #001597;
  font-size: 14px;
  border: none;
  background: none;
}

.hide-btn {
  color: #001597;
  font-size: 14px;
  border: none;
  background: none;
}

/* .ant-upload-select .image_view{
  width: 720px !important;
  height: 320px !important;
} */

/* .react-tabs__tab-panel .upload-input {
  width: 720px !important;
  height: 320px !important;
} */
.react-tabs__tab-panel .upload-input .ant-upload{
  width: 100% !important;
  /* height: 320px !important; */
}

.video_no_scroll_header{
  padding-top: 26px !important;
}

.video_no_scroll{
  max-height: 495px !important;
}

.video_image_upload{
  padding-top: 25px !important;
}

.video_bar .react-tabs__tab-list{
  margin-top: 10px;
  margin-bottom: 12px !important;
}