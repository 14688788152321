.contact-info-item.information ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.contact-info-item.information ul li a{
    color: #303030;
font-size: 16px;
font-weight: 400;
line-height: normal;
}
.e-mail a{
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 16px;
font-weight: 400;
line-height: normal;
border-bottom: 1px solid #001597;
}
.contact-info-item.information ul li{
    display: flex;
    align-items: center;
    column-gap: 9px;
}
.contact-info-grid{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
}
.contact-info-item.map{
    border-radius: 15px;
    overflow: hidden;
}