.menu-grid--item {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 36px;
}

.menu-grid-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}

.menu-item-cover {
    border-radius: 15px;
    height: 238px;
    position: relative;
}

.menu-item-cover:after {
    background: linear-gradient(134deg, rgba(0, 0, 0, 0.22) 33.04%, rgba(0, 0, 0, 0.00) 55.18%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.menu-item-cover .rating {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    border-radius: 15px 0;
    width: 58px;
    height: 27px;
}

.menu-item-details {
    padding: 10px 20px;
}

.menu-item-details h4 {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
}

.menu-item-details span {
    color: rgb(48, 48, 48, 0.8);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.restaurant-menu--grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 30px;
}

.restaurant-menu--item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    padding: 30px;
    display: flex;
    align-items: center;
    column-gap: 32px;
}

.restaurant-menu--item p {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: 134.4%;
    margin-bottom: 8px;
}

.restaurant-menu--item h4 {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10PX;
}

.menu--image {
    width: 100px;
    height: 100px;
    border-radius: 21px;
    background: linear-gradient(150deg, rgba(135, 136, 139, 0.40) 3.43%, rgba(173, 175, 176, 0.40) 53.19%, rgba(128, 129, 132, 0.40) 96.56%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.manu--details {
    width: calc(100% - 132px);
}

.market-restaurant {
    position: relative;
    border-radius: 15px;
    padding: 98px 40px 98px 60px;
}

.market-restaurant::after {
    border-radius: 15px;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
    background: #474747;
}

.market-restaurant h4 {
    font-size: 30px;
    font-weight: 700;
    line-height: 126.5%;
    text-transform: capitalize;
    margin-bottom: 17px;
}

.market-restaurant span {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 7px;
    display: block;
}

.market-restaurant p {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.reting-div {
    display: flex;
    column-gap: 5px;
    margin-bottom: 18px;
}

.breackfast-grid--item {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    display: grid;
    gap: 27px;
}

.voucher-item {
    border-radius: 10px;
    background: #303030;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
    height: 342px;
    display: flex;
    color: #fff;
    align-items: center;
}

.voucher-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 10px 10px 0;
}

.voucher-item h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 25px;
}

.voucher-item h4 {
    font-size: 29px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 7px;
}

.voucher-item h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
}

.voucher-item span {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 6px;
}

.voucher-item h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.voucher-detail {
    padding-left: 40px;
    padding-right: 20px;
    width: 50%;
}

.voucher-cover {
    width: 50%;
    height: 100%;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 20% 100%, 0 50%);
}

.restaurant-booking-form {
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    color: #fff;
}

.restaurant-booking-form::after {
    border-radius: 15px;
    opacity: 0.9;
    background: #303030;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.restaurant-booking-form form {
    position: relative;
    z-index: 1;
    padding: 30px;
}

.restaurant-booking-form label {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 9px;
}

.restaurant-booking-form input,
.restaurant-booking-form input::placeholder {
    color: rgb(141 141 141 / 60%);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.restaurant-booking-form input {
    height: 50px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    width: 100%;
    padding-left: 20px;
}

.input-grid-restaurant {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 30px;
    margin-bottom: 30px;
}

.restaurant-booking-form h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
}

.restaurant-details .free-courses-starter-pack {
    background: url(../../images/16dd0761071153e6f37dda743bf986bd.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.breackfast-grid--item h2 {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}

.breackfast-grid--item h5 {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
}

.breackfast-grid--item h6 {
    color: #303030;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.restaurant--sub-detail {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px;
}

.restaurant--sub-detail p {
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.restaurant--sub-detail span {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.col-gap-70 {
    column-gap: 70px;
}

.price-btn {
    border-radius: 25px;
    border: 1px solid #303030;
    background: #F9F9F9;
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    padding: 5px 15px;
}