.event-slide {
    height: 615px;
    border-radius: 15px;
    color: #fff;
    padding: 50px 0px 0 93px;
    position: relative;
}

.event-slide::after {
    border-radius: 15px;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.15) 0%, #000 100%);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.event-slide--date {
    width: 72.787px;
    height: 78px;
    background: #fff;
    box-shadow: 0px 0px 14.882px rgba(82, 106, 103, 0.19);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.event-slide--date h3 {
    color: #303030;
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
}

.event-slide--date span {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
}

.event-slide--name h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.event-slide--name p {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.event-silde--header {
    display: flex;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
}

.event-slide--detail {
    position: relative;
    z-index: 1;
}

.event-slide--detail p {
    font-size: 18px;
    font-weight: 500;
    line-height: 153.4%;
    max-width: 610px;
    margin-bottom: 40px;
}

.event-slide--book {
    display: flex;
    align-items: center;
    column-gap: 18px;
    margin-bottom: 40px;
}

.event-slide--book p {
    margin: 0;
    opacity: 0.7;
    background: linear-gradient(145deg, #0F0 14.06%, #00DA00 57.68%, #00C700 85.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}

.event-slide--recomendation {
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    position: relative;
    z-index: 1;
}

.event-slide--thumb {
    width: 223.641px;
    height: 146.997px;
    border-radius: 20px;
    border: 1px solid #8D8D8D;
    position: relative;
}

.event-slide--thumb::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 20px;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 34.71%, #000 95.23%);
}

.event-thumb--date {
    height: 35.026px;
    width: 37.405px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
}

.event-thumb--date h3 {
    color: #303030;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin: 0;
}

.event-thumb--date span {
    color: #000;
    font-size: 10px;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
}

.thumb-swiper-position {
    bottom: 50px;
    left: 93px;
    z-index: 9;
    width: 100%;
}

.thumb-swiper-position .swiper-slide {
    width: 223.641px;
    cursor: pointer;
}

.thumb-swiper-position .swiper-slide-thumb-active .event-slide--thumb {
    border: 2px solid #fff;
}

.thumb-swiper-position .swiper-slide-thumb-active .event-slide--thumb::after {
    content: "";
    position: absolute;
    height: 6.053px;
    border-radius: 6px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    width: calc(100% - 20px);
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 9px;
    top: unset;
}

.mySwiper2 .swiper-button-prev {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='38' viewBox='0 0 21 38' fill='none'%3E%3Cpath d='M19.1 36.21L2 19.11L19.1 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    width: 21px;
    height: 38px;
    position: absolute;
    top: 40%;
    left: 20px;
    z-index: 1;
}

.mySwiper2 .swiper-button-next {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='38' viewBox='0 0 21 38' fill='none'%3E%3Cpath d='M1.90002 36.21L19 19.11L1.90002 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    width: 21px;
    height: 38px;
    position: absolute;
    top: 40%;
    right: 20px;
    z-index: 1;
}

.event-cetegory .swiper-button-prev {}

.event-cetegory .swiper-button-next {
    background: url("data:image/svg+xml,%0A%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.1579 0H11.8421C5.30189 0 0 7.62191 0 17.024V36.976C0 46.3781 5.30189 54 11.8421 54H42.1579C48.6981 54 54 46.3781 54 36.976V17.024C54 7.62191 48.6981 0 42.1579 0Z' fill='url(%23paint0_linear_3866_148684)'/%3E%3Cpath d='M23.1434 16.9703L33.9434 27.7672L23.1434 38.5703' stroke='white' stroke-width='3.08571' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3866_148684' x1='14.4708' y1='-11.5196' x2='65.815' y2='38.5823' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    width: 54px;
    height: 54px;
    position: absolute;
    top: 43px;
    right: -10px;
    z-index: 1;
    cursor: pointer;
}

.event-cetegory .swiper-slide {
    width: 235px !important;

}

.event-cetegory .swiper-wrapper {
    padding: 5px 1px 35px 1px;
}

.event-cetegory .swiper {
    margin-right: 50px;
}

.event-cetegory .swiper-pagination-progressbar {
    bottom: 0;
    top: unset !important;
}

.event-cetegory .swiper-pagination-progressbar-fill {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%) !important;
}

.cetegory-swiper {
    border-radius: 15px;
    box-shadow: 0px 4px 15px 0px rgba(82, 106, 103, 0.19);
    background: #fff;
    display: flex;
    align-items: center;
    padding: 32px 12px;
    justify-content: space-between;
    position: relative;
}

.cetegory-swiper::after {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    content: "";
    height: 70px;
    width: 5px;
    border-radius: 10px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
}

.cetegory-swiper h2 {
    color: #303030;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
}

.cetegory-swiper p {
    margin: 0;
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.event-host img {
    width: 24px;
    height: 24px;
    border: 1px solid #FFF;
    border-radius: 50%;
    object-fit: cover;
    margin-left: -6px;
}

.event-host {
    position: absolute;
    top: 11px;
    left: 11px;
    z-index: 1;
}

.event-host img:first-child {
    margin-left: 0;
}

.event-offer-cover {
    color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 14.882px 0px rgba(82, 106, 103, 0.19);
    position: relative;
    height: 186px;
}

.event-offer-cover::after {
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 50.23%, rgba(0, 0, 0, 0.60) 95.33%);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.event-name-and-text {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0px 0px 19px 19px;
    background: rgba(0, 0, 0, 0.70);
    width: 100%;
    padding: 10px 15px 13px 15px;
    z-index: 1;
}

.event-offer-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}

.event-ticket-detail {
    padding: 8px 10px 12px 10px;
    display: flex;
    justify-content: space-between;
}

.about-event {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.offer-price-event {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
}

.actual-price-event {
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
    margin: 0;
}

.event-name-and-text h6 {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.event-name-and-text h5 {
    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.event-name-and-text p {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

.event-offer-grid {
    display: grid;
    gap: 28px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.ticket-grid {
    display: grid;
    row-gap: 30px;
    column-gap: 50px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.ticket-item {
    height: 346px;
    color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.ticket-item h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 25px;
    color: #fff;
}

.ticket-item h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(120deg, #FC3F6F 0%, #FC134B 64.36%, #FC003C 90.19%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.ticket-item h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 6px;
}

.ticket-item h6 {
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 12px;
}

.ticket-item span {
    color: #8D8D8D;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    /* 160% */
}

.ticket-qr {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 11px;
    width: 152px;
    height: 100%;
    border-radius: 20px;
    border: 1.25px dashed #C4C4C4;
    box-shadow: 0px 2.5px 15px rgba(128, 128, 128, 0.16);
    position: relative;
}

.ticket-qr::after {
    content: "";
    width: 55px;
    height: 55px;
    background: #fff;
    border-radius: 50%;
    top: -27.5px;
    left: -27.5px;
    position: absolute;
    border-right: 1.25px dashed #C4C4C4;
}

.ticket-qr::before {
    content: "";
    width: 55px;
    height: 55px;
    background: #fff;
    border-radius: 50%;
    bottom: -27.5px;
    left: -27.5px;
    position: absolute;
    border-right: 1.25px dashed #C4C4C4;
}

.ticket-details {
    width: calc(100% - 152px);
    height: 100%;
    position: relative;
    border-radius: 20px;
}

.ticket-details::after {
    content: "";
    position: absolute;
    background: rgba(14, 13, 13, 0.45);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
}

.ticket-footer {
    max-width: 250px;
    border-top: 1px solid rgba(255, 255, 255, 0.20);
}

.ticket--details {
    padding: 54px 0 0px 22px;
}

.ticket-overview--img {
    height: 365px;
    object-fit: cover;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 14.882px 0px rgba(82, 106, 103, 0.19);
}

.ticket-cover-row {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 36px;
    margin-top: 10px;
}

.ticket-cover-row img {
    height: 107px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.price--ticket-offer {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.price--ticket-actual {
    color: #8D8D8D;
    font-size: 18px;
    opacity: 0.6;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
}

.price-ticket-counter--wrapper {
    display: flex;
    align-items: center;
    column-gap: 37px;
}

.price-ticket-counter--wrapper span {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    opacity: 0.6;
}

.price--ticket-counter button {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price--ticket-counter {
    color: #474747;
    font-size: 20.16px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 13px;
}

.price--ticket-location {
    padding: 33px 0 18px 0;
}
.price--ticket-count{
    padding: 13px 0 17px 0;
}
.price--ticket-type .dropdown {
    margin: 0;
}

.price--ticket-atendent img {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    border: 1px solid #FC396B;
    margin-left: -16px;
    object-fit: cover;
}

.price--ticket-atendent img:first-child {
    margin-left: 0px;
}

.price--ticket-atendent {
    color: #8D8D8D;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 19px 0 23px 0;
}

.price--ticket-atendent span {
    background: linear-gradient(150deg, #FF0067 97149.48%, #F04 98144.81%, #FF0013 99704.17%, #F00 100434.08%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}
.vertical-divider{
    opacity: 0.4;
background: #8D8D8D;
height: 1px;
width: 100%;
}
.ticket-overview--profile{
    margin-bottom: 21px;
    margin-top: 60px;
}
.ticket-overview--profile img{
    width: 49px;
    height: 49px;
    border-radius: 10px;
    object-fit: cover;
}
.ticket-overview--type-detail ul{
    display: flex;
    border: none;
    column-gap: 85px;
}
.ticket-overview--type-detail li{
    color:  #303030;
font-size: 18px;
font-weight: 600;
line-height: normal;
display: flex;
column-gap: 12px;
align-items: center;
border: none;
}
.ticket-overview--guest{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 33px;
}
.ticket-overview--guest img{
    width: 95px;
    height: 100px;
    border-radius: 31px;
border: 2px solid  #001597;
box-shadow: 0px 0px 10.098px 0px rgba(0, 0, 0, 0.15);
}
.ticket-overview--guest h2{
    color:  #303030;
font-size: 16px;
font-weight: 600;
line-height: normal;
margin: 0;
margin-top: 15px;
}
.ticket-overview--guest span{
    color:  #8D8D8D;
font-size: 14px;
font-weight: 500;
line-height: normal;
display: block;
margin-bottom: 10px;
}
.ticket-overview-guest--overview{
    border-radius: 15px;
background:  #FFF;
box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
padding: 20px;
}
.ticket-overview-guest--overview a{
    background:  linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 14px;
font-weight: 500;
line-height: normal;
border-bottom: 1px solid #001597;
}