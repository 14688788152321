.header-wrapper {
  border-radius: 0px 0px 15px 0px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  padding: 30px;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 238px);
  z-index: 10;
}
.serch-input input {
  /* border-radius: 16px; */
  width: 280px;
  min-height: 50px;
  border-radius: 35px;
  /* box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19); */
  padding-left: 51px;
  border: none !important;
  outline: none;
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.header-right-bar {
  column-gap: 10px;
}
.serch-input input::placeholder {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.serch-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}
.right-button {
  height: 44px;
  width: 44px;
  background: var(--header-bg);
  border-radius: 100px;
  border: none;
  align-content: center;
  justify-items: center;
}
.dropdown-toggles {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  padding: 4px 8px !important;
}
.dropdown-toggles img {
  border-radius: 100px;
  border: 1.5px solid #001597;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0px 0px 2.735px 0px rgba(0, 0, 0, 0.15);
  width: 36px;
  height: 36px;
}

.dropdown-toggles:after {
  content: "";
  transition: all 0.3s;
  border: solid #001597;
  border-width: 0 2px 2px 0;
  float: right;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -6px;
  margin-right: 6px;
}

.ant-dropdown-menu-item.header_item span {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.ant-dropdown-menu-item.header_item span img{
 height: 26px !important;
 width: 26px !important;
}
.ant-dropdown-menu-item.header_item span span {
  font-size: 14px;
  font-weight: 500;
  color: #0b0a0a;
  font-family: Montserrat;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
