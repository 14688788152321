.about h3 {
    margin-bottom: 10px;
}

.about p {
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.about span {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid #001597;
}

.expert-courses .courses-item-row {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.expert-courses .courses-item-box {
    height: 276px;
}

.expert-megazines .like {
    right: 11px;
    left: unset !important;
}

.megazines-slider-container {
    height: 375px;
    border-radius: 15px;
}

.megazines-slider-container::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.87) 58.4%, rgba(189, 189, 189, 0.00) 100%);
}

.megazines-slider-detail {
    color: #fff;
    position: relative;
    z-index: 1;
    padding: 30px 30px 30px 90px;
}

.megazines-slider-detail h6 {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.megazines-slider-detail h5 {
    font-size: 22px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.megazines-slider-detail p {
    max-width: 610px;
    font-size: 16px;
    font-weight: 400;
    line-height: 155.9%;
    /* 24.944px */
}

.col-gap-10 {
    column-gap: 10px;
}

.expert-megazines .mySwiper-story .swiper-button-prev.swiper-button-disabled {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='38' viewBox='0 0 21 38' fill='none'%3E%3Cpath d='M19.1 36.21L2 19.11L19.1 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.expert-megazines .mySwiper-story .swiper-button-prev {
    width: 21px;
    height: 38px;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='38' viewBox='0 0 21 38' fill='none'%3E%3Cpath d='M19.1 36.21L2 19.11L19.1 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.expert-megazines .mySwiper-story .swiper-button-next.swiper-button-disabled {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='38' viewBox='0 0 21 38' fill='none'%3E%3Cpath d='M1.90002 36.21L19 19.11L1.90002 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.expert-megazines .mySwiper-story .swiper-button-next {
    width: 21px;
    height: 38px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='38' viewBox='0 0 21 38' fill='none'%3E%3Cpath d='M1.90002 36.21L19 19.11L1.90002 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 1;
    background: #fff;
    display: inline-block;
}

.swiper-pagination-bullet-active {
    background: #8d8d8d;
    opacity: 1;
}

.swiper-pagination {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;
    z-index: 9;
}

.recipes-slider-item {
    display: flex;
    column-gap: 20px;
    border-radius: 15.053px;
    background: #FFF;
    box-shadow: 0px 0px 15.053px 0px rgba(82, 106, 103, 0.19);
    margin: 10px 26px 30px 26px;
}

.recipes-slier-item-detail {
    width: 50%;
    min-height: 483px;
    padding: 40px 0 40px 40px;
}

.recipes-slier-item-detail h4 {
    color: #303030;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
}

.recipes-slier-item-detail p {
    color: #303030;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
    margin-top: 20px;
}

.recipes-slier-item-detail button {
    margin-top: 35px;
}

.recipies-profile {
    display: flex;
    align-items: center;
    column-gap: 13px;
}

.recipies-profile img {
    border-radius: 15px;
    border: 1px solid #001597;
    box-shadow: 0px 0px 5.492px 0px rgba(0, 0, 0, 0.15);
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.recipies-profile p {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.recipies-profile span {
    color: #8D8D8D;
    opacity: 0.3;
}

.linear-rating img {
    width: 14px;
    height: 13px;
    border: none;
    box-shadow: none;
}

.linear-rating {
    color: #FFF;
    font-size: 15.522px;
    font-weight: 500;
    line-height: normal;
    border-radius: 24.041px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    box-shadow: 0px 0px 8.078px 0px rgba(0, 0, 0, 0.15);
    width: 73px;
    height: 34px;
    column-gap: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cooking-info {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    padding: 20px;
}

.cooking-info h5 {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.cooking-info div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 40px;
}

.cooking-info p {
    display: flex;
    align-items: center;
    margin: 0;
    column-gap: 10px;
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.expert-recipes .mySwiper-story .swiper-button-prev {
    left: 6px;
}
.expert-recipes .mySwiper-story .swiper-button-next {
    right: 6px;
}
.views-and-time-read span{
    color: #303030;
font-size: 14px;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;
column-gap: 7px;
display: flex;
align-items: center;
}
.views-and-time{
    column-gap: 15px;
}
.recipes-slier-item-detail{
    position: relative;
}
.recipes-slier-item-detail .like{
    background: #fff;
    height: 19px;
    width: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    border-radius: 6px;
    top: 11px;
    right: 11px;
}