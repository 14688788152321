.photos-grid {
  grid-template-columns:repeat(3, minmax(0, 322px));
  display: grid;
  gap: 30px;
}

.photos-grid img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* object-fit: cover; */
}

.photos-grid-item {
  border-radius: 15px;
  height: 280px;
}

.upload-gellery-img {
  text-align: center;
}

.upload-gellery-img .upload-img {
  /* background: rgb(141 141 141 / 20%); */
  width: 85px;
  height: 85px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: auto;
}

.upload-gellery-img h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 15px;
}

.upload-gellery-img p {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  max-width: 247px;
  margin: auto;
  margin-bottom: 14px;
}

.upload-gellery-img button {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
}

.add-photos .photos-grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13.605px;
  border: 1px dashed rgba(48, 48, 48, 0.3);
  background: rgba(141, 141, 141, 0.05);
  box-shadow: 0px 0px 1.759px 0px rgba(82, 106, 103, 0.19);
  max-width: 322px;
  height: 229px;
}

.photos-grid-item-added {
  border: none !important;
  position: relative;
}
.photos-grid-item-added:hover .image-buttons {
  display: flex;
}
.image-buttons {
  display: none;
  column-gap: 15px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.image-buttons label {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%0A%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.87402 10.7828V13.1266H4.21777L11.1303 6.21406L8.78652 3.87031L1.87402 10.7828ZM12.9428 4.40156C13.1865 4.15781 13.1865 3.76406 12.9428 3.52031L11.4803 2.05781C11.2365 1.81406 10.8428 1.81406 10.599 2.05781L9.45527 3.20156L11.799 5.54531L12.9428 4.40156Z' fill='url(%23paint0_linear_17354_194274)'/%3E%3Cpath d='M1.87402 10.7828V13.1266H4.21777L11.1303 6.21406L8.78652 3.87031L1.87402 10.7828ZM12.9428 4.40156C13.1865 4.15781 13.1865 3.76406 12.9428 3.52031L11.4803 2.05781C11.2365 1.81406 10.8428 1.81406 10.599 2.05781L9.45527 3.20156L11.799 5.54531L12.9428 4.40156Z' fill='black' fill-opacity='0.2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_17354_194274' x1='7.4998' y1='1.875' x2='7.4998' y2='13.1266' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.47' stop-color='%2301104D'/%3E%3Cstop offset='0.82' stop-color='%23020C1C'/%3E%3Cstop offset='0.99' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.image-buttons button:hover,
.image-buttons label:hover {
  background-color: rgb(235, 235, 236);
}
.image-buttons button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116856)'%3E%3Cpath d='M6 19.8871C6 20.9871 6.9 21.8871 8 21.8871H16C17.1 21.8871 18 20.9871 18 19.8871V7.88708H6V19.8871ZM19 4.88708H15.5L14.5 3.88708H9.5L8.5 4.88708H5V6.88708H19V4.88708Z' fill='url(%23paint0_linear_9174_116856)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116856' x1='8.7517' y1='0.04721' x2='25.2404' y2='12.5615' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116856'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}
.image-buttons .edit_event_pencil button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}
.image-buttons .button1 {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 15.435V7.47003C1 5.20503 0.999941 4.07002 1.43994 3.20502C1.82908 2.44477 2.44862 1.82699 3.20996 1.44C4.07496 1 5.20997 1 7.46997 1H12.73C14.995 1 16.13 1 16.995 1.44C17.7563 1.82699 18.3759 2.44477 18.765 3.20502C19.205 4.07002 19.205 5.20503 19.205 7.47003V8.70502C19.205 10.97 19.205 12.1 18.765 12.965C18.3759 13.7265 17.7565 14.3459 16.995 14.735C16.13 15.175 14.995 15.175 12.73 15.175H8.45996C8.12543 15.1549 7.7897 15.1751 7.45996 15.235C7.24915 15.2848 7.0481 15.3693 6.86499 15.485C6.59477 15.6828 6.34817 15.911 6.13 16.165L5.18994 17.14C3.88494 18.49 3.22994 19.165 2.68994 19.22C2.45138 19.2455 2.21016 19.2134 1.98657 19.1264C1.76298 19.0394 1.5635 18.9001 1.40491 18.72C0.999907 18.25 1 17.315 1 15.435Z" stroke="url(%23paint0_linear_2594_87234)" stroke-width="1.5" stroke-miterlimit="10"/><defs><linearGradient id="paint0_linear_2594_87234" x1="5.87854" y1="-2.88863" x2="23.21" y2="14.0016" gradientUnits="userSpaceOnUse"><stop stop-color="%23001597"/><stop offset="0.4691" stop-color="%2301104D"/><stop offset="0.8208" stop-color="%23020C1C"/><stop offset="0.9944" stop-color="%23020B09"/></linearGradient></defs></svg>');
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.add-item-model button.edit-button-model {
  /* width: 35px;
  height: 35px; */
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
}
.add-item-model .primary-btn-modelConfirm {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
  width: 110px;
  height: 53.6px;
}

.add-item-model .primary-btn-modelConfirm:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
  cursor: pointer;
}

.add-item-model .secondary-btn-modelConfirm {
  width: 110px;
  border-radius: 16px;
  border: 1px solid #001597;
  background: #fff;
  padding: 14px 22px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  height: 53.6px;
}
.add-item-content .Gallery_header {
  color: #303030;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 23px;
}

.gallery_add_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.crop-btn{
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  right: 4px;
}
button.crop-btn:hover{
  border-radius: 8px;
  background: transparent;
}
button.crop-btn:hover svg{
  fill: #001597;
}

.image_add button {
  border-radius: 50%;
  background: #e6e6e6;
  box-shadow: 0px 0px 4.93px 0px rgba(82, 106, 103, 0.19);
  border: none;
  width: 35px;
  height: 35px;
  padding: 6px;
}