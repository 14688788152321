.content-manager,
.detail-main {
  margin-left: 238px;
  margin-top: 73px;
  padding: 30px;
  position: relative;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.content-manager-community-head {
  color: #303030;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}

.content-manager-discussions-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.discussions-wrapper-header {
  padding-bottom: 0 !important;
}

.common-contnt-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.common-card-body {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 30px;
}

.common-contnt-wrapper h2 {
  margin: 0;
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.common-contnt-wrapper a {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  transition: all 0.6s ease;
  padding-bottom: 4px;
}
.common-contnt-wrapper a:hover {
  border-bottom: 1px solid #01104d !important;
}

.content-manager .dropdown {
  margin: 0;
}

.content-manager-Statistics {
  border-radius: 15px;
  background: #fff;
  padding: 0px;
  border: 0.5px solid #E4E4E4;
}

.content-manager-video-wrapper .video-items {
  grid-template-columns: repeat(4, minmax(0, 254px));
  display: grid;
  gap: 11px;
}

.megazines-row {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 11px;
}

.content-manager-shots-wrapper .video-items {
  grid-template-columns: repeat(4, minmax(0, 254px));
  display: grid;
  gap: 11px;
}

.video-item-detail {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  height: fit-content;
}

.content-manager-podcasts-wrapper .Podcasts-item {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 11px;
}

.content-manager-courses-wrapper .courses-item {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 11px;
}

.content-manager-recipies-wrapper .recipies-item {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 11px;
}

.magazines-box {
  border-radius: 13.47px;
  background: #fff;
  box-shadow: 0px 0px 13.47px 0px rgba(82, 106, 103, 0.19);
  display: flex;
  column-gap: 13px;
}

.megazines-items {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  gap: 25px;
}

.magazines-cover {
  width: 40%;
  border-radius: 13.47px;
  position: relative;
}

.magazines-cover::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 13.47px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(67, 67, 67, 0.45) 0%,
    rgba(67, 67, 67, 0.45) 100%
  );
}

.magazines-cover .like {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  left: 13px;
  top: 13px;
  background: #fff;
  border-radius: 6px;
}

.magazines-detail {
  width: 60%;
  padding: 15px 15px 15px 0;
}

.magezines-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.magezines-title h6 {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.magazines-detail p {
  color: #303030;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  height: 66px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 13px 0 4px 0;
}

.magazines-detail .other-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.magazines-detail .other-info span {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.magazines-detail .other-info a {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px solid #01104d;
}

.content-manager-communitymegazines-wrapper .magazines-cover {
  height: 178px;
  width: 100%;
}

.content-manager-communitymegazines-wrapper .magazines-detail h5 {
  color: #303030;
  font-size: 15.498px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding-bottom: 4px;
}

.magazines-detail p.price {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  height: auto;
  padding: 0;
}

.other-detail-megazines span {
  color: #8d8d8d;
  font-size: 13.561px;
  font-weight: 400;
  line-height: normal;
}

.other-detail-megazines {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.megazines-row .magazines-items {
  border-radius: 14.53px;
  background: #fff;
  box-shadow: 0px 0px 14.53px 0px rgba(82, 106, 103, 0.19);
}

.megazines-row .magazines-detail {
  padding: 10px;
  width: 100%;
}

.discussions-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #001597 !important;
  overflow: hidden;
}

.discussion-profile {
  display: flex;
  column-gap: 15px;
  width: 624px;
}

.discssion-detail {
  width: calc(100% - 77px);
}

.discussions-item .discssion-profile-image {
  width: 77px;
  height: 77px;
  border-radius: 29.615px;
  border: 1.8px solid #00298f7d;
  box-shadow: 0px 0px 3.949px 0px rgba(0, 0, 0, 0.15);
}

.discssion-detail h5 {
  color: #303030;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.discssion-detail span {
  opacity: 0.6;
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  padding: 7px 0 10px 0;
}

.discssion-detail p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}

.giveaway_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */
}

.discussion-group .like_section {
  display: flex;
  gap: 5px;
  margin-left: 10px;
  margin-bottom: 3px;
}

.like_section .giveaway_Like img {
  width: 22px;
  height: 22px;
}

.discussion-group .like_section h4 {
  font-size: 22px;
}

.discussion-group img {
  max-width: 25px;
  max-height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  margin-left: -5px;
  border: 0.818px solid #001597;
  cursor: pointer;
}

.discussion-group img:first-child {
  margin-left: 0;
}

.discussion-group img:last-child {
  border: none;
}

.discussion-comment {
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}

.poll_comment_icon {
  /* opacity: 0.5; */
}

.discussion-button button {
  background: transparent;
  border: none;
}

.content-manager-Polls-wrapper .profress-line {
  border: 1.8px solid #00298fc7;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  overflow: hidden;
}

.content-manager-Polls-wrapper .profress-line div {
  border-radius: 10px 0 0 10px !important;
  justify-content: flex-start !important;
}

.polls-label {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  padding-left: 20px;
  min-width: 820px;
}

.active_label {
  color: #fffcfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  padding-left: 20px;
  min-width: 820px;
}

/* .polls-group .discussion-group img {
    border: 0.818px solid #00CEA1;
} */

.polls-group {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.polls-tag {
  border-radius: 16px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
}

.polls-tag span {
  background: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.poll-header p {
  color: #303030;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  max-width: 664px;
  margin: 0;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}

.poll-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.polls-option {
  display: flex;
  column-gap: 85px;
  align-items: center;
  cursor: pointer;
}

.polls-option .profress-line {
  width: calc(100% - 170px);
}

.awards-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 5.746px 0px rgba(82, 106, 103, 0.19);
}

.awards-cover {
  height: 195px;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.awards-cover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background:
    lightgray 50% / cover no-repeat,
    #c4c4c4;
  border-radius: 15px;
}

.awards-cover .like {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #fff;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
}

.awards-cover .like img {
  width: 16px;
}

.awards-details {
  padding: 10px 20px 15px 20px;
}

.awards-details h4 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.awards-details p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 33.6px;
}

.awards-other {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.awards-other .time-duration-detail {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.awards-profile-img {
  border-radius: 7.854px;
  box-shadow: 0px 0px 2.924px 0px rgba(0, 0, 0, 0.15);
  width: 26.263px;
  height: 25.579px;
}

.awards-profile {
  color: #303030;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.awards-row {
  grid-template-columns: repeat(3, minmax(0, 322px));
  display: grid;
  gap: 22px;
  /* height: 285px; */
}

.polls-item {
  margin-bottom: 30px;
  overflow: hidden;
}

.polls-item:last-child {
  margin-bottom: 0;
}

.poll-header {
  padding: 10px 0 20px 0;
}

.polls-option {
  margin-bottom: 20px;
}

.polls-option:last-child {
  margin-bottom: 0;
}

.content-manager-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.content-manager-head .dropdown {
  border-radius: 16px;
  border: 1px solid #001597;
  padding: 13px 15px;
  width: auto;
}

.content-manager-head button {
  border-radius: 13px;
  background: linear-gradient(136deg, #ff408d 15.86%, #fa0165 90.59%);
  padding: 15px 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.content-manager-main-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-manager-main-heading h2 {
  margin-bottom: 0;
  color: #303030;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.content-manager-main-heading button {
  border-radius: 16px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 14px 22px;
  border: none;

  transition: all 0.6s ease;
}
/* .content-manager-main-heading button:hover {
  transform: scale(0.9);
} */
.content-manager-Statistics-chart h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 30px;
}

.Statistics-tab {
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.Statistics-tab button {
  border-radius: 16px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  border: none;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding: 14px 22px;
}

.statistic-chart-container h4 {
  background: linear-gradient(134.3deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%),
linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 15px;
  padding: 15px 10px;
  border-bottom: 1px solid #E4E4E4;
}

.statistic-chart-container .react-tabs__tab-list,
.details-tab-view .react-tabs__tab-list {
  border-bottom: none;
  box-shadow: 0px 0px 15px 0px #0000000f;
  margin-left: -30px;
  margin-right: -30px;
}

.details-tab-view .react-tabs__tab-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  padding: 20.5px 0px 20.5px 20px;
}

.content-manager .details-tab-view {
  position: relative;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  padding-top: 0;
}

.content-manager .details-tab-view::-webkit-scrollbar {
  display: none;
}

.content-manager .react-tabs__tab-list {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 11;
  padding: 24px 0px 20px 30px;
}

.view_watch_sub {
  padding: 10px 0px 2px 0px !important;
  margin-bottom: 0 !important;
}

/* .content-manager .add-video-row {
    position: sticky;
    top: 76px;
    background-color: #fff;
    z-index: 11;
    margin-left: -30px;
    margin-right: -30px;
    padding: 10px 30px;
    
} */
.content-manager .add-video-row {
  margin-top: 30px;
}

.content-manager .react-tabs__tab-list {
  margin-bottom: 0px !important;
}

.details-tab-view .react-tabs__tab--selected svg,
.details-tab-view .react-tabs__tab--selected svg path {
  fill: #001c93;
}

.details-tab-view .react-tabs__tab--selected img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(52%) saturate(6444%)
    hue-rotate(229deg) brightness(88%) contrast(115%);
}
.details-tab-view .react-tabs__tab:hover img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(52%) saturate(6444%)
    hue-rotate(229deg) brightness(88%) contrast(115%);
}
.details-tab-view .react-tabs__tab--selected {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.details-tab-view .react-tabs__tab:hover {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.details-tab-view .react-tabs__tab {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 11px;
  border: none;
}

.statistic-chart-container .react-tabs__tab {
  border: none;
  color: #000;
  font-size: 16.226px;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin-left: 30px;
  padding-bottom: 6px;
}

.statistic-chart-container .react-tabs__tab--selected {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  border-bottom: 2px solid #000;
}

.statistic-chart-container .dropdown {
  border-radius: 11px;
  border: 1px solid #001597;
  width: 96px;
  padding: 5px 18px;
}

.statistic-chart-container .dropdown__items {
  top: 27px;
}

.content-manager-head .dropdown__items {
  top: 45px;
}

.content-manager-head .dropdown.active .dropdown__items {
  width: calc(100% + 0px);
  left: 0;
}

.content-manager-head .dropdown__item img {
  width: 30px;
  height: 30px;
}

.content-manager-head .dropdown__item:hover {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  -webkit-text-fill-color: #fff;
}

.content-manager-head .dropdown__item:hover img {
  filter: brightness(0) invert(1);
}

.content-manager-head .dropdown__item {
  display: flex;
  align-items: center;
  column-gap: 17px;
}

.statistic-chart-container .react-datepicker__input-container input {
  border-radius: 100px;
  border: 0.853px solid #ece9f1;
  background: linear-gradient(120deg, #0e3177 0%, #05101a 90.19%);
  color: #fff;
  width: 130px;
  padding: 9px 0px 9px 48px;
  color: #fff;
  font-size: 11.942px;
  font-weight: 600;
  line-height: normal;
  text-align: end;
  padding-right: 20px;
}

.statistic-chart-container .react-datepicker__input-container {
  /* width: max-content; */
}

.statistic-chart-container .react-datepicker__input-container::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  left: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 13 12' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.95508 3.01469C1.95508 2.46508 2.40062 2.01953 2.95023 2.01953H9.91633C10.4659 2.01953 10.9115 2.46508 10.9115 3.01469V9.98078C10.9115 10.5304 10.4659 10.9759 9.91633 10.9759H2.95023C2.40062 10.9759 1.95508 10.5304 1.95508 9.98078V3.01469Z' stroke='white' stroke-width='0.995157' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.42285 1.02429V3.0146' stroke='white' stroke-width='0.995157' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.44238 1.02429V3.0146' stroke='white' stroke-width='0.995157' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.95508 5.00488H10.9115' stroke='white' stroke-width='0.995157' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.statistic-chart-container .react-datepicker__input-container::before {
  content: "Year";
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  left: 43px;
  color: #fff;
  font-size: 11.942px;
  font-weight: 600;
  line-height: normal;
}

.drop-and-date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
  position: absolute;
  top: 70px;
  right: 10px;
}

.video-content {
  padding: 10px 10px 13px 10px;
}

.platform-badge {
  width: 40px;
  height: 35px;
  background: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 15px 0 0 0;
  z-index: 1;
  padding: 6px 8px;
  justify-items: center;
}

.badge-cover {
  width: 100%;
  height: 100%;
}

.platform-badge-alt {
  width: 45px;
  height: 70px;
  background: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 15px 0 0 0;
  z-index: 1;
  padding: 6px 8px;
}

.platform-badge-alts {
  width: 45px;
  height: 70px;
  background: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 22px 0 14px 0;
  z-index: 1;
  padding: 6px 8px;
}

.badge-cover-alts {
  width: 84%;
  height: 46%;
  margin-bottom: 7px;
}

.platform-badge.discussions-badge {
  border-radius: 22px 0 14px 0 !important;
}

.badge-cover-alt {
  width: 84%;
  height: 46%;
  margin-bottom: 7px;
}

.video-detail-manager .video-detail-cover {
  aspect-ratio: 16 / 9;
  width: 100%;
  border-radius: 15px;
  position: relative;
}

.video-detail-item:hover .image-buttons {
  display: flex;
  top: 10px;
  right: 10px;
}

.video-detail-manager .video-detail-cover:after {
  border-radius: 15px;
  /* background: #E0E0E0; */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-detail-manager .video-detail-cover .like {
  width: 18.974px;
  height: 18.974px;
  background: #fff;
  border-radius: 7px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 11px;
  left: 11px;
}

.activity-badge {
  color: #001c93;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  border-radius: 15px;
  background: #fff;
  padding: 5px 10px;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}

.video-detail-item:hover .activity-badge {
  visibility: hidden;
}

.video-content p {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 13px;
  min-height: 33.6px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}

.views-and-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.views-and-time span {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.video-detail-manager {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 30px;
}

.video-detail-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  position: relative;
  height: fit-content;
}

.add-video-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.add-video-row a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: all 0.6s ease;
}
/* .add-video-row a:hover {
  transform: scale(0.9);
} */

.add-video-rows {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 25px; */
  cursor: pointer;
}

.main_btn {
  margin-bottom: 25px !important;
}

.add-video-rows .add_new_content {
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  border-radius: 999px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: all 0.6s ease;
}

.add-video-rows.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

/* .add-video-rows .add_new_content:hover {
transform: scale(0.9);
} */

.items-filter input {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px #0000000f;
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 9px 10px 9px 51px;
  border: none;
  outline: none;
}

.items-filter input::placeholder {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.items-filter .dropdown {
  margin: 0;
  width: 114px;
  border-radius: 16px;
  border: 1px solid #001597;
}

.items-filter {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.items-filter .input-position::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21' fill='none'%3E%3Cpath d='M20.4412 19.2138L14.5771 13.3304C15.8953 11.7009 16.5381 9.62783 16.3729 7.53844C16.2076 5.44906 15.2471 3.50271 13.6891 2.10066C12.1312 0.698606 10.0947 -0.0522897 7.99955 0.00283362C5.90436 0.0579569 3.9102 0.914887 2.42817 2.39692C0.946137 3.87895 0.089207 5.87311 0.0340836 7.96829C-0.0210397 10.0635 0.729856 12.0999 2.13191 13.6578C3.53396 15.2157 5.48031 16.1763 7.5697 16.3416C9.65908 16.5068 11.7322 15.864 13.3617 14.5458L19.2258 20.4099C19.3054 20.49 19.4001 20.5535 19.5044 20.5969C19.6087 20.6403 19.7205 20.6626 19.8334 20.6626C19.9464 20.6626 20.0583 20.6403 20.1625 20.5969C20.2668 20.5535 20.3615 20.49 20.4412 20.4099C20.5959 20.2493 20.6824 20.0349 20.6824 19.8119C20.6824 19.5888 20.5959 19.3745 20.4412 19.2138ZM8.23847 14.6669C6.96333 14.6679 5.71667 14.2905 4.65603 13.5827C3.5954 12.8749 2.76845 11.8683 2.28004 10.6904C1.79162 9.51255 1.6636 8.21629 1.91213 6.96561C2.16067 5.71493 2.77468 4.56608 3.67634 3.66442C4.578 2.76276 5.72685 2.1488 6.97753 1.90027C8.22821 1.65174 9.52441 1.77976 10.7023 2.26817C11.8802 2.75659 12.8867 3.58348 13.5946 4.64411C14.3024 5.70475 14.6797 6.95147 14.6788 8.22661C14.6762 9.93389 13.9968 11.5705 12.7896 12.7777C11.5824 13.985 9.94575 14.6643 8.23847 14.6669Z' fill='%238D8D8D'/%3E%3C/svg%3E");
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-position: center;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.react-tabs__tab {
  padding: 10px 7px !important;
}

@media screen and (max-width: 1317px) {
  .details-tab-view .react-tabs__tab-list {
    column-gap: 0;
  }
}
@media screen and (max-width: 950px) {
  .video-content .views-and-time {
    column-gap: 0px;
  }
  .video-content .views-and-time span {
    width: 40px;
  }
}

@media screen and (max-width: 1077px) {
  .react-tabs__tab {
    padding: 6px 7px;
  }
}

@media screen and (max-width: 1200px) {
  .content-manager-video-wrapper .video-items,
  .content-manager-podcasts-wrapper .Podcasts-item,
  .content-manager-courses-wrapper .courses-item,
  .content-manager-recipies-wrapper .recipies-item,
  .megazines-row,
  .content-manager-shots-wrapper .video-items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .polls-label {
    font-size: 11px;
  }

  .active_label {
    font-size: 11px;
  }

  .poll-header p {
    max-width: 320px;
  }

  .discussion-profile {
    max-width: 325px;
  }

  .Statistics-tab {
    column-gap: 10px;
  }

  .Statistics-tab button {
    padding: 7px 10px;
  }

  .video-detail-manager,
  .Podcasts-item,
  .courses-item-row,
  .recipies-item-add,
  .shots-overview {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

.add-item-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000004d;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  scroll-behavior: smooth;
}

.add-item-model .add-item-content {
  background: #fff;
  border-radius: 15px;
  /* padding: 40px; */
  width: 800px;
  height: 600px;
  overflow-y: scroll;
  padding-right: 10px;
}

.add-item-content .header {
  position: sticky;
  top: 0;
  /* top: -41px; */
  background-color: #fff;
  padding: 40px 26px 20px 26px;
  /* padding: 24px 0 0 0; */
  z-index: 1001;
  width: 100%;
}

.add-item-content .header button {
  width: 30px;
  height: 30px;
}

.add-item-content .scroll-bars {
  padding: 0px 30px 40px 40px;
}
.add-item-content .scroll-bar {
  padding: 0px 30px 20px 40px;
}

.add-item-content .header-community {
  position: sticky;
  top: -41px;
  background-color: #fff;
  /* padding: 24px 0 0 0; */
  z-index: 1001;
  width: 100%;
}

.add-item-model .add-item-contents {
  background: #fff;
  border-radius: 15px;
  padding: 30px 40px;
}

.add-item-model .add-item-content::-webkit-scrollbar {
  display: none;
}

.add-item-model .upload-img {
  /* border-radius: 16px;
    background: #8D8D8D33; */
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.add-item-model .upload-img:hover {
  cursor: pointer;
}

.add-item-model h4 {
  font-size: 20px;
  font-weight: 900;
  line-height: 29.26px;
  margin-bottom: 0px;
  margin-top: 20px;
  font-family: Montserrat;
  color: #4E4949;
  font-style: italic;
  text-transform: uppercase;
  -webkit-text-fill-color: #4E4949;
}

.add-item-model p {
  color: #8d8d8d;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  margin-bottom: 30px;
}

.add-item-model .drag-drop-text {
  color: #8d8d8d;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  margin-bottom: 30px;
  font-family: Montserrat;
  max-width: 335px;
  margin: auto;
}

/* .add-item-model button span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
} */

.add-item-model button {
  border-radius: 16px;
  border: 2px solid #001597;
  background: transparent;
  padding: 14px 22px;
}

.add-item-model h3 {
  color: #303030;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.add-item-model .react-tabs__tab-list {
  box-shadow: none;
  margin-left: 0;
  margin-right: 0;
  gap: 42px;
}

.upload-div {
  margin-top: 60px;
}

.add-item-model .react-tabs__tab--selected {
  border-bottom: 1px solid #01104d;
  padding: 5px 0px !important;
}

.add-item-model .react-tabs__tab {
  padding-left: 0;
  padding-right: 0;
}

.upload-input {
  position: relative;
  text-align: center;
}

.upload-input input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.upload-input label {
  text-align: center;
}

.add-item-content video {
  border-radius: 8.21px;
  object-fit: cover;
}

.video-controls button {
  border: none;
  padding: 0;
  margin-right: 20px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.video-controls {
  position: absolute;
  bottom: 28px;
  left: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.video-model-wrapper::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 394px;
  top: 0;
  left: 0;
  border-radius: 8.21px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.63) 18.75%,
    rgba(189, 189, 189, 0) 110.04%
  );
}

.hidden {
  display: none;
}

.item-details-input label {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  /* /* line-height: normal; */
  margin-bottom: 6px;
}

/* .item-details-input {
    padding-top: 60px;
} */

.item-details-input input,
.item-details-input textarea {
  border-radius: 999px;
  background: #FAFAFA;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
  padding: 0px 20px;
  width: 100%;
  margin-bottom: 20px;
}

.tag-edit-container .edit-input {
  border-radius: 16px;
  background: transparent;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
  width: 100%;
  box-shadow: none;
  margin-bottom: 0px;
  padding: 12px 0px;
}

.item-details-input textarea {
  min-height: 120px;
  border-radius: 15px;
}

.item-details-input input::placeholder,
.item-details-input textarea::placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.add-item-model .secondary-btn-model {
  border-radius: 16px;
  border: 1.8px solid #001f6dc9;
  background: #fff;
  padding: 14px 22px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  width: 110px;
}

.add-item-model .secondary-btn-model:hover {
  background-color: rgb(235, 235, 236);
}

.add-item-model .secondary-btn-model:hover span {
  background-color: #001597;
}

.add-item-model .secondary-btn-model-store {
  border-radius: 16px;
  border: 1px solid #001597;
  background: #fff;
  padding: 14px 22px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
  /* width: 110px; */
}

.add-item-model .secondary-btn-model-store:hover {
  background-color: rgb(235, 235, 236);
}

.add-item-model .secondary-btn-model-store:hover span {
  background-color: #001597;
}

.secondary-btn-model:disabled,
button[disabled] {
  opacity: 60%;
  pointer-events: none;
}

/* .add-item-model .secondary-btn-model:visited,.add-item-model .secondary-btn-model:focus,.add-item-model .secondary-btn-model:focus-visible{
    color: #fff;
    background:linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%) ;
}
.add-item-model .secondary-btn-model:visited span,.add-item-model .secondary-btn-model:focus span,.add-item-model .secondary-btn-model:focus-visible span{
    -webkit-text-fill-color: #fff;
} */

.secondary-btn-model span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  font-family: Montserrat;
}

.video-controls button img {
  display: block;
}

.add-item-model .primary-btn-model {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
  width: 110px;
}

.add-item-model .primary-btn-model.loading {
  width: 140px;
}

.add-item-model .primary-btn-model-store {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 24px;
}

.platformTag_dropdown .primary-btn-model-store {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 30px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 12px 15px;
}

.primary-btn-model-store:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
  cursor: pointer;
}

.edit-model {
  display: flex;
  justify-content: end;
}

.add-item-model .secondary-btn-model-store {
  border-radius: 30px;
  /* border: 1.8px solid #00298f7d; */
  border: 1.8px solid #00298fc4;
  background: #fff;
  padding: 12px 24px;
  font-weight: 600;
  color: #7d7d7d;
  transition: all 0.2s ease-in;
}

.add-item-model .secondary-btn-model-store:hover {
  background-color: rgb(235, 235, 236);
}

.add-item-model .secondary-btn-model-store:hover span {
  background-color: #001597;
}

.item-details-input .button-row {
  display: flex;
  column-gap: 15px;
  padding-top: 30px;
  padding-bottom: 4px;
}

.custom-progress-range {
  width: calc(100% - 24px);
  position: absolute;
  left: 12px;
  bottom: 55px;
  z-index: 1;
  height: 3px;
  outline: none;
}

.duration-progress {
  color: #fff;
  font-size: 8.521px;
  font-weight: 500;
  line-height: normal;
  padding-left: 5px;
}

.live {
  color: #fff;
  font-size: 9.738px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  margin-left: 22px;
  padding-left: 4px;
}

.live::before {
  content: "";
  width: 6.12px;
  height: 6.12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -4px;
  background-position: center;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 7 7' fill='none'%3E%3Cpath d='M7.00053 3C7.00053 3.58059 6.32309 4.51726 6.00053 5C5.67798 5.48274 5.53693 5.77782 5.00053 6C4.46414 6.22218 3.56997 6.11327 3.00053 6C2.4311 5.88673 2.41107 5.41054 2.00053 5C1.59 4.58946 1.1138 4.56944 1.00053 4C0.887267 3.43056 0.778352 2.5364 1.00053 2C1.22272 1.4636 1.51779 1.32256 2.00053 1C2.48328 0.67744 3.41994 0 4.00053 0C4.77895 0.000413286 5.45011 0.449571 6.00053 1C6.55096 1.55043 7.00012 2.22158 7.00053 3Z' fill='url(%23paint0_linear_1056_44284)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1056_44284' x1='2.40382' y1='0.410334' x2='5.4683' y2='5.71862' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF5C00'/%3E%3Cstop offset='0.24' stop-color='%23FF4410'/%3E%3Cstop offset='0.75' stop-color='%23FF1330'/%3E%3Cstop offset='1' stop-color='%23FF003D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.show-filled-details .profile-img {
  width: 37px;
  height: 37px;
  border-radius: 13.42px;
  box-shadow: 0px 0px 3.343px 0px rgba(0, 0, 0, 0.15);
}

.profile-with-occupation {
  display: flex;
  align-items: center;
  column-gap: 11px;
}

.profile-basic p {
  color: #303030;
  font-size: 9.738px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: left;
}

.profile-basic .occupation {
  font-weight: 400;
  opacity: 0.6;
  padding-top: 4px;
}

.show-filled-details {
  position: relative;
}

.show-filled-details h5::before {
  content: "";
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E%3Cpath d='M10.0001 4C10.0001 4.87089 9.48391 6.27588 9.00007 7C8.51623 7.72412 7.80466 8.66673 7.00007 9C6.19548 9.33327 5.85422 9.1699 5.00007 9C4.14592 8.8301 2.61588 8.61581 2.00007 8C1.38426 7.38419 1.16997 5.85415 1.00007 5C0.830167 4.14585 0.666796 3.8046 1.00007 3C1.33334 2.1954 2.27596 1.48384 3.00007 1C3.72418 0.51616 5.12919 0 6.00007 0C7.1677 0.000619928 8.17443 0.174357 9.00007 1C9.82571 1.82564 9.99945 2.83237 10.0001 4Z' fill='url(%23paint0_linear_1056_44299)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1056_44299' x1='3.105' y1='0.6155' x2='7.70171' y2='8.57793' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF5C00'/%3E%3Cstop offset='0.24' stop-color='%23FF4410'/%3E%3Cstop offset='0.75' stop-color='%23FF1330'/%3E%3Cstop offset='1' stop-color='%23FF003D'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"); */
  background-image: url('data:image/svg+xml,<svg width="800px" height="800px" viewBox="0 0 72 72" id="emoji" xmlns="http://www.w3.org/2000/svg" fill="%23000000" stroke="%23000000"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"><g id="color"><circle cx="36" cy="36.0001" r="28" fill="%23d22f27"/></g><g id="line"><circle cx="36" cy="36.0001" r="28" fill="none" stroke="%23d22f27" stroke-linejoin="round" stroke-width="2"/></g></g></svg>');
  width: 10px;
  height: 10px;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 10%;
  /* transform: translateY(-50%); */
}

.show-filled-details h5 {
  color: #303030;
  font-size: 13.39px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
  position: relative;
  padding-left: 17px;
  margin-top: 10px;
}
.player_live h5 {
  font-family: Montserrat;
  color: #ffffff;
  font-size: 10.74px;
  font-weight: 400;
  line-height: 12.87px;
  text-align: left;
  margin-top: 7px;
}

.show-filled-details .streaming-data {
  gap: 17px;
}
.show-filled-details .streaming-data p {
  color: #303030;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 10.39px;
  text-align: left;
  margin-bottom: 0;
  position: relative;
  /* padding-left: 17px; */
  margin-top: 10px;
  opacity: 0.6;
}

.show-filled-details span {
  color: #303030;
  font-size: 8.521px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
  padding-bottom: 12px;
}

.discription {
  margin-top: 40px;
  padding: 15px 25px 25px 25px !important;
}

.discription h6 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.discription p {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.shimmer-title {
  width: 70px;
}

.shimmer-text {
  margin-bottom: 0 !important;
}

.Podcasts-item {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 30px;
}

/* .video-detail-item:hover {
    width: 125%;
    z-index: 9;
    height: 125%;
    box-shadow: 0px 0px 9px 0px rgba(26, 32, 67, 0.70);
    transform: translateY(-10px);
}

.video-detail-item:hover .video-detail-cover {
    height: 68%;
} */

.upload-input-with-border {
  border-radius: 13.605px;
  border: 1px dashed rgba(48, 48, 48, 0.3);
  background: rgba(141, 141, 141, 0.05);
  box-shadow: 0px 0px 1.759px 0px rgba(82, 106, 103, 0.19);
  padding: 20px;
  margin-bottom: 40px;
}

.shot_upload {
  border-radius: 13.605px;
  border: 1px dashed rgba(48, 48, 48, 0.3);
  background: rgba(141, 141, 141, 0.05);
  box-shadow: 0px 0px 1.759px 0px rgba(82, 106, 103, 0.19);
  padding: 20px;
  margin-bottom: 20px !important;
}

.input-grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  display: grid;
}

.input-grid-three {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  display: grid;
}

.podcast-details-manage .add-item-model .add-item-content {
  display: flex;
  flex-direction: column;
}

.podcast-overview {
  border-radius: 10.279px;
  background: rgb(48 48 48 / 80%);
  box-shadow: 0px 0px 7.364px 0px rgba(82, 106, 103, 0.19);
  display: flex;
  column-gap: 21px;
  padding: 20px;
}

.podcast-overview .podcast-detail h3 {
  color: #fff;
  font-size: 34.264px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.podcast-overview .podcast-detail p {
  color: #fff;
  font-size: 20.558px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  text-align: left;
}

.podcast-img {
  width: 196px;
  height: 185px;
  border-radius: 10.279px;
}

.podcast-profile-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 4.421px 0px rgba(0, 0, 0, 0.15);
}

.podcast-profile-img-content img {
  width: 51px;
  height: 51px;
  border-radius: 17.749px;
  box-shadow: 0px 0px 4.421px 0px rgba(0, 0, 0, 0.15);
}
.podcast-profile {
  display: flex;
  column-gap: 11px;
  align-items: center;
  margin-top: 25px;
}

.podcast-profile-detail span {
  margin: 0;
  color: #fff;
  font-size: 12.335px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.podcast-profile-detail .user_name {
  margin: 0;
  color: #303030;
  font-size: 12.335px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.podcast-profile-detail p {
  margin: 0;
  color: #8D8D8D;
  font-size: 12.335px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.podcast-profile-detail p .designation_p {
  font-size: 10.964px;
  font-weight: 500;
  padding-top: 6px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.podcast-profile-detail p.designation {
  font-size: 10.964px;
  font-weight: 500;
  padding-top: 6px;
}

.podcast-discription {
  align-items: center;
  row-gap: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 22px;
  column-gap: 39px;
  margin-top: 30px;
}

.podcast-discription h5 {
  color: #303030;
  font-size: 12.335px;
  font-weight: 600;
  line-height: normal;
  /* margin-top: 20px; */
}

.podcast-discription p {
  color: #303030;
  font-size: 15.96px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.courses-item-box {
  height: 240px;
  border-radius: 15px;
  position: relative;
  box-shadow: 0px 0px 20.194px 0px rgba(0, 0, 0, 0.3);
}

.courses-item-row {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  display: grid;
  gap: 30px;
}

.courses-item-box::after {
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(58, 58, 58, 0) 45.08%,
    rgba(58, 58, 58, 0.73) 70%,
    rgba(22, 22, 22, 0.85) 93.06%
  );
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Courses-name {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 10px;
}

.Courses-name p {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  max-width: 165px;
}

.Courses-name span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.courses-item-box .rating {
  border-radius: 0 15px 0 15px;
  position: absolute;
  top: 0;
  right: 0;
}

.courses-item-box .platform-badge {
  top: 0;
  left: 0;
  border-radius: 15px 0 15px 0;
}

.input-grid .dropdown {
  margin: 0 0 20px 0;
  width: 100%;
  padding: 16px 20px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07) !important;
  border: 0;
}

.input-grid .dropdown .dropdown__items {
  outline: none;
  box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.07) !important;
  width: 100%;
  margin-left: 0;
}

.input-grid .dropdown.active:before {
  display: none;
}

.input-grid .dropdown .dropdown__text {
  -webkit-text-fill-color: #303030;
  font-size: 14px;
}

.input-grid .dropdown__text:after {
  border: solid #303030;
  border-width: 0 2px 2px 0;
}

.preview-model-cources .add-item-content {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.courses-banner {
  border-radius: 10px;
  position: relative;
  height: 466px;
}

.courses-banner::after {
  position: absolute;
  content: "";
  border-radius: 10px;
  background: linear-gradient(
    83deg,
    rgba(48, 48, 48, 0.59) 43.4%,
    rgba(238, 238, 238, 0) 130.07%
  );
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.title-text {
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  left: 30px;
  bottom: 96px;
}

.title-text span {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.courses-banner .other-detail {
  display: flex;
  border-radius: 17px;
  background: #fff;
  padding: 8px 35px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  width: calc(100% - 60px);
  left: 30px;
  bottom: 30px;
}

.courses-banner .other-detail ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  column-gap: 20px;
}

.courses-banner .other-detail ul li {
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: linear-gradient(
    150deg,
    #59ccf9 3.43%,
    #50bbf8 20.16%,
    #378af6 70.88%,
    #2d77f5 96.04%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.courses-banner .other-detail button {
  border-radius: 12px;
  background: linear-gradient(
    150deg,
    #03cdfd 6.08%,
    #03b9fd 23.98%,
    #0188fd 70.39%,
    #0175fd 93.43%
  );
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  border: none;
  padding: 7.283px 13.229px 7.153px 12.651px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
}

.courses-banner .rating {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
}

.courses-banner .rating span {
  background: linear-gradient(
    150deg,
    #59ccf9 3.43%,
    #50bbf8 20.16%,
    #378af6 70.88%,
    #2d77f5 96.04%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.courses-banner .like {
  position: absolute;
  top: 20px;
  right: 80px;
  z-index: 1;
}

.courses-description {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 30px;
  margin: 40px 0;
}

.courses-description h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.courses-description p {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(
    180deg,
    rgba(48, 48, 48, 0.61) 36.01%,
    rgba(16, 16, 16, 0) 124.91%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}

.courses-description button {
  border: none;
  padding: 0;
  margin-top: 11px;
}

.episode-cover {
  height: 145px;
  border-radius: 15px;
}

.episode-cover::after {
  border-radius: 15px;
  /* background:  rgba(67, 67, 67, 0.45); */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.episode-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}

.episode-row {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  column-gap: 38px;
  row-gap: 25px;
}

.time-duration {
  border-radius: 15px 0px;
  background: linear-gradient(
    150deg,
    #59ccf9 3.43%,
    #50bbf8 20.16%,
    #378af6 70.88%,
    #2d77f5 96.04%
  );
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  z-index: 1;
  padding: 4px 10px;
  bottom: 0;
  right: 0;
}

.play-button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.episode-title-text {
  background: linear-gradient(
    150deg,
    #59ccf9 3.43%,
    #50bbf8 20.16%,
    #378af6 70.88%,
    #2d77f5 96.04%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.episode-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.episode-text {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  padding: 20px 0;
  margin: 0;
}

.episode-detail .other-detail {
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.episode-detail .other-detail span {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.episode-detail {
  padding: 10px;
}

.episode-box {
  margin-bottom: 46px;
}

.episode-box h3 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 22px;
}

.preview-model-cources .add-item-content {
  /* padding: 80px 137px; */
}

.about-courses-cetegory h2 {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
  padding-bottom: 14px;
  border-bottom: 1px solid #8d8d8d66;
  padding-top: 16px;
}

.about-courses-cetegory p {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin-bottom: 25px;
}

.about-courses-cetegory ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.about-courses-cetegory ul li {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
}

.recipies-item-add {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 30px;
}

.add-item-content .input-img-label label {
  width: 160px;
  height: 160px;
  row-gap: 13px;
}

.add-item-content .input-img-label .plsimg {
  width: 160px;
  height: 160px;
  row-gap: 13px;
  border: 1px solid #30303033;
}

.add-item-content .input-image-show .image {
  width: 160px;
  height: 160px;
}

.input-image-show .image button {
  padding: 0;
  border: none;
  background: transparent;
  position: absolute;
  top: -17px;
  right: -7px;
  z-index: 1;
  display: none;
  padding: 8px;
  border-radius: 50%;
}

.input-image-show .image:hover button {
  display: block;
}

.input-image-show .image:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  opacity: 0.7;
  background: #303030;
}

.plus-img {
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus-img img:hover {
  cursor: pointer;
}

.cooking-time-filed:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cg clip-path='url(%23clip0_1110_44265)'%3E%3Cpath d='M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM15.9516 17.6484L10.8 12.4968V4.8H13.2V11.5032L17.6484 15.9516L15.9516 17.6484Z' fill='url(%23paint0_linear_1110_44265)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1110_44265' x1='6.43149' y1='-5.11983' x2='29.2511' y2='17.1477' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_1110_44265'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  position: absolute;
  top: calc(50% - -1px);
  right: 13px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.custom-radio-input {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.custom-radio-input label {
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  position: relative;
}

.custom-radio-input input {
  background: transparent;
  box-shadow: none;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  display: none;
}

input[type="radio"] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border: 1px solid #cecece;
  border-radius: 50%;
  cursor: pointer;
  transition:
    background 0.2s,
    transform 0.2s;
}

input[type="radio"]:checked + label span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.badge-ingredients {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 19px;
  background: #f6f6f6;
  padding: 6px 15px;
  display: inline-block;
  position: relative;
}

.ingredients-row {
  display: flex;
  align-items: center;
  column-gap: 23px;
  flex-wrap: wrap;
  row-gap: 23px;
}

.badge-ingredients button {
  padding: 0;
  margin: 0;
  border: none;
  position: absolute;
  top: -7px;
  right: -3px;
  z-index: 1;
  width: 16px;
  height: 16px;
}

.recipies-step span {
  padding-left: 10px;
  word-break: break-all;
}

.recipies-step .label-poll {
  margin-bottom: 0px !important;
  width: 75px;
}

.recipies-img-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  gap: 20px;
}

.recipies-img-item {
  height: 190px;
  border-radius: 15px;
}

.recipies-img-item:first-child {
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
  height: 100%;
}

.recipies-detail-text,
.cooking-information,
.ingredients-of-slow,
.ingredients-of-slow-step {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 30px;
}

.recipies-detail-text h5,
.cooking-information h5,
.ingredients-of-slow h5,
.ingredients-of-slow-step h5 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.recipies-detail-text p {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.cooking-information ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 69px;
  padding-top: 17px;
}

.cooking-information li {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.ingredients-of-slow-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}

.ingredients-of-slow-row {
  grid-template-columns: repeat(6, minmax(0, 1fr));
  display: grid;
  column-gap: 50px;
  row-gap: 26px;
  padding-top: 17px;
}

.ingredients-of-slow-image {
  height: 111px;
  border-radius: 15px;
  box-shadow: 0px 0px 5.425px 0px rgba(0, 0, 0, 0.15);
}

.ingredients-of-slow-item p {
  margin: 0;
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  text-align: left;
}

.ingredients-of-slow-step h6 {
  color: #303030;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.ingredients-of-slow-step p {
  color: #303030;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  text-align: left;
}

.ingredients-of-slow-step-list {
  padding-top: 17px;
}

.row-gap-30 {
  row-gap: 30px;
}

.magazines-detail-item:hover {
  width: 100%;
  z-index: 9;
  height: 100%;
  box-shadow: none;
  transform: translateY(0px);
}

.magazines-detail-item:hover .video-detail-cover {
  height: 145px;
}

.megezine-model-wrapper {
  height: 250px;
  border-radius: 14.243px;
}

.megezine-model-final-wrapper {
  border-radius: 15px;
  height: 385px;
}

.about-courses-cetegory h1 {
  color: #303030;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  margin: 20px 0 0 0;
}

.megezines-like-share {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 30px;
  opacity: 1 !important;
}

.megezines-like-share button {
  padding: 0;
  border: none;
}

button.follow-btn {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #001c93;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.megezines-profile-name p {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.megezines-profile-name .designation {
  opacity: 0.6;
  font-weight: 400;
}

.megezines-profile-pic {
  width: 63px;
  height: 63px;
  border-radius: 22.05px;
  box-shadow: 0px 0px 5.492px 0px rgba(0, 0, 0, 0.15);
}

.megazines-profile-detail {
  display: flex;
  align-items: center;
  column-gap: 18px;
}

.megazines-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.shots-item {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 464px;
  border-radius: 15px;
  position: relative;
}

.shots-item::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
  opacity: 0.6;
  background: #e0e0e0;
}

.shots-overview {
  grid-template-columns: repeat(auto-fit, minmax(0, 245px));
  display: grid;
  gap: 42px;
}

.shots-item:hover .image-buttons {
  display: flex;
  top: 10px;
  right: 10px;
}

.shots-item .shots-profile {
  border-radius: 34px;
  border: 1.755px solid var(--white, #fff);
  box-shadow: 0px 0px 8.5px 0px rgba(0, 0, 0, 0.15);
  height: 86px;
  width: 86px;
}

/* .shots-item .button1 {
  border: none;
  background: transparent;
  padding: 0;
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 999;
} */

.shots-item h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 12px;
}

.shots-item span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.shots-badge {
  width: 40px;
  height: 35px;
  border-radius: 11px 0 15px 0px;
  background: #fff;
  padding: 6px 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.badge-picture {
  width: 97%;
  height: 108%;
}

.shots-badge-alt {
  width: 45px;
  height: 70px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 11px 0 15px 0px;
  z-index: 1;
  padding: 6px 8px;
  justify-items: center;
}

.badge-picture-alt {
  width: 84%;
  height: 46%;
  margin-bottom: 7px;
}

.shots-basic-detail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: max-content;
}

.add-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 15px 22px 22px 22px;
  column-gap: 39px;
}

.add-tags ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 15px 0 0 0;
  gap: 20px;
}

.add-tags ul li {
  position: relative;
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 19px;
  background: #f6f6f6;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.add-tag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 12px 15px;
  column-gap: 10px;
}
.add-tag span {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
}

.add-tag ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-bottom: 0 0 0 0;
  /* margin: auto; */
  gap: 20px;
}

.add-tag ul li {
  position: relative;
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 19px;
  background: #f6f6f6;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.add-tag-btn {
  border-radius: 19px !important;
  background: #f6f6f6 !important;
  padding: 12px 15px !important;
  /* border: none !important; */
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: rgba(141, 141, 141, 0.8);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border: 1.8px solid #00298fc4 !important;
  /* margin-top: 15px; */
  height: 40px;
}

.add-tag-btns {
  border-radius: 19px !important;
  background: #f6f6f6 !important;
  padding: 10px 15px !important;
  border: none !important;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: rgba(141, 141, 141, 0.8);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 15px; */
  height: 40px;
}

.add-tag-btn .select-plat {
  /* background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important; */
  color: #303030 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.dropdown-content label {
}

.remove-tag {
  border: none !important;
  padding: 0 !important;
  width: 16px;
  height: 16px;
  background: transparent;
  position: relative;
  z-index: 1;
}

.remove-tag img {
  width: 100%;
  height: 100%;
  display: block;
}

.edit-tag {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 19px;
  opacity: 0;
}

.add-tag ul li.tag-item {
  position: relative;
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 19px;
  background: #f6f6f6;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-height: 40px;
}

.add-tag ul li.editing {
  background: none;
  padding: 0;
}

.add-tag ul li .tag-edit-container {
  position: relative;
  flex-grow: 1;
}

.add-tag ul li .edit-input {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  border-radius: 19px;
  background: #f6f6f6;
  padding: 10px;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0px !important;
}

.add-tag ul li .cancel-edit {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.column-gap-20 {
  column-gap: 20px;
}

.shots-stories video {
  /* height: 464px; */
  border-radius: 10px;
  object-fit: cover;
}

.shots-stories {
  border-radius: 13.605px;
  background: #303030;
  box-shadow: 0px 0px 1.759px 0px rgba(82, 106, 103, 0.19);
  padding: 10px 0;
  height: 490px;
}

.product-detail h2 {
  color: #303030;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.product-seller-detail {
  color: rgb(48 48 48 / 60%);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 7px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(141 141 141 / 60%);
  margin-bottom: 15px;
}

.product-seller-logo {
  width: 29px;
  height: 29px;
  border-radius: 6px;
}

.product-type {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(141 141 141 / 60%);
  margin-bottom: 15px;
}

.product-type span {
  color: rgb(48 48 48 / 60%);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.product-specification h5 {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.product-specification p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.product-img-item {
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  width: 90px;
  height: 85px;
  position: relative;
}

.product-img-selected {
  width: 268px;
  height: 275px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}

.product-img-preview {
  display: flex;
  column-gap: 20px;
}

.product-img-table {
  row-gap: 10px;
  display: flex;
  flex-direction: column;
}

.product-img-selected img {
  border-radius: 15px;
}

.product-img-item.false:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
  background: rgb(175 175 175 / 60%);
}

.discussion-preview {
  display: flex;
  column-gap: 15px;
  border-radius: 15px;
  background: #fff;
  padding: 30px 20px;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  margin-top: 10px;
}

.discussion-img img {
  min-width: 77px;
  width: 77px;
  height: 77px;
  border-radius: 29.615px;
  border: 1.974px solid #00c3a7;
  box-shadow: 0px 0px 3.949px 0px rgba(0, 0, 0, 0.15);
}

.discussion-preview-detail h6 {
  color: #303030;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.discussion-preview-detail p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: left;
}

.first-row-award {
  border-radius: 15px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  box-shadow: 0px 4px 15px 0px rgba(82, 106, 103, 0.19);
}

.award-second img,
.award-three img {
  width: 100px;
  height: 100px;
  border-radius: 40px;
  border: 2px solid #fff;
  box-shadow: 0px 0px 10.098px 0px rgba(0, 0, 0, 0.15);
}

.award-first img {
  width: 150px;
  height: 150px;
  border-radius: 58px;
  border: 2px solid #fff;
  box-shadow: 0px 0px 10.098px 0px rgba(0, 0, 0, 0.15);
}

.award-winner {
  position: relative;
}

.award-second-badge,
.award-first-badge,
.award-three-badge {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -4px;
  bottom: -8px;
}

.award-second-badge {
  background: linear-gradient(
    150deg,
    #ff0067 97149.48%,
    #f04 98144.81%,
    #ff0013 99704.17%,
    #f00 100434.08%
  );
  color: #fff;
}

.award-first-badge {
  background: linear-gradient(180deg, #ffd739 0%, #f8c600 99.99%, #ffd739 100%);
  color: #303030;
  left: 0px;
  bottom: -2px;
}

.award-three-badge {
  background: linear-gradient(
    150deg,
    #0ff 202406.52%,
    #0ddeff 203546.42%,
    #1fadff 205393.85%,
    #269aff 206337.22%
  );
  color: #303030;
}

.award-winner-name {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 5px;
  padding-top: 18px;
}

.award-first .award-winner-name {
  font-size: 18px;
}

.award-first {
  margin-bottom: 8px;
}

.winning-percentage {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}

.first-row-award {
  display: flex;
  column-gap: 120px;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding: 109px 0;
}

.second-row-award {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 30px;
}

.second-row-award h4 {
  -webkit-text-fill-color: #303030;
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
  margin-top: 0;
}

.award-second-row-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.second-row-award-detail {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.award-second-row-data {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  column-gap: 30px;
  row-gap: 25px;
}

.second-row-award-badge {
  color: #fff;
  font-size: 12.308px;
  font-weight: 500;
  line-height: normal;
  border-radius: 2.133px;
  opacity: 0.9;
  background: linear-gradient(120deg, #00cba4 0%, #00917e 90.19%);
  box-shadow: 0px 0px 5.731px 0px rgba(82, 106, 103, 0.19);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-row-award-profile {
  width: 41px;
  height: 41px;
}

.second-row-award-profile img {
  border-radius: 11.172px;
  width: 100%;
  height: 100%;
}

.second-row-award-profile-name {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.second-row-award-percentage {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.preview-model-cources .add-item-content.award-publish-content {
  /* padding: 40px 70px; */
  height: 700px;
  width: 1300px;
  border-radius: 15px;
}

.item-details-input .profress-line {
  border: 1.8px solid rgb(0 203 164 / 20%);
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  overflow: hidden;
}

.item-details-input .profress-line div {
  border-radius: 15px 0 0 15px !important;
  justify-content: flex-start !important;
}

.polls-option input {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  border: 1.6px solid #00cba4;
}

.polls-option input:checked {
  background: linear-gradient(120deg, #00cba4 0%, #00917e 90.19%);
}

.polls-option input[type="checkbox"]:before {
  width: 9px;
  height: 9px;
}

.item-details-input .polls-option {
  column-gap: 10px;
}

.item-details-input .profress-line {
  width: 100%;
}

.polls-label-publish {
  border-radius: 16px;
  background: #b7e5dc;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  display: inline-block;
}

.polls-label-publish span {
  background: linear-gradient(120deg, #00cba4 0%, #00917e 90.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.polls-text-publish {
  color: #303030;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-bottom: 20px;
}

.polls-publish-detail {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 30px;
}

.styles_main__-0FEu {
  border-radius: 10px;
  background-color: #fff !important;
  margin: auto;
}

.shots-stories .Video-styles_soundIcon__ZvYXE {
  padding: 11px 15px 0 0;
  display: none;
}

.shots-stories-react {
  width: fit-content;
  position: relative;
  margin: auto;
  height: 452px;
}

.shots-control {
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: absolute;
  top: 11px;
  right: 15px;
  z-index: 1;
}

.shots-control1 {
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: absolute;
  top: 14px;
  right: 17%;
  z-index: 1;
}

.shots-volume-control,
.shots-play-control {
  cursor: pointer;
}

.edit-button-model.content-meneger-edit {
  width: 30px;
  height: 30px;
}

.add-item-model button.edit-button-model.content-meneger-edit {
  top: -2px !important;
  right: -8px !important;
  position: absolute;
}

.upload-input button:hover {
  background-color: rgb(235, 235, 236);
}

.image_view img {
  object-fit: contain;
  /* width: 720px !important; */
  height: 320px !important;
  margin-top: 5px;
  border-radius: 10px;
}
.image_view {
  position: relative;
}

.video_mb {
  margin-bottom: 20px;
}

.Podcasts-item-detail {
  position: relative;
}

.Podcasts-item-detail:hover .image-buttons {
  display: flex;
  top: 10px;
  right: 10px;
}

.user_image h6 img {
  width: 21px;
  height: 21px;
}

.user_image h6 span {
  font-size: 8.6px;
  line-height: 10.48px;
  color: #303030;
  margin-left: 12px;
}

@media screen and (max-width: 1400px) {
  .react-tabs__tab img {
    width: 16px;
  }

  .details-tab-view .react-tabs__tab {
    column-gap: 5px;
  }

  .details-tab-view .react-tabs__tab {
    font-size: 12px;
  }

  .content-manager .add-video-row {
    top: 68.69px;
  }
}

.Video-styles_video__BykuO {
  height: 355px;
  width: 226px;
}

.shots_size {
  width: 270px !important;
  height: 464px;
}

.preview_text {
  width: 100%;
  height: 100%;
  border: none;
}

textarea {
  border: none;
  outline: none;
}

.spinner {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.audio-container {
  width: 100%;
  max-width: 600px;
  /* Adjust this to your desired max width */
}

.audio-poster {
  min-width: 196px;
  width: 196px;
  height: 185px;
  border-radius: 10.28px;
}

.item-details-input .button-row-shots {
  display: flex;
  column-gap: 15px;
  padding-top: 60px;
}

.video-player-container {
  position: relative;
  width: 100%;
  max-width: 800px;
}

.video-player {
  width: 100%;
  height: auto;
}

.controls {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
}

.controls .control-buttons {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 8px;
}

.volume-slider,
.seek-slider {
  width: 100px;
  margin: 0 8px;
}

.time-display {
  color: #fff;
  margin-right: 8px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  appearance: none;
  border: none;
  background-color: #ddd;
  outline: none;
  margin-top: 8px;
}

.progress-bar::-webkit-progress-bar {
  background-color: #ddd;
}

.progress-bar::-webkit-progress-value {
  background-color: #007bff;
  /* Progress bar color */
}

.items_button:disabled {
  pointer-events: none;
  opacity: 100% !important;
  background: #9e9e9e !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.awards-item-giveaway {
  height: 320px;
  width: 349px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
  position: relative;
}

.awards-item-giveaway:hover .image-buttons {
  display: flex;
  top: 10px;
  right: 10px;
}

.awards-item-award {
  /* height: 320px;
    width: 349px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
  position: relative;
}

.awards-item-award:hover .image-buttons {
  display: flex;
  top: 10px;
  right: 10px;
}

.awards-item-awards {
  height: 320px;
  /* width: 334px; */
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 10.746px 0px rgba(82, 106, 103, 0.19);
  position: relative;
}

.ant-design {
  padding: 0px;
  border: none;
  outline: none;
}

.ant-picker-outlined:focus-within {
  border-color: transparent;
  outline: none;
  box-shadow: none;
  border-radius: 16px;
}

.ant-design .ant-picker {
  all: unset;
  display: inline-block;
  cursor: text;
  padding: 0px;
}

.ant-design .ant-picker-input {
  all: unset;
  display: inline-flex;
  align-items: center;
  margin: 0px;
  width: 100%;
  height: 48.8px;
  outline: none;
}

.ant-design .ant-picker-input input {
  all: unset;
  display: inline-flex;
  align-items: center;
  margin-top: 24px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
  padding: 16px 20px;
  width: 100%;
  font-family: inherit;
  margin-bottom: 20px;
}

.cooking-time-filed .placeholder-style .ant-picker-input input::placeholder {
  color: #a0a0a0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17.07px !important;
  font-family: Montserrat !important;
}

.ant-design .ant-picker-clear {
  display: none;
}

.ant-design .ant-picker-outlined:focus-within {
  all: unset;
  display: inline-flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  color: #303030;
  font-size: 14px;
}

.ant-picker-ok {
  display: none !important;
}

.text-cancel-btn {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.comment-section {
  width: 100%;
  max-width: 600px;
  /* margin: 20px auto; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comments-list {
  list-style-type: none;
  padding: 0;
}

.comment-item {
  /* margin-bottom: 10px; */
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.comment-item > div {
  margin-bottom: 5px;
}

.replies-list {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
}

.reply-item {
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.add-reply {
  /* margin-top: 5px; */
  /* padding: 5px; */
  /* border-radius: 5px; */
  /* background-color: #f9f9f9; */
}

.add-reply input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-right: 5px;
  border: none;
  border-bottom: 1px solid #001597;
  color: #001597;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  padding-right: 46px;
}

.add-reply button {
  color: #001597;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  background: transparent;
  z-index: 1;
}

.add-comment {
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  /* background-color: #f9f9f9; */
  position: relative;
}

.add-comment input[type="text"] {
  width: 100%;
  padding-right: 34px;
  /* margin-right: 5px; */
  border: none;
  border-bottom: 1px solid #001597;
  color: #001597;
  border-radius: 0;
}

.add-comment button {
  background-color: transparent;
  color: #001597;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  z-index: 1;
  right: 0;
}

button {
  cursor: pointer;
}

.add-item-model .upload_photo {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.38px;
}

.add-item-model .drag_drop {
  color: #8d8d8d;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  margin-bottom: 30px;
}

.community-margin {
  margin-bottom: 40px;
}

.option_edit_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116852)'%3E%3Cpath d='M3 18.1371V21.8871H6.75L17.81 10.8271L14.06 7.07708L3 18.1371ZM20.71 7.92708C21.1 7.53708 21.1 6.90708 20.71 6.51708L18.37 4.17708C17.98 3.78708 17.35 3.78708 16.96 4.17708L15.13 6.00708L18.88 9.75708L20.71 7.92708Z' fill='url(%23paint0_linear_9174_116852)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116852' x1='7.82429' y1='0.0441742' x2='24.9414' y2='16.7471' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116852'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.podcast-detail h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.list_data .list_table {
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.list_table .number_index {
  min-height: 20px;
  width: 36px;
}

.list_table .profile-pic {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}

.list_table .user-name {
  width: 100%;
}

.list_table .time-date {
  width: 100%;
}

.discussions-wrapper-header .common-contnt-wrapper .link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.link_view a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.link_view a {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  border-radius: 16px;
}

.add-item-model .add_btn {
  width: fit-content;
}

.add-item-model .podcast-modal-height {
  height: 550px !important;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  bottom: 10px;
  gap: 5px;
}

.pagination-containers-list-comment {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  bottom: 10px;
  gap: 5px;
  margin-right: 20px;
}

.pagination-button-comment {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border-radius: 16px;
  border: 1px solid #001597;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  padding: 14px 22px;
}

.pagination-buttons-comment {
  background-color: #8d8d8d;
  width: 45px;
  color: #fff;
  font-size: 16px;
  border: none;
  font-weight: 600;
  padding: 10px 16px;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-buttons-comment:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
}

.pagination-button-comment:hover {
  background: linear-gradient(
    294.96deg,
    #001597 10.62%,
    #01104d 52.16%,
    #020c1c 83.31%,
    #020b09 98.68%
  );
  cursor: pointer;
}

.pagination-buttons-comment.active {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.pagination-button-comment.disabled {
  pointer-events: none;
  background-color: #8d8d8d;
  /* opacity: 0.6; */
}

.pagination-containers-list-comment button {
  margin-top: 10px;
  border-radius: 16px;
  border: 2px solid #001597;
  padding: 9px 12px;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
}

.content-video-title {
  /* min-width: 200px; */
}

.content-manager-yearly .dropdown__text:after {
  margin-left: -8px;
}

.content-manager-yearly .dropdown__text {
  margin-left: -4px;
  font-size: 16px;
  font-weight: 500;
}

.view-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 10px 10.522px -9px rgba(0, 28, 147, 0.13);
}

.poll_topic {
  width: 664px;
}

.component_icon {
  width: 24px;
  height: 24px;
}

.content-filter .dropdown__text {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center !important;
  column-gap: 5px !important;
  -webkit-text-fill-color: #01104d;
}

.content-filter {
  padding: 12px 22px !important;
}

.profile-type-dropdown.disabledDropdown {
  pointer-events: none;
  opacity: 0.5;
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.disabledDropdown {
  pointer-events: none;
  opacity: 0.5;
  background-color: #e0e0e0;
  color: #e6e6e6;
  cursor: not-allowed;
}

.content-manager-year.disabledDropdown {
  pointer-events: none;
  opacity: 0.5;
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.podcast-skeleton-height {
  min-height: 0px !important;
}

.polls-option .count-vote-poll-skeleton {
  display: block;
}
.user-profile-crop {
  display: flex;
  justify-content: space-between;
}

/* .ant-upload-wrapper {
  width: 170px !important;
  height: 170px !important;
  margin: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  ) !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15) !important;
  position: relative !important;
} */

.ant-upload-select {
  align-content: center;
  text-align: center;
}
.ant-upload-select .ant-upload {
  border: none !important;
}

.upload_btn_width {
  width: 45% !important;
  border: 1.8px solid #001b5de0 !important;
}

.cropper-user h6 {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: center;
  color: #9e9e9e;
  margin-top: 10px;
}

.title_views {
  display: flex;
  justify-content: space-between;
}
.title_views .view_count {
  display: flex;
  gap: 5px;
  align-items: center;
}
.view_count h6 {
  margin-bottom: 0px !important;
}

.otp_redirect_login {
  text-align: center;
  color: white;
}
.otp_redirect_login span:hover {
  cursor: pointer;
}

.cropper-user .default {
  /* width: 100px; */
  /* width: 81px;
  margin-top: 18px;
  margin-left: 9px; */
  width: 82px;
  margin-top: 18px;
  margin-left: 6px;
  height: 78px;
}

.profile-upload .Profile_img {
  width: 100px;
  height: 95px;
  border-radius: 50%;
  object-fit: cover;
}

.comment_people {
  gap: 71px;
  display: flex;
}

.platform_dropdown {
  border-radius: 15px !important;
}

.custom-progress {
  margin-top: 20px;
  text-align: center;
}

.custom-progress .ant-progress {
  border-radius: 10px;
}

.podcast-profile-imgs {
  display: flex;
  gap: 15px;
  /* padding: 10px 10px 10px 0px; */
  border: 1px solid #e4e4e4;
  border-radius: 10px;
}

.podcast-profile-imgs span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  color: #212121;
}

.podcast-profile-imgs img {
  width: 41px;
  height: 41px;
  border-radius: 13.42px;
  box-shadow: 0px 0px 3.34px 0px #00000026;
}

.streaming-data-line-right {
  border: 0.61px solid rgba(214, 214, 214, 1);
  margin-top: 8px;
  height: 12px;
}

/* .hight_shots_btn {
  position: fixed;
  margin-top: 150px;
} */

.position {
  position: relative;
}
.position_btn {
  position: absolute;
  bottom: 0;
}

.shots_without_modal {
  height: 570px !important;
}

.video_sticky_btn {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 15px 26px 0px 30px !important;
  z-index: 10;
}

.item-details-input .add_photo {
  padding: 20px 26px 20px 40px !important;
  align-items: center;
}

.upload_height {
  height: 326px !important;
}

.upload_height_local {
  /* height: 280px !important; */
}

.upload_shots_text {
  cursor: pointer;
}

.podcast-profile-detail .video_preview_user_name {
  margin: 0;
  color: #303030;
  font-size: 12.335px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.podcast-profile-detail .video_preview_designation_p {
  font-family: Montserrat;
  font-size: 12.335px;
  font-weight: 400;
  line-height: 11.87px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #303030;
}

.padding_scroll_bottom {
  padding-bottom: 3% !important;
}

.time-picker-disabled {
  pointer-events: none;
  border-radius: 17px;
}

.cooking-time-filed .ant-picker-disabled {
  background-color: rgba(0, 0, 0, 0);
}

.video_cover_btn {
  padding-top: 45px !important;
}

.add_platform_text {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.comment-container {
  padding: 25px 25px !important;
}

.platformtag {
  padding-bottom: 0;
  transition: padding-bottom 0.3s ease;
}

.platform_bottom-padding {
  padding-bottom: 9%;
  transition: padding-bottom 0.3s ease;
}

.video-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
}

.video-content.expanded {
  display: block;
  height: auto;
  overflow: visible;
}

.rating_image_podcast {
  width: auto !important;
  height: auto !important;
}

.width_all {
  width: 43px !important;
}

.video-details {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
}
.video-details {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
}
.video-details .title_profile_podcast {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%;
  font-weight: 600;
  color: #303030 !important;
  font-size: 14px;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #303030 3.03%,
    #303030 47.1%,
    #303030 80.14%,
    #303030 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  display: flex;
}

.video-details.expanded {
  display: block;
  height: auto;
  overflow: visible;
}

.discussion_pre_scroll {
  height: 360px !important;
}

.add-item-discussion {
  height: 550px !important;
}

.delete_modal {
  z-index: 9999;
}

.video_upload_file {
  margin-left: 3px !important;
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
}

.video-wrapper {
  position: relative;
}

.video-element {
  position: relative;
  z-index: 1;
}

.play-overlay,
.replay-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
}

.play-overlay img,
.replay-overlay img {
  width: 50px;
  height: 50px;
}

.custom-video-container {
  position: relative;
  width: 100%;
}

.video-element {
  width: 100%;
  height: auto;
  display: block;
}

.custom-controls {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0px;
  background: rgba(197, 197, 197, 0.7);
  color: #fff;
  z-index: 10;
  border-radius: 0px 0px 8px 8px;
}

.play-time-full button {
  padding: 0 !important;
}

.play-time-full .time {
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 400;
  line-height: 10.39px;
  text-align: left;
  color: #ffffff;
  margin: auto;
}
.play-pause-btn,
.fullscreen-btn {
  background: none !important;
  border: none !important;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.play_time_full {
  height: 35px !important;
  display: flex;
  align-items: center;
}

.timeline {
  flex: 1;
  height: 5px;
  background: #fff;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
}

.progress {
  height: 100% !important;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  width: 0%;
}

.progress-dot {
  position: absolute;
  top: -4px;
  width: 15px;
  height: 15px;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  border: 2px solid white;
  border-radius: 50%;
  pointer-events: none;
}

.mute-unmute-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border: none !important;
}

.play_icon {
  display: flex;
  justify-content: space-between;
  padding: 5px 12px;
}

.play_screen {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.giveaway_image_section {
  gap: 24px !important;
  margin: 4px !important;
  margin-top: 8px !important;
}

.shots_tags_without_shots {
  max-height: 105px;
  overflow-y: auto;
  padding-right: 10px;
}

.shots_tags {
  max-height: 310px;
  padding-bottom: 10px;
  overflow-y: auto;
  padding-right: 10px;
  max-width: 100%;
  overflow-x: hidden;
}

.shots_tags::-webkit-scrollbar {
  width: 8px;
}

.shots_tags::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.shots_tags::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* .timeline {
  position: relative;
  height: 8px;
  background: #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
} */

.shot_home_page_tag {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
}

/* new design css */

.sidebar_text {
  font-family: Montserrat;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  line-height: 48.76px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: transparent;
  background: var(--primary-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stay_login {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dont_account {
  background: #505050;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  line-height: 17.07px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sing_up_text {
  font-family: Montserrat;
  font-size: var(--TitleSmallSize);
  font-style: italic;
  font-weight: 800;
  line-height: 17.07px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background: linear-gradient(
    134.3deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login_button {
  max-width: 550px !important;
}

.login_sign_text {
  font-family: Montserrat;
  font-size: 30px !important;
  font-style: italic;
  font-weight: 900 !important;
  line-height: 43.88px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151515;
}

.login-option {
  border: 1.8px solid #ddd;
  border-radius: 20px;
  height: 120px;
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px !important;
  background: linear-gradient(127.03deg, #FFFFFF 37.51%, #C7D5E0 148.47%);
  cursor: pointer;
}

.login-type-active {
  border: 1.8px solid #01104d;
}

.login_as{
color: #303030;
font-size: 20px;
font-family: Montserrat;
font-weight: 500;
line-height: 24px;
margin-bottom: 10px;
}
.login-option p {
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 19.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0b0a0a;
}
.login-option img {
  width: 64px;
  height: 64px;
}


.custom-select .ant-select-selector {
  border-radius: 20px;
}

.statistic-chart-container .discount-date .react-datepicker__input-container input{
  text-align: start;
  }
  .statistic-chart-container .discount-date .datepicker-button{
    padding: 10px;
    height: auto;
    line-height: 1;
  }
.auth-register.business-register .custom-select input,
.auth-register.business-register .custom-country-select input {
  padding-left: 0px !important;
}

.custom-country-select .ant-select-selector {
  border-radius: 50px;
  background: var(--background) !important;
  border-width: 0px !important;
}

