.review-slider-container {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    height: 294px;
    color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-slider-container p {
    max-width: 430px;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0;
    display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.review-slider-container span {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
}

.review-slider-container img {
    width: 600px;
    height: 100%;
    object-fit: cover;
}

.review-cover-img {
    width: 600px;
    height: 294px;
    clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0% 100%);
    border-radius: 0 15px 15px 0;
}
@media only screen and (max-width: 1300px) {
    .review-cover-img {
        width: 400px;
    }
  }

.review-text {
    padding-left: 45px;
}

.recent-reviews .dropdown {
    border: 1px solid #001597;
}

.review-profile-information img {
    border-radius: 30px;
    border: 2px solid #001597;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    width: 78px;
    height: 78px;
    object-fit: cover;
}

.review-profile-information ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.review-profile-information ul li {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.review-date {
    color: #8D8D8D;
    font-weight: 400 !important;
    opacity: 0.6;
}

.review-profile-information p {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding-top: 6px;
    margin: 0;
}