.recent-hotel-grid .like {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    left: 13px;
    top: 13px;
    background: #fff;
    border-radius: 6px;
}

.recent-hotel-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 24px;
}

.recent-hotel-cover {
    height: 190px;
    border-radius: 15px 15px 0 15px;
    position: relative;
}

.recent-hotel-cover::after {
    border-radius: 15px 15px 0 15px;
    background: linear-gradient(134deg, rgba(0, 0, 0, 0.22) 33.04%, rgba(0, 0, 0, 0.00) 55.18%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.recent-hotel-cover .rating,
.recent-event-cover .rating {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    border-radius: 15px 0 0 0;
}

.hotel--grid-item,
.recent-event-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
}

.hotel--grid-item ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.hotel--grid-item li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hotel--grid-item li span {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
    width: calc(100% - 44px);
}

.hotel--grid-item h5 {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 10px;
}

.price--type,
.event--name--date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price--detail {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.type-detail {
    color: #474747;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}

.recent-hotel-detail {
    padding: 13px 10px 15px 10px;
}

.rooms--cover {
    height: 163px;
}

.name--detail {
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.review--detail {
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.rooms-detail li {
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    justify-content: flex-start;
    column-gap: 5px;
}

.eating--drinks {
    border-radius: 15px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    color: #fff;
}

.eating--drinks h4 {
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    margin: 0;
}

.eating--drinks p {
    font-size: 18px;
    font-weight: 500;
    line-height: 141.9%;
    max-width: 497px;
    margin: 10px 0 35px 0;
}

.p-40 {
    padding: 40px;
}

.rounded-15 {
    border-radius: 15px;
}

.recent-event-cover {
    height: 238px;
    border-radius: 15px;
    position: relative;
}

.recent-event-cover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background: linear-gradient(134deg, rgba(0, 0, 0, 0.22) 33.04%, rgba(0, 0, 0, 0.00) 55.18%);
    border-radius: 15px;
}

.recent-event-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 28px;
}

.event--name {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
}

.event-date {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.event-location {
    margin: 8px 0 0 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.event-location span {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.8;
}

.recent-event-detail {
    padding: 10px 20px;
}

.slider--local {
    height: 395px;
    display: flex;
    border-radius: 15px;
}

.slider--local-detail {
    width: 55%;
    padding: 37px 15px 0 90px;
}

.slider--local-image {
    width: 45%;
    height: 100%;
    border-radius: 0 15px 15px 0;
    overflow: hidden;
    clip-path: ellipse(64% 82% at 65% 50%);
}

.slider--local--img {
    height: 100%;
}

.rounded-50 {}

.border-50 {}

.slider--local h4 {
    color: #FFF;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 13px;
}

.slider--local p {
    max-width: 473px;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 36px;
}

.offer-ellips {
    width: 143px;
    height: 143px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-30deg);
    position: absolute;
    top: 100%;
    right: 0px;
}

.offer-ellips p span {
    background: linear-gradient(150deg, #FF0067 97149.48%, #F04 98144.81%, #FF0013 99704.17%, #F00 100434.08%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 28.652px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-right: 5px;
    position: relative;
    top: 5px;
    line-height: 55px;
}

.offer-ellips p {
    color: #F4434F;
    text-align: center;
    font-size: 17.191px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    position: relative;
    bottom: 10px;
}

.slider-local-- .swiper-button-prev {
    left: 15px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.4643' fill='white' stroke='white' stroke-width='1.07143'/%3E%3Cpath d='M24.9983 29.5219L15.7656 19.7638L24.9983 10' stroke='url(%23paint0_linear_3703_119025)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3703_119025' x1='18.2398' y1='5.83546' x2='32.3682' y2='12.3557' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.slider-local-- .swiper-button-next {
    right: 15px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Ccircle cx='20' cy='20' r='19.4643' transform='matrix(-1 0 0 1 40 0)' fill='white' stroke='white' stroke-width='1.07143'/%3E%3Cpath d='M15.0017 29.5219L24.2344 19.7638L15.0017 10' stroke='url(%23paint0_linear_3703_119028)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3703_119028' x1='21.7602' y1='5.83546' x2='7.63179' y2='12.3557' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}
.slider-local--  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 1;
    background: #fff;
    display: inline-block;
}
.slider-local--  .swiper-pagination-bullet-active {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    opacity: 1;
}

.services-container .gellery-item .overlay-gredient {
    opacity: 0;
}

.services-container .gellery-item img {
    object-fit: cover;
}

.show-photos {
    background: #fff;
    border: none;
    border-radius: 16px;
    padding: 14px 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    position: absolute;
    bottom: 40px;
    right: 40px;
}

.show-photos span {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}

.hotel--image {
    width: 127px;
    height: 127px;
    border-radius: 15px;
    border: 0.637px solid #8D8D8D;
}

.hotel--type {
    position: absolute;
    color: #FFF;
    border-radius: 15px;
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    font-size: 15.279px;
    padding: 2.546px 6.366px;
    font-weight: 500;
    line-height: normal;
    bottom: 0;
    right: 0;
}

.hotel--name h4 {
    color: #303030;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.hotel--location {
    color: #303030cc;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    column-gap: 20px;
    align-items: center;
    margin: 20px 0 17px 0;
}

.hotel--name ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.hotel--name ul li {
    color: #303030cc;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.divider {
    opacity: 0.3;
    background: #333;
    width: 1.273px;
    height: 26px;
}

.hotel--price span {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
}

.hotel--price p {
    color: #303030;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.hotel--list--item {
    display: flex;
    padding: 25px;
    border-radius: 19.099px;
    background: #FFF;
    box-shadow: 0px 0px 19.099px 0px rgba(82, 106, 103, 0.19);
    margin-top: 30px;
}
.main-heading{
font-size: 18px;
font-weight: 700;
line-height: normal;
background: linear-gradient(180deg, #FFD739 0%, #F8C600 99.99%, #FFD739 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}