.podcast-video-container{
    border-radius: 15px;
    height: 426px;
    position: relative;
}
.podcast-video-container::after{
    background: linear-gradient(90deg, #000 1.86%, rgba(0, 0, 0, 0.87) 28.85%, rgba(91, 91, 91, 0.00) 99.08%);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.podcast-video-title h4{
    color: #FFF;
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
}
.podcast-video-title p{
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    max-width: 468px;
    margin: 15px 0 30px 0;
}
.podcast-video-title button{
    display: flex;
    align-items: center;
    column-gap: 6px;
}
.podcast-video-title{
    position: absolute;
    z-index: 1;
    left: 30px;
    top: 50%;
    transform: translate(0%, -50%);
}