.rating-container h4{
    color:  #303030;
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}
.total-rating-value{
    color: #1A1A1A;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 0;
    display: block;
}
.rating-progress-container{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.rating-column{
    display: flex;
    flex-direction: column-reverse;
    row-gap: 15px;
}