.profile-left-wrapper {
  margin-left: 238px;
  padding-top: 86px;
}
.create-profile {
  padding: 0px 30px 30px 30px;
}
/* .create-profile h1{
    color:  #303030;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}
.create-profile .sub-text-1{
    color: #303030;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
} */

.create-profile .sub-text-2 {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.7px;
  margin-bottom: 25px;
}
.create-profile .sub-text-3 {
  color: grey;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  margin-bottom: 25px;
}

.create-profile .sub-text {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}

.step-1 {
  display: grid;
  grid-template-columns: repeat(2, 538px);
  column-gap: 30px;
  row-gap: 15px;
}

.step-1 .selectable {
  cursor: pointer;
  border: 1px solid var(--Border-Light, #e4e4e4);
}

.step-1 .selected {
  border: 3px solid transparent;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}

.step-1 .selected::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border: 3px solid transparent;
  background: linear-gradient(white, white),
    linear-gradient(
      174.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: -1;
  border-radius: 23px;
}
.step-2 .selectable {
  cursor: pointer;
  border: 1px solid var(--Border-Light, #e4e4e4);
}

.step-2 .selected {
  border: 1px solid var(--Border-Light, #01104d);
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 20px;
}

.step-2 .selected::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border: 3px solid transparent;
  background: linear-gradient(white, white),
    linear-gradient(
      174.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: -1;
  border-radius: 23px;
}

.step-4 .selected {
  border: 1px solid var(--Border-Light, #01104d);
}

.selectable {
  cursor: pointer;
  border: 1px solid var(--Border-Light, #e4e4e4);
}

/* .selected {
  border: 0px solid transparent;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 20px;
}

.selected::before {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border: 3px solid transparent;
  background: linear-gradient(white, white),
    linear-gradient(
      174.3deg,
      #001597 3.03%,
      #01104d 47.1%,
      #020c1c 80.14%,
      #020b09 96.45%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: -1;
  border-radius: 23px;
} */
/* .step-1 h3{
    color:  #303030;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.step-1 p{
    color: #303030;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin: 0;

} */
.step-1 > div {
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.07));
  background: #fff;
  padding: 30px;
  column-gap: 30px;
  border-radius: 23px;
}
.step-2 {
  display: grid;
  grid-template-columns: repeat(4, 262px);
  gap: 20px;
}
.profile-checkbox {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.create_profile_check {
  height: 122px !important;
}
.profile-checkbox p {
  padding-top: 12px;
  margin-bottom: 0;
  /* color:#303030; */
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  background: rgba(48, 48, 48, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.info-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.custom-check-box {
  position: absolute;
  top: 10px;
  left: 10px;
}
.use-profile {
  display: flex;
  column-gap: 20px;
  margin-bottom: 40px;
}
.company-profile {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
}
.company-profile img {
  max-width: 80%;
}
.type-profile {
  width: calc(100% - 240px);
}
.type-profile textarea {
  height: 100px;
  border-radius: 16px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
  border: none;
  padding: 16px 20px;
  outline: none;
  width: 100%;
}

.step-3 .profile-image {
  width: 100px;
  height: 100px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  position: relative;
  border: 2px dashed #00000026;
}
.step-3 .profile-image input {
  visibility: hidden;
  width: 0;
  height: 0;
}
.step-3 .profile-image label {
  position: absolute;
  bottom: -12px;
  right: -12px;
  cursor: pointer;
}
.step-4 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 350px));
  gap: 28px;
  /* margin-top: 25px; */
}
.add-ons {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-row {
  display: flex;
  text-align: left;
  padding: 20px;
  column-gap: 20px;
}
.add-ons-main-ing {
  padding: 55px 6px 30px 6px;
}
.add-ons-main-ing img {
  /* max-width: 100%;
    max-height: 100%; */
  /* object-fit: contain; */
  margin: auto;
  height: 165px;
  width: 280px;
}
.install_addOns_img {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain !important;
  height: 75px !important;
  width: 75px !important;
}
.add-ons-main-ing .add_ons_first {
  width: 327px;
  height: 207px;
  object-fit: contain;
}
.footer-row {
  border-radius: 0px 0px 15px 15px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
}
.images-block {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #fff;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07); */
}
.images-block .icon_img {
  width: auto;
  height: 50px;
}

.app_icon_img {
  width: 58px;
  height: 58px;
}
.add-ons-detail {
  width: calc(100% - 116px);
}
.add-ons-detail h4 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}
.add-ons-detail p {
  color: #8d8d8d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.custome-checkbox {
  position: absolute;
  top: 20px;
  left: 20px;
}
.steps-button {
  display: flex;
  column-gap: 20px;
  justify-content: flex-end;
  margin-top: 30px;
}
.case {
  margin-top: 110px !important;
}
.steps-button button {
  border-radius: 16px;
  height: 48px;
  width: auto;
  padding: 14px 22px;
}
.steps-button button span {
  line-height: 16px;
}
.steps-button .btn-prev {
  border: 2px solid #001597;
  position: absolute;
  right: 140px;
  bottom: 20px;
  border-radius: 30px;
}
.steps-button .btn-prev-case3 {
  border: 2px solid #001597;
  border-radius: 30px;
}
.steps-button .btn-prev-case4 {
  border: 2px solid #001597;
  border-radius: 30px;
}
.steps-button .btn-next-case3 {
  border-radius: 30px;
}
.steps-button .btn-next-case4 {
  border-radius: 30px;
}

.steps-button .btn-next {
  position: absolute;
  right: 40px;
  bottom: 20px;
  border-radius: 30px;
}
.selected-form label {
  display: block;
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 9px;
}
.selected-form input,
.selected-form textarea {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07);
  height: 50px;
  width: 100%;
  color: #8d8d8d;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  opacity: 0.6;
  border: none;
  padding-left: 20px;
}
.selected-form textarea {
  min-height: 120px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 20px;
}
.social-media-link-input .secondary-button,
.expert-verification .secondary-button {
  width: auto;
  padding: 14px 22px;
  border-radius: 16px;
}
.social-media-link-input .secondary-button span {
  font-weight: 600;
}
.input-with-button {
  column-gap: 20px;
}
.organization-verification .custome-select .dropdown {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.organization-verification .custome-select .dropdown .dropdown__text {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background: none;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #8d8d8d;
  opacity: 0.6;
}
.selected-form {
  min-height: calc(100vh - 160px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.input-img-label {
  /* position: relative; */
}
.input-img-label label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 104px;
  border-radius: 15px;
  background: #fff;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.07); */
  padding: 0;
  cursor: pointer;
}
.input-img-label input {
  visibility: hidden;
  height: 0;
  width: 0;
}
.input-image-show .image {
  width: 115px;
  height: 104px;
  border-radius: 15px;
  box-shadow: 0px 0px 8px 0px rgba(1, 18, 104, 0.2);
}
.input-image-show {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  margin-top: 10px;
}
.profile-pic-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #001597;
  position: relative;
  margin: auto;
}
.profile-pic-images {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #032150;
  position: relative;
  margin: auto;
}

.profile-pic-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-pic-images img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
}
.profile-select-label {
  position: absolute;
  right: 0;
  bottom: 0;
}
.upload-btn {
  height: 100% !important;
  margin-bottom: 0 !important;
  color: #fff !important;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .step-1 {
    grid-template-columns: repeat(2, 538px);
  }
}
@media screen and (max-width: 1400px) {
  .step-1 {
    display: grid;
    grid-template-columns: repeat(2, 450px);
  }
}
@media screen and (max-width: 1200px) {
  .step-1 {
    display: grid;
    grid-template-columns: repeat(2, 360px);
  }
}
/* @media screen and (max-height:880px) {
    .step-1 {
        column-gap: 10px;
        row-gap: 10px;
    }
    .step-1 p{
        font-size: 12px;
    }
    .step-1>div{
        padding: 13px;
    }
} */
@media screen and (max-height: 700px) {
  .create-profile .sub-text {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .create-profile h1 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  /* .step-1 img {
        width: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
    } */
  /* .step-1>div {
        padding: 8px;
    } */
  .steps-button {
    margin-top: 10px;
  }
  .step-1 h3 {
    font-size: 20px;
  }
}

.socialmedia-select {
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.socialmedia-select .dropdown {
  margin: 0;
  /* width: auto; */
  box-shadow: none;
  padding: 0;
  margin-right: -68px;
  padding-left: 10px;
  width: 84px;
  height: 60px;
}
.socialmedia-select .dropdown .dropdown__text {
  margin-top: 0px;
  margin-right: 8px;
}
.socialmedia-select .dropdown .dropdown__items {
  margin-top: 6px;
}
.socialmedia-select input {
  padding-left: 80px !important;
}
.socialmedia-select .dropdown__item:hover img {
  /* filter: brightness(0) invert(1); */
}
.profile-select-label img:hover {
  cursor: pointer;
}

.addons_name {
  margin-top: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #001597 !important;
}

.socialmedia-select .link_add_create {
}

.socialmedia-select button {
  position: absolute;
  border: none !important;
  background: none !important;
}

.input-and-remove {
  width: 100%;
}

.remove-button {
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  top: 8px;
  right: 15px;
}
.remove-button:hover {
  filter: opacity(75%);
}

.sticky-header {
  position: sticky;
  top: 99px;
  z-index: 1;
  padding: 15px 15px 0 0;
  background-color: #ffff;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 20px 0;
  background-color: #fff;
}

.scroll-bars_create {
  overflow: auto;
  padding: 5px 15px 5px 10px;
  height: calc(100vh - 355px);
}
/* .scroll_bars_create_3{
    overflow: auto;
    height: 430px;
    padding-right: 10px;
} */
.scroll_bars_create_3 {
  overflow: auto;
  /* height: calc(100vh - 299px); */
  height: calc(100vh - 284px);
  padding-right: 10px;
  overflow-x: hidden;
  /* padding-bottom: 20%;  */
}
.scroll_bars_create_3_business {
  overflow: auto;
  height: calc(100vh - 239px);
  padding-right: 10px;
  overflow-x: hidden;
}
.additional-class {
  padding-bottom: 20% !important;
}
.case-4-active {
  padding: 0px 30px 0px 30px;
}

.create_profile_add_link {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}
.create_profile_local_add_link {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
}

.create_profile_link_dropdown .dropdown__item {
  padding: 10px 18px !important;
}

.hover_create:hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
}

.AddOns_li ul li {
  list-style: circle;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #303030;
}


.item-details-input input.profile_input, .item-details-input textarea.profile_input {
  background-color: #ffff !important;
  box-shadow: none !important;
  border: 1px solid #E4E4E4 !important;
}

.create_profile_link_dropdown .dropdown__item_active{
  background: none !important;
}
.create_profile_link_dropdown .dropdown__item:hover{
  background: rgba(0, 0, 0, 0.05) !important;
}
.dropdown.active.create_profile_link_dropdown:before{
  background: none !important;
}

.plus-img .plsimg .plsimg img{
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 13px !important;
}

.profile_create_label{
  color: #0B0A0A;
  font-size: 14px;
  font-weight: 500;
}