.item-details-input-component button {
  border-radius: 16px;
  border: 2px solid #040f5a;
  background: transparent;
  padding: 14px 22px;
}
.item-details-input-component button span {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.item-details-input-component h4 {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 20px;
}
.item-details-input-component p {
  color: #8d8d8d;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}

.item-details-input-component .upload-img {
  border-radius: 16px;
  /* background: #8d8d8d33; */
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.item-details-input-component .upload-img img{
  width: 80px;
  height: 80px;
 
}
.item-details-input-component .upload-img:hover {
  cursor: pointer;
}

.item-details-input-component .upload-input-with-border {
  border-radius: 15px;
  border: none;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(82, 106, 103, 0.19);
  padding: 20px;
  margin-bottom: 0px;
}
.banner-main {
  position: relative;
}
.banner-btn {
  position: absolute;
  display: flex;
  gap: 10px;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.edit-delete {
  box-shadow: 0px 0px 13.93px 0px #526a6730 !important;
  background-color: white !important;
  border-radius: 100%;
  border: none !important;
  padding: 4px !important;
}
.banner-main img {
  width: 100%;
  object-fit: fill;
  border-radius: 15px;
}

.banner-main {
  position: relative;
  overflow: hidden;
}

.banner-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.banner-image {
  width: 1087px;
  height: 292px;
}

.banner-main:hover .banner-btn {
  opacity: 1;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 999;
}

.item-details-input-component button:hover{
  background-color: rgb(235, 235, 236);
}

.image-buttons .banner-delete{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'%3E%3Cg clip-path='url(%23clip0_9174_116856)'%3E%3Cpath d='M6 19.8871C6 20.9871 6.9 21.8871 8 21.8871H16C17.1 21.8871 18 20.9871 18 19.8871V7.88708H6V19.8871ZM19 4.88708H15.5L14.5 3.88708H9.5L8.5 4.88708H5V6.88708H19V4.88708Z' fill='url(%23paint0_linear_9174_116856)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_9174_116856' x1='8.7517' y1='0.04721' x2='25.2404' y2='12.5615' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23001597'/%3E%3Cstop offset='0.4691' stop-color='%2301104D'/%3E%3Cstop offset='0.8208' stop-color='%23020C1C'/%3E%3Cstop offset='0.9944' stop-color='%23020B09'/%3E%3C/linearGradient%3E%3CclipPath id='clip0_9174_116856'%3E%3Crect width='24' height='24' fill='white' transform='translate(0 0.887085)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.banner-height{
  height: 281px;
  padding-top: 32px !important;
}

.item-details-input-component .banner_label .ant-upload-select{
  height: 0px !important;
}