.bg-linier-blue {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    color: #fff;
    border-radius: 15px;
    padding: 40px;
}

.bg-linier-blue-left {
    max-width: calc(100% - 200px);
}

.bg-linier-blue-left h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
    margin-top: 10px;
}

.bg-linier-blue-left p {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    max-width: 692px;
}

.porfolio-head {
    height: 412px;
    border-radius: 15px;
    background: rgb(48 48 48 / 90%);
    color: #fff;
}

.porfolio-head img {
    clip-path: circle(62.0% at 62% 50%);
}

.porfolio-head h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.porfolio-head h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 25px;
}

.porfolio-head p {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    max-width: 501px;
    margin-bottom: 35px;
    display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
@media only screen and (max-width: 1200px) {
    .porfolio-head h1 {
        font-size: 22px;
    }
    
    .porfolio-head h2 {
        font-size: 18px;
    }
    
    .porfolio-head p {
        font-size: 16px;
    }
  }
.potfolio-head-detail {
    padding: 40px;
}

.job-categories-item span.porfolio-service-text {
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}

.project-gellery,
.recent-project-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 34px;
}

.project-item-detail h4 {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 15px;
}

.project-item-detail p {
    color: #8D8D8D;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.job--project-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
}
.project-item-cover{
    height: 192px;
    border-radius: 15px;
}
.project-item-detail{
    padding: 15px 15px 39px 15px;
}
.project-item-cover img{
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.project-item {
    height: 250px;
    border-radius: 15px;
}

.project-item img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}
.fitness-expert-item h4{
    color:  #FFF;
font-size: 18px;
font-weight: 600;
line-height: normal;
margin-bottom: 15px;
}