.sidebar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
}

.custom-sidebar {
    width: 238px;
    /* border-radius: 0px 15px 15px 0px; */
    background: #FFF;
    border-right: 1px solid #E4E4E4 !important;
    /* box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.06); */
    padding: 10px;
    height: 100%;
}

.custom-sidebar .logo {
    padding: 20px 15px 33px 15px;
}

.custom-sidebar ul {
    padding: 0;

}

.main-navigation {
    height: calc(100vh - 140px);
    overflow-y: auto;
}

.main-navigation-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-navigation::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.custom-sidebar ul li a.active .image-block svg {
    filter: brightness(0) invert(1);
}

.cursor-pointer.image-block {
    right: 5px;
}

.custom-sidebar li {
    list-style-type: none;
}

.custom-sidebar li a>div {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    /* padding: 14px 10px 12px 18px; */
    padding: 16px 0px 16px 20px;
    display: flex;
    align-items: center;
    /* background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: #0B0A0A;
}

.image-block {
    margin-right: 14px;
}
.img-width img{
   height: 22px;
   width: 22px;
}

.sidebar-collapse .image-block {
    margin-right: 0px;
}

.saprator {
    height: 1px;
    width: 100%;
    background: #DBDEE4;
    opacity: 0.5;
    margin: 10px 0;
}

.custom-sidebar ul li a>div,
.custom-sidebar ul li a .image-block img {
    transition: all 0.3s ease-in;
}

.custom-sidebar ul li a>div {
    margin-bottom: 5px;
}

.custom-sidebar ul li a.active>div {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    -webkit-text-fill-color: #fff;

}

.custom-sidebar ul li a:hover>div {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    -webkit-text-fill-color: #fff;
}

.custom-sidebar ul li a.active .image-block img {
    filter: brightness(0) invert(1);
    transition: all 0.3s ease-in;
}

.custom-sidebar ul li a:hover .image-block img {
    filter: brightness(0) invert(1);
}

.custom-sidebar ul li a>div {
    border-radius: 50px;
}

.sidebar-link-text {
    display: none;
}

.custom-sidebar.custom-sidebar-builder {
    width: 293px;
    display: flex;
}

.sidebar-collapse {
    width: 92px;
    height: 100%;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
    text-align: center;
}

.sidebar-right-part {
    width: calc(100% - 92px);
    padding: 10px;
}

.custom-sidebar .sidebar-collapse li a>div {
    justify-content: center;
    padding-left: 0;
}

.custom-sidebar .sidebar-right-part li a>div {
    font-size: 14px;
    border-radius: 10.191px;
}

.custom-sidebar .sidebar-right-part li a {
    border-radius: 10.191px;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    display: block;
}

.custom-sidebar .sidebar-right-part li {
    margin-bottom: 7px;
    padding: 0 0px;
}

.sidebar-right-part .dropdown {
    margin-top: 9px;
    margin-bottom: 10px;
    width: 100%;
}

.sidebar-right-part label {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-top: 23px;
}

.links-end {
    height: calc(100vh - 106px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-right-part .dropdown__text {
    /* -webkit-text-fill-color: #8D8D8D; */
}

.sidebar-right-part .dropdown__text.selected {
    -webkit-text-fill-color: #303030;
}

.custom-sidebar li.disableSideMenu a>div {
    -webkit-text-fill-color: #9E9E9E;
}

.custom-sidebar li.disableSideMenu .image-block img {
    filter: invert(71%) sepia(19%) saturate(7%) hue-rotate(321deg) brightness(85%) contrast(91%);
}

.disableSideMenu {
    pointer-events: none;
}

.sidebar-collapse li{
    width: 65px;
    margin: auto;
}
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.home_profile{
    padding-left: 24px !important;
}