.add-giveways-item .add-giveways-cover {
  width: 44%;
  border-radius: 15px 0px 0px 15px;
  background: #e0e0e0;
  position: relative;
  display: flex;
  justify-content: center; 
  align-items: center;  
  border: none;
}

.add-giveways-item .add-giveways-details {
  width: 56%;
  padding: 40px 30px;
}

.add-giveways-item {
  display: flex;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  margin-bottom: 30px;
}

.add-value {
  border-radius: 15px;
  opacity: 0.15;
  background: #303030;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
}

.add-giveways-details h4 {
  width: 155px;
  height: 29px;
  margin-bottom: 20px;
}

.add-giveways-details ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  column-gap: 23px;
}

.add-giveways-details li {
  width: 110px;
  height: 29px;
}

.add-giveways-details span {
  width: 121px;
  height: 29px;
  display: inline-block;
}

.add-giveways-details p {
  height: 110px;
  margin-bottom: 30px;
}

.add-giveways-details h5 {
  width: 149px;
  height: 48px;
  margin-bottom: 0;
}

.add-item-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 35px;
  height: 35px;
  background: #e6e6e6;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
}

.added-giveways-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 13.93px 0px rgba(82, 106, 103, 0.19);
  display: flex;
  margin-bottom: 40px;
  display: flex;
  height: 366px;
}
.giveways-item-cover img {
  border-radius: 15px 0 0 15px;
  width: 100%;
  height: 100%;
}
.giveways-item-details h4 {
  color: #303030;
  font-size: 24px;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.giveways-profile-detail {
  display: flex;
  align-items: center;
  column-gap: 13px;
}
.giveway-time-left {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.profile-img-giveway {
  border-radius: 6px;
  width: 29px;
  height: 29px;
}

.giveways-profile-detail span {
  color: #303030;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.giveways-profile-detail .vertical-devider {
  opacity: 0.4;
  background: #8d8d8d;
  width: 0.5px;
  height: 15px;
}

.giveway-time-left span {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.giveways-item-details p {
  color: #303030;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 30px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 116px;
}
.giveways-item-cover {
  width: 44%;
}
.giveways-item-details {
  width: 56%;
  padding: 35px 30px;
}

.add-giveways-container .added-giveways-item:hover .image-buttons {
  display: flex;
}
.added-giveways-item:hover .image-buttons {
  display: flex;
}

.added-giveways-item:hover .image-buttons-cancel{
  display: flex;
}

@media screen and (max-width: 1400px) {
  .giveways-item-cover {
    width: 34%;
  }
  .giveways-item-details {
    width: 66%;
    padding: 30px 20px;
  }
}

.add-giveways-details h4,
.add-giveways-details h5 {
  font-size: 0;
}

.add-giveways-details h4 *,
.add-giveways-details h5 * {
  font-size: initial;
}

.giveaway_like_data{
 display: flex;
 gap: 10px;
 justify-content: center;
 align-items: center;
 text-align: center;
}

.giveaway_like_data h5{
  text-align: center;
 }

.giveaway_Like{
  border: none;
  background: none;
}

.like_section{
  display: flex;
  width: 50px;
  gap: 4px;
}
.like_section h5{
  width: 10px !important;
  height: 10px !important;
}

/* .dropdown .dropdown__text .giveaway_dropdown{
  -webkit-text-fill-color: #c2c2c2 !important;
  font-weight: 300;
}
.dropdown .dropdown__text.selected {
  -webkit-text-fill-color: #303030 !important;
} */

.giveaway-dropdown .dropdown__text {
  -webkit-text-fill-color: #8D8D8D !important; 
  font-weight: 400 !important;
  background: none !important;
}

.giveaway-dropdown .dropdown__text.selected {
  -webkit-text-fill-color: #303030;
} 
.selected-class .dropdown__text {
  -webkit-text-fill-color: #303030 !important;
} 

.giveaways_text{
  text-align: center; 
  font-size: 16px;
}

.ant-design .ant-picker-input input::placeholder {
  color: #a0a0a0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17.07px !important;
  font-family: Montserrat !important;
}

.ant-design.placeholder-style .ant-picker-input input::placeholder {
  color: #a0a0a0;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.ant-design.has-value .ant-picker-input input {
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.scroll-giveaway-height{
  max-height: 420px !important;
}

.giveaway-close{
  margin-left: 12px !important;
}
.giveways-com-rating{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 8px;
}

.time-picker-disabled {
  pointer-events: none; 
  border-radius: 17px;
}

.cooking-time-filed .ant-picker-disabled{
  background-color: rgba(0, 0, 0, 0);
}