.services-container h3 a {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid #001597;
}

.service-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    gap: 30px;
}

.services-item {
    height: 329px;
    position: relative;
    border-radius: 15px;
}

.services-item::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(4, 4, 4, 0.77) 11.41%, rgba(189, 189, 189, 0.00) 117.66%);
    border-radius: 15px;
}

.services-item h4 {
    color: #FFF;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    z-index: 1;
}