.common-input {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    min-height: 50px;
    width: 100%;
  }
  
  /* Specific to certain inputs */
  .product-name-input {
    text-transform: capitalize;
  }
  
  .price-input {
    background-color: #f9f9f9;
  }
  
  .discount-input {
    background-color: #f0f0f0;
  }
  
  .description_count{
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 12px;;
    color: #aaa;
  }