.feeds-video {
  height: 348px;
  border-radius: 15px;
  position: relative;
}

.feeds-video::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 15px;
}

.feeds-main .play {
  width: 58px;
  height: 58px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feeds-main .play img {
  width: 100%;
}

.feeds-profile-main-profile {
  width: 48px;
  height: 48px;
  position: relative;
  padding: 1.8px;
  z-index: 1;
  border-radius: 20px;
  background: linear-gradient(to right, #001597, #01104D, #020C1C, #020B09);
  background-clip: border-box;
  -webkit-background-clip: padding-box;
}

.feeds-profile-main {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.feeds-profile-main ul {
  padding: 0;
  margin: 0;
}

.feeds-profile-main ul li {
  background: linear-gradient(134.3deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  list-style-type: none;
}

.feeds-profile-main ul li.designation {
  opacity: 0.5;
  font-weight: 600;
}

.feeds-profile {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.feeds-detail h6 {
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 5px;
}

.feeds-detail p {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  max-width: 426px;
}

.feed-btn-group {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.feeds-button button {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(
    134deg,
    #001597 3.03%,
    #01104d 47.1%,
    #020c1c 80.14%,
    #020b09 96.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feeds-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.save-btn{
  width: 76px;
}
.feeds-video {
  margin: 20px 0;
}
.report-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures the modal is on top */
}

.report-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-btn img {
  width: 20px;
  height: 20px;
}

.open_report {
  cursor: pointer;
}

.add-item-model .feed-report {
  background: #fff;
  border-radius: 15px;
  /* padding: 40px; */
  width: 350px !important;
  height: 500px !important;
  overflow-y: scroll;
}

.table_data {
  display: flex;
  max-width: 320px;
}

.table_data span {
  background: #01104d;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 5px;
  cursor: pointer;
}

.table_list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_icons img {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 40%;
}

.add-item-content .feed-scroll{
  padding-bottom: 0%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; 
}

.comment-section.loading {
  height: 130px; 
  display: flex;
  justify-content: center;
  align-items: center;
}


.no-comments-message {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  padding: 10px;
}

.feed-section {
  position: relative;
  height: 150px;
}

.feed-section .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; 
}

.add-comment .comment_add_btn{
  margin-right: 8px;
}