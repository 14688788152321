.our-team-profile {
    border-radius: 15px;
    position: relative;
    padding: 17px 60px 18px 40px;
}

.our-team-profile::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
    opacity: 0.9;
    background: #303030;
}

.z-index-1 {
    z-index: 1;
}

.team-profile-item {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.19);
    text-align: center;
}
.profile-team{
    display: flex;
    align-items: center;
    justify-content: center;
}
.team-profile-item{
    padding: 20px 30px;
}
.team-profile-item:first-child{
    margin-right: -50px;
    width: 211px;
    padding: 15px 25px;
}
.team-profile-item:first-child .profile-img,.team-profile-item:last-child .profile-img{
    width: 137px;
    height: 137px;
}
.team-profile-item:first-child .rating-star,.team-profile-item:last-child .rating-star{
    margin: 5px 0 12px 0;
}
.team-profile-item:first-child button,.team-profile-item:last-child button{
    margin-top: 25px;
}
.team-profile-item:first-child h6,.team-profile-item:last-child h6{
    margin: 15px 0 9px 0;
}
.team-profile-item:nth-child(2) h6{
    margin: 20px 0 10px 0;
}
.team-profile-item:nth-child(2) button{
margin-top: 30px;
}
.team-profile-item:nth-child(2) .rating-star{
    margin: 5px 0 15px 0;
}
.team-profile-item:nth-child(2){
    position: relative;
    width: 255px;
}
.team-profile-item:last-child{
    margin-left: -50px;
    width: 211px;
    padding: 15px 25px;
}
.team-profile-item .profile-img {
    border-radius: 50px;
    border: 2.5px solid #001597;
    box-shadow: 0px 0px 5.41px 0px rgba(0, 0, 0, 0.15);
    width: 165px;
    height: 165px;
}

.team-profile-item h6 {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    column-gap: 13px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
}

.team-profile-item .designation {
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
}

.team-profile-item button {
    border: none;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(82, 106, 103, 0.15);
    width: 100%;
    text-align: center;
    padding: 6.55px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
}

.team-profile-item button span {
    background: linear-gradient(134deg, #001597 3.03%, #01104D 47.1%, #020C1C 80.14%, #020B09 96.45%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
}

.rating-star {
    display: flex;
    column-gap: 2.25px;
    align-items: center;
    justify-content: center;
}

.follower-creater span {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.follower-creater {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.our-team-profile h4{
    color: #FFF;
    font-size: 24px;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 15px;
}
.our-team-profile p{
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 52px;
    max-width: 387px;
}